/** @jsx jsx */
import { FC, ReactNode, Fragment } from 'react';
import { css, jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { compose, get, map, join, compact, first } from 'lodash/fp';
import { Colors } from '@blueprintjs/core';
import { TReferenceData } from '..';

const alternativeBibliographyDataEnabled: boolean = window.REACT_APP_ALTERNATE_REFERENCE_BIBLIOGRAPHY_DATA_ENABLED === 'true';
const alternateTranslationsEnabled: boolean = window.REACT_APP_ALTERNATE_TRANSLATIONS_ENABLED === 'true';


interface ISectionProps {
  label: ReactNode;
  text?: ReactNode;
  link?: string;
}

const labelCss = css`
  color: ${Colors.LIGHT_GRAY1};
`;

const Section: FC<ISectionProps> = ({ label, link, text }) => {
  return (
    <div className="py-2">
      <div css={labelCss}>{label}</div>
      <div className="text-white break-words">
        {(text ||
          (link && (
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          ))) ??
          '-'}
      </div>
    </div>
  );
};

export type TAuthor = {
  lastName: string;
  firstNames: string[];
};

interface IAuthors {
  authors: TAuthor[];
}

interface IStudyDataProps {
  reference: Pick<TReferenceData, 'title' | 'attrs'>;
}

export const Authors: FC<IAuthors> = ({ authors }) => (
  <Fragment>
    {compose(
      join('; '),
      map((author: TAuthor) => `${author.lastName}, ${author.firstNames.join(' ')}`)
    )(authors)}
  </Fragment>
);

const StudyData: FC<IStudyDataProps> = ({ reference }) => {
  const authors: TAuthor[] | undefined = get('attrs.authors', reference);
  const firstAuthorData = first<TAuthor>(authors);
  const firstAuthor = compact<TAuthor>([firstAuthorData]);

  return alternativeBibliographyDataEnabled ? (
    <div>
      <Section
        label={alternateTranslationsEnabled ? <Trans id="alt:Reference number">Reference number</Trans> : <Trans>Reference number</Trans>}
        text={get('attrs.id', reference)}
      />
      <Section
        label={alternateTranslationsEnabled ? <Trans id='alt:All authors'>All authors</Trans> : <Trans>All authors</Trans>}
        text={authors ? <Authors authors={get('attrs.authors', reference)} /> : undefined}
      />
      <Section
        label={alternateTranslationsEnabled ? <Trans id='alt:Reference type'>Reference type</Trans> : <Trans>Reference type</Trans>}
        text={get('attrs.itemType', reference)}
      />
      <Section label={<Trans>Language</Trans>} text={get('attrs.language', reference)} />
      <Section
        label={alternateTranslationsEnabled ? <Trans id='alt:Journal title'>Journal title</Trans> : <Trans>Journal</Trans>}
        text={get('attrs.venue', reference)}
      />
      <Section label={<Trans>Document type</Trans>} text={get('attrs.documentType', reference)} />
      <Section label={<Trans>Year</Trans>} text={get('attrs.year', reference)} />
      <Section label={<Trans>URL</Trans>} link={get('attrs.fullText', reference)} />
      <Section label={<Trans>DOI</Trans>} text={get('attrs.doi', reference)} />
    </div>
  ) : (
    <div>
      <Section label={<Trans>Title</Trans>} text={get('title', reference)} />
      <Section
        label={<Trans>First Author</Trans>}
        text={firstAuthor ? <Authors authors={firstAuthor} /> : undefined}
      />
      <Section label={<Trans>Year</Trans>} text={get('attrs.year', reference)} />
      <Section
        label={<Trans>All authors</Trans>}
        text={authors ? <Authors authors={get('attrs.authors', reference)} /> : undefined}
      />
      <Section label={<Trans>Reference number</Trans>} text={get('attrs.id', reference)} />
      <Section label={<Trans>URL</Trans>} link={get('attrs.fullText', reference)} />
      <Section label={<Trans>Reference type</Trans>} text={get('attrs.itemType', reference)} />
      <Section label={<Trans>Journal</Trans>} text={get('attrs.venue', reference)} />
      <Section label={<Trans>Pages</Trans>} text={get('attrs.pages', reference)} />
      <Section label={<Trans>Volume</Trans>} text={get('attrs.volume', reference)} />
      <Section label={<Trans>Issue</Trans>} text={get('attrs.issue', reference)} />
      <Section label={<Trans>DOI</Trans>} text={get('attrs.doi', reference)} />
      <Section
        label={<Trans>Study identifier</Trans>}
        text={get('attrs.studyIdentifier', reference)}
      />
    </div>
  );
};

export default StudyData;
