/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useQuery } from '@apollo/react-hooks';
import {
  Breadcrumb,
  Breadcrumbs,
  Colors,
  Divider,
  BreadcrumbProps,
  Icon,
  Text,
} from '@blueprintjs/core';
import { gql } from 'graphql.macro';
import { Link } from 'react-router-dom';
import { History, Location } from 'history';
import { t } from '@lingui/macro';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { constant, find, get, propEq } from 'lodash/fp';
import {
  AccountType,
  AppContentProps,
  IAppLocationState,
  MatchParams,
  Project,
  ProjectsFolder,
  Role,
  ScreeningListMode,
  Stage,
  StageMatchParams,
  TeamMember,
} from '../../common/types';
import i18n from '../../i18n';
import { useKeycloak } from '../../keycloak';
import { bgTransition } from '../../common/styles';
import pathToRegexp from 'path-to-regexp';
import { LABELS } from './dashboard/screening_stats';

const GetProjectQuery = gql`
  query GetProjectQuery($id: uuid!, $userId: String!) {
    project: project_by_pk(id: $id) {
      id
      name
      folder {
        id
        name
      }
      stages {
        id
        name
        type
      }
      my_team_member: team_members(
        where: { user_id: { _eq: $userId }, deleted_at: { _is_null: true } }
      ) {
        id
        role
        user {
          id
          role
        }
      }
    }
  }
`;

type TProjectData = {
  project: Pick<Project, 'id' | 'name'> & {
    stages: Pick<Stage, 'id' | 'name' | 'type'>[];
    my_team_member: Pick<TeamMember, 'id' | 'role'>[];
    folder?: Pick<ProjectsFolder, 'id' | 'name'>;
  };
};

const backIcon = css`
  &:hover {
    background-color: ${Colors.GRAY1};
  }
`;

interface IBreadcrumbPropsWithLocationState extends BreadcrumbProps {
  state?: History.PoorMansUnknown;
  search?: string;
}

type TRoutingData = {
  location: Location<IAppLocationState>;
  params: MatchParams;
  searchParams: URLSearchParams;
  projectData: TProjectData;
  isAdmin: boolean;
};

interface IBreadcrumbConfig {
  pathRe: pathToRegexp.PathRegExp;
  text: (data: TRoutingData) => string | undefined;
  href?: (defaultHref: string, data: TRoutingData) => string;
  search?: (data: TRoutingData) => string | undefined;
  subRoutes?: IBreadcrumbConfig[];
}

const createPathRe = (path: string) => pathToRegexp(path, [], { end: false });

// contains the breadcrumbs hierarchy which defines the order of the breadcrumb items in the
// breadcrumbs menu
// the order of breadcrumb config is important and has the same meaning/logic as react-router routes
// order: the more specific routes should precede the more general ones to make sure they are matched
// first
const getBreadcrumbsList: (isTechAdmin: boolean) => IBreadcrumbConfig[] = (isTechAdmin) => [
  {
    pathRe: createPathRe('/projects/:projectId'),
    text: ({ projectData }) =>
      projectData.project.folder
        ? `${projectData.project.folder?.name}/${projectData.project.name}`
        : projectData.project.name,
    href: (defaultHref, { projectData }) => {
      const isAdminAccount =
        get('project.my_team_member[0].user.role', projectData) === AccountType.TechAdmin;
      return isAdminAccount
        ? `${defaultHref}/${isTechAdmin ? 'dashboard' : 'screening'}`
        : defaultHref;
    },
    subRoutes: [
      {
        // admin-role route
        pathRe: createPathRe('/screening'),
        text: constant(i18n._(t`Screening`)),
      },
      {
        // admin-role route
        pathRe: createPathRe('/references'),
        text: ({ searchParams, location }) => {
          const { state, pathname } = location;
          let isDuplicateList = searchParams.get('duplicatesOnly') === '1';

          // check if previous location was the duplicates list and keep the duplicates label if so
          if (
            state?.prevLocation &&
            state.prevLocation.pathname !== pathname &&
            state.prevLocation.pathname.endsWith('/references')
          ) {
            isDuplicateList =
              new URLSearchParams(state.prevLocation.search).get('duplicatesOnly') === '1';
          }

          return (
            i18n._(t`References`) + (isDuplicateList ? ` - ${i18n._(t`Removed duplicates`)}` : '')
          );
        },
        search: ({ searchParams, location }) => {
          const isDuplicateList = searchParams.get('duplicatesOnly') === '1';
          if (isDuplicateList) {
            return `?duplicatesOnly=1`;
          }

          // persists search params from previous location if previous location pointed at
          // references list.
          const { state, pathname } = location;
          if (
            state?.prevLocation &&
            state.prevLocation.pathname !== pathname &&
            state.prevLocation.pathname.endsWith('/references')
          ) {
            return state.prevLocation.search;
          }
        },
        subRoutes: [
          {
            // admin-role route
            pathRe: createPathRe('/:referenceId/details'),
            text: constant(i18n._(t`Reference details`)),
          },
          {
            // admin-role route
            pathRe: createPathRe('/unassigned-attachments'),
            text: constant(i18n._(t`Unassigned PDF files`)),
          },
        ],
      },
      {
        // admin-role route
        pathRe: createPathRe('/prisma'),
        text: constant(i18n._(t`PRISMA Diagram`)),
      },
      {
        // admin-role route
        pathRe: createPathRe('/team-members'),
        text: constant(i18n._(t`Team members`)),
      },
      {
        // screener-role route
        pathRe: createPathRe('/stages/:stageId/screening'),
        text: ({ params, projectData, searchParams }) => {
          const { stageId } = params as StageMatchParams;
          const stage = find(propEq('id', stageId), projectData.project.stages);
          if (stage == null) return;
          const screeningListMode = searchParams.get('listMode');

          const screeningListModeLabel =
            screeningListMode === ScreeningListMode.Completed
              ? i18n._(t`Completed`)
              : screeningListMode === ScreeningListMode.ToScreen
              ? i18n._(t`To screen`)
              : null;

          return `${stage.name} - ${screeningListModeLabel ?? i18n._(t`Screening`)}`;
        },
        search: ({ searchParams }) => {
          const screeningListMode = searchParams.get('listMode');
          return `?listMode=${screeningListMode}`;
        },
      },
      {
        // screener-role route
        pathRe: createPathRe('/stages/:stageId/conflicts'),
        text: ({ params, projectData }) => {
          const { stageId } = params as StageMatchParams;
          const stage = find(propEq('id', stageId), projectData.project.stages);
          if (stage == null) return;

          return `${stage.name} - ${i18n._(t`Conflicts`)}`;
        },
      },
      {
        // screener-role route
        pathRe: createPathRe(''),
        text: ({ searchParams }) => {
          const isFocusMode = searchParams.get('focus') === '1';
          if (isFocusMode) return i18n._(t`Focus mode`);
        },
        search: ({ location }) => {
          return location.search;
        },
      },
      {
        // admin-role route
        pathRe: createPathRe('/stages'),
        text: constant(i18n._(t`Review`)),
        subRoutes: [
          {
            // admin-role route
            pathRe: createPathRe('/:stageId'),
            text: ({ params, projectData }) => {
              const { stageId } = params as StageMatchParams;
              const stage = find(propEq('id', stageId), projectData.project.stages);
              if (stage == null) return;
              return stage.name;
            },
            subRoutes: [
              {
                // admin-role route
                pathRe: createPathRe('/references/:referenceStatus?'),
                text: ({ params }) => {
                  const maybeStatusLabel = params.referenceStatus
                    ? LABELS[params.referenceStatus]
                    : null;
                  return i18n._(t`References`) + maybeStatusLabel ? ` - ${maybeStatusLabel}` : '';
                },
              },
              {
                // admin-role route
                pathRe: createPathRe('/instructions'),
                text: constant(i18n._(t`Instructions`)),
              },
            ],
          },
        ],
      },
    ],
  },
];

function breadcrumbsBuilder(
  location: Location<IAppLocationState>,
  params: MatchParams,
  isAdmin: boolean,
  projectData: TProjectData
) {
  const searchParams = new URLSearchParams(location.search);

  return function processBreadcrumbs(
    breadcrumbs: IBreadcrumbConfig[],
    remainingPath?: string, // used for internal recursive invocation
    matchedPathParts?: string[] // used for internal recursive invocation
  ): [
    IBreadcrumbPropsWithLocationState[] /* prepared breadcrumbs */,
    string /* remaining path */,
    string[] /* matched path parts */
  ] {
    const result: IBreadcrumbPropsWithLocationState[] = [];
    let remainingLocationPath = remainingPath ?? location.pathname;
    let matchedParts: string[] = [...(matchedPathParts ?? [])];

    for (let breadcrumb of breadcrumbs) {
      const { pathRe } = breadcrumb;
      const pathMatch = pathRe.exec(remainingLocationPath);
      if (pathMatch) {
        matchedParts.push(pathMatch[0]);
        remainingLocationPath = remainingLocationPath.replace(pathMatch[0], '');

        const { text, subRoutes, search, href } = breadcrumb;
        const maybeCrumbText = text({
          params,
          searchParams,
          isAdmin,
          projectData,
          location,
        });

        if (maybeCrumbText) {
          const defaultHref = matchedParts.join('');
          result.push({
            text: maybeCrumbText,
            href:
              href?.(defaultHref, { params, searchParams, isAdmin, projectData, location }) ??
              defaultHref,
            state: location.state,
            search: search?.({ params, searchParams, isAdmin, projectData, location }),
          });
        }

        if (subRoutes) {
          const [subCrumbs, pathAfterSubCrumbs, matchedPartsAfterSubCrumbs] = processBreadcrumbs(
            subRoutes,
            remainingLocationPath,
            matchedParts
          );
          remainingLocationPath = pathAfterSubCrumbs;
          matchedParts.push(...matchedPartsAfterSubCrumbs);
          result.push(...subCrumbs);
        }
      }
    }

    return [result, remainingLocationPath, matchedParts];
  };
}

function getBreadcrumbs(
  location: Location<IAppLocationState>,
  params: MatchParams,
  isAdmin: boolean,
  isTechAdmin: boolean,
  projectData?: TProjectData
): IBreadcrumbPropsWithLocationState[] {
  if (projectData && projectData.project) {
    const createProjectBreadcrumbs = breadcrumbsBuilder(location, params, isAdmin, projectData);
    const [result] = createProjectBreadcrumbs(getBreadcrumbsList(isTechAdmin));

    if (result.length > 0) {
      result[result.length - 1].current = true;
    }
    return result;
  } else {
    return [];
  }
}

const renderBreadcrumb = (history: History) => (props: IBreadcrumbPropsWithLocationState) => {
  const { text, state } = props;
  const breadCrumbText = (
    <Text
      ellipsize
      className="text-sm"
      css={{
        maxWidth: '300px',
        color: props.current ? Colors.WHITE : Colors.GRAY5,
      }}
    >
      {text}
    </Text>
  );

  return props.href ? (
    <Breadcrumb
      {...props}
      current={false}
      onClick={(e) => {
        history.push({
          pathname: props.href || '',
          state,
          search: props.search,
        });
        e.preventDefault();
      }}
      text={breadCrumbText}
    />
  ) : (
    <Breadcrumb {...props} text={breadCrumbText} />
  );
};

const ProjectBreadcrumbs = ({ match, history, location }: AppContentProps) => {
  const {
    isAdmin,
    user: { id: userId },
    isTechAdmin
  } = useKeycloak();
  const { loading: projectLoading, data: projectData } = useQuery<TProjectData>(GetProjectQuery, {
    skip: !match || match.params.projectId == null,
    variables: { id: match && match.params.projectId, userId },
  });

  if (!match || projectLoading) return null;
  const isProjectAdmin =
    isAdmin && get('project.my_team_member[0].role', projectData) === Role.Manager;
  const items = getBreadcrumbs(
    location as Location<IAppLocationState>,
    match.params,
    isProjectAdmin,
    isTechAdmin,
    projectData
  );
  const backItem = items.length > 1 ? items[items.length - 2] : null;

  return (
    <React.Fragment>
      {backItem && (
        <React.Fragment>
          <Link
            to={{ pathname: backItem.href, search: backItem.search }}
            css={[backIcon, bgTransition]}
            className="px-4 h-full flex flex-row justify-center items-center"
          >
            <Icon icon={IconNames.ARROW_LEFT} color={Colors.WHITE} />
          </Link>
          <Divider className="h-full m-0" css={{ borderColor: Colors.GRAY1 }} />
        </React.Fragment>
      )}
      <Breadcrumbs
        className="w-full ml-4"
        items={items}
        breadcrumbRenderer={renderBreadcrumb(history)}
      />
    </React.Fragment>
  );
};

export default ProjectBreadcrumbs;
