/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { ControlGroup, FormGroup, Colors, Classes } from '@blueprintjs/core';
import { UserData } from '../users_panel';
import {
  formatDateDistance,
  getLocalizedDateTime,
} from '../../project/helpers';
import { useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import i18n from '../../../i18n';
import { getAccountTypeLabel } from '../../../lib/user_helpers';

const containerCss = css`
  background-color: ${Colors.LIGHT_GRAY3};
  .${Classes.LABEL} {
    color: ${Colors.GRAY1};
  }
`;

interface IAccountDetailsProps {
  user: UserData;
  lastActivity?: string;
  //inactive?: boolean;
}

const FIELDS = ['accountType', 'added', 'lastSeen'];

const FIELD_LABELS = {
  accountType: i18n._(t`Account type`),
  added: i18n._(t`Added`),
  lastSeen: i18n._(t`Last activity`),
};

const AccountDetails: React.FC<IAccountDetailsProps> = ({
  user,
  lastActivity,
  // inactive
}) => {
  const getFieldContent = useCallback(
    (fieldName: string) => {
      switch (fieldName) {
        case 'accountType':
          return getAccountTypeLabel(user.role, user.isTechAdmin);
        case 'added':
          return getLocalizedDateTime(user.createdAt);
        case 'lastSeen':
          return (
            <span
            // className={inactive ? 'text-red-600' : undefined}
            >
              {lastActivity ? formatDateDistance(lastActivity) : <Trans>No activity</Trans>}
            </span>
          );
        default:
          return null;
      }
    },
    [
      user,
      lastActivity,
      // inactive
    ]
  );

  return (
    <ControlGroup fill className="px-6 py-4" css={containerCss}>
      {FIELDS.map((fieldName) => (
        <FormGroup
          key={fieldName}
          className="flex-1 m-0 account-details-field"
          label={FIELD_LABELS[fieldName]}
        >
          <span className="text-lg field-content">{getFieldContent(fieldName)}</span>
        </FormGroup>
      ))}
    </ControlGroup>
  );
};

export default AccountDetails;
