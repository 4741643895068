/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import {
  CategoryWithActions,
  IFoldersCollapsed,
  ISelectedProjects,
  ProjectData,
  ProjectsFolder,
  ProjectStatus,
  Role,
  TSelectedFolderAction,
} from '../../common/types';
import { t, Trans } from '@lingui/macro';
import {
  Alert,
  Button,
  Classes,
  Colors,
  Dialog,
  IMenuItemProps,
  Icon,
  Intent,
  NonIdealState,
  Spinner,
  Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import { useCurrCallback, useI18n, useSetState } from '../../lib/utils';
import { compact, compose, concat, find, flatMap, get, isEmpty, isNil, propEq } from 'lodash/fp';
import PageContentWrapper from '../common/page_content_wrapper';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useKeycloak } from '../../keycloak';
import AppToaster from '../../lib/toaster';
import EditProjectDialog from './create_or_edit_project_dialog';
import FolderProjectsList from './folder_projects_list';
import MoveProjectsToFolderDialog from './move_projects_to_folder_dialog';
import { RowSelectionColumn, TableCol } from '../common/gba_table';
import {
  TITLE_AND_NEXT_FOLDER_STATUSES,
  TITLE_AND_NEXT_PROJECT_STATUSES,
  formatDate,
  getFolderIcon,
  getFolderTitleColumnLabel,
  getTableHeaderOpacityCss,
  renderClaimsIcon,
  smallCircleIconSize,
} from './helpers';
import { alignStartCss, cursorCss, headerCellBgCss } from '../../common/styles';
import i18n from '../../i18n';
import { menuCol, statusCol } from '../../common/table_cols';
import ActionsMenu from '../common/actions_menu';
import StagesStatus from './stages_status';

const InsertTeamMembersAndSendInvitationMutation = loader(
  '../../graphql/insert_team_members_and_send_invitation_mutation.gql'
);

interface GetListColumnsProps {
  toggleCreatingProject: (folderId: string | null) => void;
  selectFolderAndAction: (folderId: string, action: TSelectedFolderAction) => void;
  handleFolderStatusChange: (payload: { folderId: string; status: ProjectStatus }) => void;
  isTechAdmin: boolean;
  folderId?: string;
  folderName?: string;
  folderStatus?: ProjectStatus;
  projectsStatus?: ProjectStatus;
  projectsCount?: number;
  isCollapsed?: boolean;
  toggleIsCollapsed?: () => void;
}

const getListColumns: (props: GetListColumnsProps) => TableCol<ProjectData>[] = ({
  toggleCreatingProject,
  selectFolderAndAction,
  handleFolderStatusChange,
  isTechAdmin,
  folderId,
  folderName,
  folderStatus,
  projectsStatus,
  projectsCount,
  isCollapsed,
  toggleIsCollapsed,
}: GetListColumnsProps) => {
  const categoriesWithActions =
    folderId && folderStatus
      ? projectsStatus === ProjectStatus.Active
        ? [
            {
              name: i18n._(t`Folder`),
              actions: [
                {
                  text: <Trans>Create project</Trans>,
                  icon: IconNames.ADD,
                  onClick: () => toggleCreatingProject(folderId),
                },
              ],
            },
            {
              name: '',
              actions: concat<IMenuItemProps>(
                [
                  {
                    text: <Trans>Settings</Trans>,
                    icon: IconNames.COG,
                    onClick: () => selectFolderAndAction(folderId, 'showSettings'),
                  },
                ],
                TITLE_AND_NEXT_FOLDER_STATUSES[projectsStatus!].possibleStatuses.map(
                  ({ text, icon, status }) => ({
                    text,
                    icon,
                    intent: status === ProjectStatus.InTrash ? Intent.DANGER : Intent.NONE,
                    onClick:
                      status === ProjectStatus.InTrash
                        ? () => selectFolderAndAction(folderId, 'delete')
                        : () => handleFolderStatusChange({ folderId, status }),
                  })
                )
              ),
            },
          ]
        : [
            {
              name: i18n._(t`Folder`),
              actions: TITLE_AND_NEXT_FOLDER_STATUSES[projectsStatus!].possibleStatuses.map(
                ({ text, icon, status }) => ({
                  text,
                  icon,
                  intent: status === ProjectStatus.InTrash ? Intent.DANGER : Intent.NONE,
                  onClick:
                    status === ProjectStatus.InTrash
                      ? () => selectFolderAndAction(folderId, 'delete')
                      : () => handleFolderStatusChange({ folderId, status }),
                })
              ),
            },
          ]
      : undefined;

  return compact([
    isTechAdmin && {
      ...RowSelectionColumn,
      headerCellCss: headerCellBgCss,
      labelIcon: getFolderIcon(folderName, projectsStatus, projectsCount, isCollapsed),
      labelIconIntent: projectsStatus === ProjectStatus.InTrash ? Intent.DANGER : undefined,
      labelIconOnClick: toggleIsCollapsed,
    },
    {
      id: 'project-title',
      label: getFolderTitleColumnLabel(folderName, projectsStatus),
      cellCss: css(alignStartCss, cursorCss),
      headerCellCss: css([headerCellBgCss, alignStartCss]),
      customHeaderContent: (
        <span
          className="w-full text-left"
          onClick={toggleIsCollapsed ? () => toggleIsCollapsed() : undefined}
        >
          {getFolderTitleColumnLabel(folderName, projectsStatus)}
        </span>
      ),
    },
    {
      id: 'references',
      label: isNil(projectsCount) || projectsCount === 0 ? '' : i18n._(t`Number of references`),
      width: 130,
      headerCellCss: css([headerCellBgCss, getTableHeaderOpacityCss(isCollapsed)]),
      cellCss: cursorCss,
    },
    {
      id: 'people',
      label: isNil(projectsCount) || projectsCount === 0 ? '' : i18n._(t`People in project`),
      width: 130,
      headerCellCss: css([headerCellBgCss, getTableHeaderOpacityCss(isCollapsed)]),
      cellCss: cursorCss,
    },
    statusCol({
      label: isNil(projectsCount) || projectsCount === 0 ? '' : i18n._(t`Status`),
      headerCellCss: css([headerCellBgCss, getTableHeaderOpacityCss(isCollapsed)]),
      cellCss: cursorCss,
      sortable: false,
    }),
    {
      id: 'created',
      label: isNil(projectsCount) || projectsCount === 0 ? '' : i18n._(t`Created`),
      width: 130,
      headerCellCss: css([headerCellBgCss, getTableHeaderOpacityCss(isCollapsed)]),
      cellCss: cursorCss,
    },
    {
      id: 'due-date',
      label: isNil(projectsCount) || projectsCount === 0 ? '' : i18n._(t`Due date`),
      width: 130,
      headerCellCss: css([headerCellBgCss, getTableHeaderOpacityCss(isCollapsed)]),
      cellCss: cursorCss,
    },

    isTechAdmin && {
      id: 'reference-claims',
      labelIcon: isNil(projectsCount) || projectsCount === 0 ? undefined : IconNames.DOCUMENT,
      width: 60,
      headerCellCss: css([headerCellBgCss, getTableHeaderOpacityCss(isCollapsed)]),
      cellCss: cursorCss,
    },
    isTechAdmin &&
      menuCol({
        headerCellCss: headerCellBgCss,
        cellCss: cursorCss,
        customHeaderContent:
          folderId && folderStatus ? (
            <ActionsMenu categoriesWithActions={categoriesWithActions} />
          ) : undefined,
      }),
  ]);
};

type TSelectedProjectAction =
  | 'delete'
  | 'join'
  | 'showSettings'
  | 'remove_from_folder'
  | 'move_to_folder';

interface IListForManager {
  loading: boolean;
  folders: ProjectsFolder[];
  activeFolders: ProjectsFolder[];
  projects: ProjectData[];
  selectedProjects: ISelectedProjects;
  updateSelectedProjects: (
    folderId: string | null,
    updater: (selected: number[]) => number[]
  ) => void;
  projectsStatus: ProjectStatus;
  updateProjectsStatus: (payload: { projectIds: string[]; status: ProjectStatus }) => void;
  moveProjectsToFolder: (payload: { projectIds: string[]; folderId: string | null }) => void;
  selectFolderAndAction: (folderId: string, action: TSelectedFolderAction) => void;
  updateFolderStatus: (payload: { folderId: string; status: ProjectStatus }) => void;
  foldersCollapsed: IFoldersCollapsed;
  toggleFolderCollapsed: (folderId: string) => void;
  toggleCreatingProject: (folderId: string | null) => void;
}

export interface IListForManagerState {
  selectedProjectId: string | null;
  selectedProjectAction: TSelectedProjectAction | null;
}

const LIST_FOR_MANAGER_INITIAL_STATE: IListForManagerState = {
  selectedProjectId: null,
  selectedProjectAction: null,
};

const ListForManagers: React.FC<IListForManager> = ({
  loading,
  projects,
  folders,
  activeFolders,
  selectedProjects,
  updateSelectedProjects,
  projectsStatus,
  updateProjectsStatus,
  moveProjectsToFolder,
  selectFolderAndAction,
  updateFolderStatus,
  foldersCollapsed,
  toggleFolderCollapsed,
  toggleCreatingProject,
}) => {
  const i18n = useI18n();
  const history = useHistory();
  const {
    user: { id: userId },
    isTechAdmin
  } = useKeycloak();
  const [addTeamMembersAndSendInvitations] = useMutation(
    InsertTeamMembersAndSendInvitationMutation
  );
  const [state, setState] = useSetState<IListForManagerState>(LIST_FOR_MANAGER_INITIAL_STATE);

  const { selectedProjectId, selectedProjectAction } = state;

  const selectedProject: ProjectData | undefined = useMemo(
    () =>
      compose(
        find(propEq('id', selectedProjectId)),
        concat(projects),
        flatMap('projects')
      )(folders),
    [folders, projects, selectedProjectId]
  );

  const goToProject = useCallback(
    (projectId) => {
      history.push(['/projects', projectId, isTechAdmin ? 'dashboard' : 'screening'].join('/'));
    },
    [history, isTechAdmin]
  );

  const handleCloseDialog = useCallback(() => {
    setState({
      selectedProjectId: null,
      selectedProjectAction: null,
    });
  }, [setState]);

  const handleDeleteConfirmation = useCallback(() => {
    updateProjectsStatus({ projectIds: [selectedProjectId!], status: ProjectStatus.InTrash });
    handleCloseDialog();
  }, [updateProjectsStatus, handleCloseDialog, selectedProjectId]);

  const handleMoveToFolderConfirmation = useCallback(
    (folderId: string | null) => {
      moveProjectsToFolder({ projectIds: [selectedProjectId!], folderId });
      handleCloseDialog();
    },
    [moveProjectsToFolder, handleCloseDialog, selectedProjectId]
  );

  const handleUpdateSelectedProjects = useCurrCallback(
    (folderId: string | null, updater: (selected: number[]) => number[]) =>
      updateSelectedProjects(folderId, updater),
    [updateSelectedProjects]
  );

  const handleProjectJoinConfirmation = useCallback(() => {
    if (selectedProjectId == null || selectedProjectAction !== 'join') return;
    addTeamMembersAndSendInvitations({
      variables: {
        teamMembers: [
          {
            user_id: userId,
            project_id: selectedProjectId,
            role: Role.Manager,
          },
        ],
        invitation: {
          projectId: selectedProjectId,
          userIds: [userId],
        },
      },
    })
      .then(() => {
        AppToaster.show({
          message: i18n._(
            t`Successfully joined the project ${selectedProject?.name} as a manager!`
          ),
          intent: Intent.SUCCESS,
        });
        goToProject(selectedProjectId);
      })
      .catch((err: Error) =>
        AppToaster.show({
          message: (
            <span>
              <Trans>Failed to join project</Trans> {selectedProject?.name}: {err.message}
            </span>
          ),
          intent: Intent.WARNING,
        })
      );
  }, [
    addTeamMembersAndSendInvitations,
    goToProject,
    selectedProjectId,
    selectedProject,
    selectedProjectAction,
    userId,
    i18n,
  ]);

  const handleTableClick = useCurrCallback(
    (projects: ProjectData[], rowIdx: number, colIdx: number) => {
      // due to the fact that table's onclick handler gets called earlier than any other onclick
      // handler attached beneath the table (thus it's impossible to cancel propagation from within
      // other element's event handler) have to skip certain columns clicks
      if (isTechAdmin) {
        if (colIdx === 8 /**row menu */) return;
      }

      const project: ProjectData = projects[rowIdx];
      // if user is not a member of the project, display the dialog
      // allowing them to join the project as a manager
      // otherwise, just navigate them to the project dashboard
      if (isNil(get('my_team_member[0]', project))) {
        setState({ selectedProjectId: project.id, selectedProjectAction: 'join' });
      } else {
        goToProject(project.id);
      }
    },
    [setState, goToProject, isTechAdmin]
  );

  const renderTableCell = useCallback(
    (folder: ProjectsFolder | undefined) => (colId: string, project: ProjectData) => {
      const projectId = project.id;

      switch (colId) {
        case 'project-title':
          return project.name;
        case 'references':
          const referencesCount = get('references_aggregate.aggregate.count', project);
          return referencesCount == null ? (
            '-'
          ) : (
            <span className="flex flex-row items-center">
              {referencesCount}{' '}
              <Icon color={Colors.GRAY3} className="ml-2" icon={IconNames.DOCUMENT} iconSize={12} />
            </span>
          );
        case 'people':
          return get('team_members_aggregate.aggregate.count', project);
        case 'created':
          return project.created_at ? formatDate(project.created_at, 'dd/MM/yyyy') : '-';
        case 'due-date':
          return project.due_date ? formatDate(project.due_date, 'dd/MM/yyyy') : '-';
        case 'status':
          return <StagesStatus projectId={project.id} />;
        case 'reference-claims': {
          const hasReferencesToClaim =
            get('claims_counts.refs_without_attachments_count', project) > 0;
          const hasClaimedReferences = get('claims_counts.claims_in_progress_count', project) > 0;
          const tooltipContent = hasReferencesToClaim ? (
            <Trans>Project with unclaimed PDFs</Trans>
          ) : hasClaimedReferences ? (
            <Trans>Project with claimed PDFs</Trans>
          ) : (
            <Trans>Project without the need for PDFs</Trans>
          );

          return (
            <Tooltip content={tooltipContent}>
              {renderClaimsIcon({
                hasReferencesToClaim,
                hasClaimedReferences,
                iconSize: smallCircleIconSize,
              })}
            </Tooltip>
          );
        }
        case 'menu':
          const isProjectInFolder: boolean = !isNil(get('folder.id', project));
          if (isTechAdmin) {
            const categoriesWithActions: CategoryWithActions[] = compact([
              {
                name: i18n._(t`Project`),
                actions: concat<IMenuItemProps>(
                  compact([
                    projectsStatus === ProjectStatus.Active && {
                      text: <Trans>Settings</Trans>,
                      icon: IconNames.COG,
                      onClick: () =>
                        setState({
                          selectedProjectId: projectId,
                          selectedProjectAction: 'showSettings',
                        }),
                    },
                  ]),
                  TITLE_AND_NEXT_PROJECT_STATUSES[projectsStatus].possibleStatuses.map(
                    ({ text, icon, status }) => ({
                      text,
                      icon,
                      intent: status === ProjectStatus.InTrash ? Intent.DANGER : Intent.NONE,
                      onClick:
                        status === ProjectStatus.InTrash
                          ? () =>
                              setState({
                                selectedProjectId: projectId,
                                selectedProjectAction: 'delete',
                              })
                          : () => updateProjectsStatus({ projectIds: [projectId], status }),
                    })
                  )
                ),
              },
              (isNil(folder?.status) || folder?.status === ProjectStatus.Active) &&
              projectsStatus === ProjectStatus.Active
                ? {
                    name: i18n._(t`Project management`),
                    actions: compact([
                      {
                        text: <Trans>Move to folder</Trans>,
                        icon: IconNames.ADD_TO_FOLDER,
                        onClick: () =>
                          setState({
                            selectedProjectId: projectId,
                            selectedProjectAction: 'move_to_folder',
                          }),
                      },
                      isProjectInFolder && {
                        text: <Trans>Remove from folder</Trans>,
                        icon: IconNames.FOLDER_SHARED,
                        onClick: () =>
                          setState({
                            selectedProjectId: projectId,
                            selectedProjectAction: 'remove_from_folder',
                          }),
                      },
                    ]),
                  }
                : undefined,
            ]);

            return <ActionsMenu categoriesWithActions={categoriesWithActions} />;
          } else {
            return null;
          }
        default:
          return null;
      }
    },
    [projectsStatus, updateProjectsStatus, setState, isTechAdmin, i18n]
  );

  return (
    <PageContentWrapper>
      <div>
        {loading ? (
          <Spinner size={100} />
        ) : isEmpty(projects) && isEmpty(folders) ? (
          <NonIdealState icon={IconNames.SEARCH} title={<Trans>No projects</Trans>} />
        ) : (
          <div className="overflow-auto p-3">
            {projects.length > 0 ? (
              <FolderProjectsList<ProjectData>
                listColumns={getListColumns({
                  toggleCreatingProject,
                  selectFolderAndAction,
                  handleFolderStatusChange: updateFolderStatus,
                  isTechAdmin,
                  projectsStatus,
                  projectsCount: projects.length,
                  isCollapsed: foldersCollapsed['main'],
                  toggleIsCollapsed: () => toggleFolderCollapsed('main'),
                })}
                renderTableCell={renderTableCell(undefined)}
                projects={projects}
                selectedProjects={selectedProjects.main}
                updateSelectedProjects={handleUpdateSelectedProjects(null)}
                isCollapsed={foldersCollapsed['main']}
                handleTableClick={handleTableClick(projects)}
              />
            ) : null}
            {folders.map((folder) => (
              <FolderProjectsList<ProjectData>
                key={folder.id}
                listColumns={getListColumns({
                  toggleCreatingProject,
                  selectFolderAndAction,
                  handleFolderStatusChange: updateFolderStatus,
                  isTechAdmin,
                  folderId: folder.id,
                  folderName: folder.name,
                  folderStatus: folder.status,
                  projectsStatus,
                  projectsCount: folder.projects.length,
                  isCollapsed: foldersCollapsed[folder.id],
                  toggleIsCollapsed: () => toggleFolderCollapsed(folder.id),
                })}
                renderTableCell={renderTableCell(folder)}
                projects={folder.projects}
                selectedProjects={selectedProjects[folder.id] ?? []}
                updateSelectedProjects={handleUpdateSelectedProjects(folder.id)}
                isCollapsed={foldersCollapsed[folder.id]}
                handleTableClick={handleTableClick(folder.projects)}
              />
            ))}
          </div>
        )}
      </div>
      <Alert
        isOpen={selectedProjectId != null && selectedProjectAction === 'delete'}
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        cancelButtonText={i18n._(t`Cancel`)}
        confirmButtonText={i18n._(t`Delete`)}
        onCancel={handleCloseDialog}
        onConfirm={handleDeleteConfirmation}
      >
        <Trans>
          Are you sure you want to delete <span className="font-bold">{selectedProject?.name}</span>{' '}
          project?
        </Trans>
      </Alert>
      <Alert
        isOpen={selectedProjectId != null && selectedProjectAction === 'remove_from_folder'}
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        cancelButtonText={i18n._(t`Cancel`)}
        confirmButtonText={i18n._(t`Remove from folder`)}
        onCancel={handleCloseDialog}
        onConfirm={() => handleMoveToFolderConfirmation(null)}
      >
        <Trans>
          Are you sure you want to remove project{' '}
          <span className="font-bold">{selectedProject?.name}</span> from the{' '}
          <span className="font-bold">{selectedProject?.folder?.name}</span> folder?
        </Trans>
      </Alert>
      <Dialog
        isOpen={selectedProject != null && selectedProjectAction === 'join'}
        title={selectedProject?.name}
        onClose={handleCloseDialog}
      >
        <div className={Classes.DIALOG_BODY}>
          <p className="mb-2">
            <Trans>You are not a member of this project</Trans>.
          </p>
          <p>
            <Trans>You can join it now to see details and provide modifications</Trans>.
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button large text={<Trans>Cancel</Trans>} onClick={handleCloseDialog} />
            <Button
              large
              text={<Trans>Join as a manager</Trans>}
              onClick={handleProjectJoinConfirmation}
            />
          </div>
        </div>
      </Dialog>
      <EditProjectDialog
        isOpen={selectedProjectId != null && selectedProjectAction === 'showSettings'}
        onClose={handleCloseDialog}
        projectId={selectedProject?.id}
        dueDate={selectedProject?.due_date}
        projectName={selectedProject?.name}
        folderId={selectedProject?.folder?.id}
        folders={folders}
      />
      <MoveProjectsToFolderDialog
        isOpen={selectedProjectId != null && selectedProjectAction === 'move_to_folder'}
        onClose={handleCloseDialog}
        projectIds={selectedProject ? [selectedProject.id] : []}
        folderId={selectedProject?.folder?.id}
        folders={activeFolders}
        onConfirm={handleMoveToFolderConfirmation}
      />
    </PageContentWrapper>
  );
};

export default ListForManagers;
