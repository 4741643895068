/** @jsx jsx */
import {
  Button,
  Colors,
  Icon,
  IconSize,
  Intent,
  Popover,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { get, isEmpty, compose, find, propEq, map, reject, filter, compact } from 'lodash/fp';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import i18n from '../../i18n';
import { useKeycloak } from '../../keycloak';
import { SearchAndDesiredUndesiredKeywords } from '../../lib/criteria_utils';
import { getStatusCodesFromTaskResults, getTaskResultFromReference } from '../../lib/task_helpers';
import { createTable, IGbaTableProps, RowSelectionColumn, TableCol } from '../common/gba_table';
import TextWithHighlights from '../common/text_with_highlights';
import { formatDate } from '../project/helpers';
import {
  accessionNumberCol,
  batchCol,
  commentCol,
  docTypeCol,
  firstAuthorLastNameCol,
  languageCol,
  lastChangedCol,
  referenceNumberCol,
  statusCol,
  tagsCol,
  titleCol,
  yearCol,
} from '../../common/table_cols';
import { TExclusionReason, TReferenceData } from './index';
import LoadMoreReferencesButton from '../references/admin/load_more_references_button';
import { StageType } from '../../common/types';
import { TScreeningTag } from '../references/admin';
import ReferenceTags from '../references/admin/reference_tags';
import { renderDecisionTags } from '../references/admin/references_list_layouts';
import { ReferenceCommentFragmentType } from '../../graphql/reference_comment_fragment';

const docTypeColVisible: boolean = window.REACT_APP_DOCTYPE_COLUMN_VISIBLE === 'true';
const languageColVisible: boolean = window.REACT_APP_LANGUAGE_COLUMN_VISIBLE === 'true';
const alternativeLayoutEnabled: boolean = window.REACT_APP_ALTERNATE_SCREENING_LIST_LAYOUT_ENABLED === 'true';

export const renderReferenceCommentsIconWithPopover = (comments: ReferenceCommentFragmentType[]) =>
  isEmpty(comments) ? null : (
    <Popover
      interactionKind="hover"
      position={Position.BOTTOM}
      content={
        <div className="p-2 max-w-md">
          {comments.map((comment) => {
            const commentAuthorName: string =
              get('team_member.user.name', comment) ?? i18n._(t`User removed`);
            return (
              <div key={comment.id} className="whitespace-pre-wrap">
                <span className="font-bold">{commentAuthorName}:</span> {get('comment', comment)}
              </div>
            );
          })}
        </div>
      }
    >
      <Icon className="ml-1" icon={IconNames.COMMENT} color={Colors.GRAY1} />
    </Popover>
  );

const ReferencesTable = createTable<TReferenceData>();

const containerCss = css`
  margin-left: 1px;
  padding: 10px 20px 5px;
  background-color: ${Colors.LIGHT_GRAY5};
`;

interface IReferencesListProps {
  references: readonly TReferenceData[];
  selectedReferences: number[];
  formId: string;
  onChangeActiveReference: (activeReference: number) => void;
  onChangeSelectedReferences: (updateFn: (selected: number[]) => number[]) => void;
  customLayout?: {
    columns: TableCol<TReferenceData>[];
    cellRenderer: IGbaTableProps<TReferenceData>['cellContentRenderer'];
  };
  decisionFilter: string;
  onSortBy: (colId: string, ascending: boolean) => void;
  onLoadMore?: () => void;
  loadingMore: boolean;
  sortedBy?: {
    columnId: string;
    asc: boolean;
  };
  onClearSort: () => void;
  keywordsData: SearchAndDesiredUndesiredKeywords;
  exclusionReasons: TExclusionReason[];
  stageType: StageType;
  stageId: string;
  openFocusMode?: () => void;
  activeReference?: number;
  totalReferencesCount: number;
  screeningTags?: TScreeningTag[];
}

const COLS: TableCol<TReferenceData>[] = compact([
  RowSelectionColumn,
  statusCol({
    label: i18n._(t`Decision`),
    width: 120,
  }),
  firstAuthorLastNameCol({ width: 190 }),
  yearCol({ width: 90 }),
  titleCol(),
  accessionNumberCol(),
  referenceNumberCol(),
  docTypeColVisible ? docTypeCol() : null,
  languageColVisible ? languageCol() : null,
  batchCol(),
  commentCol(),
  tagsCol(),
  lastChangedCol(),
]);

const ALTERNATIVE_COLS_LAYOUT: TableCol<TReferenceData>[] = [
  RowSelectionColumn,
  statusCol({
    label: i18n._(t`Decision`),
    width: 120,
  }),
  firstAuthorLastNameCol({ width: 190 }),
  yearCol({ width: 90 }),
  titleCol(),
  docTypeCol(),
  lastChangedCol(),
  referenceNumberCol(),
  accessionNumberCol(),
];

const ReferencesList: React.FC<IReferencesListProps> = ({
  references,
  activeReference,
  formId,
  onChangeSelectedReferences,
  selectedReferences,
  onChangeActiveReference,
  customLayout,
  onLoadMore,
  decisionFilter,
  onSortBy,
  sortedBy,
  onClearSort,
  openFocusMode,
  keywordsData,
  exclusionReasons,
  loadingMore,
  totalReferencesCount,
  stageType,
  screeningTags,
  stageId,
}) => {
  const activeReferenceId = activeReference == null ? null : references[activeReference].id;
  const { user } = useKeycloak();
  const tableCols = customLayout?.columns ?? (alternativeLayoutEnabled ? ALTERNATIVE_COLS_LAYOUT : COLS);

  const onlySearchKeywords: SearchAndDesiredUndesiredKeywords = useMemo(() => {
    return {
      desiredKeywords: [],
      undesiredKeywords: [],
      searchKeywords: keywordsData.searchKeywords,
    };
  }, [keywordsData.searchKeywords]);

  const renderCellContent = useCallback(
    (colId: string, reference: TReferenceData) => {
      switch (colId) {
        case 'status': {
          const isActive = activeReferenceId === reference.id;
          const taskResult = getTaskResultFromReference(reference, formId, user.id);
          const inclusionStatus = get('result.inclusionStatus', taskResult);
          const statusCodes: string[] = getStatusCodesFromTaskResults(
            exclusionReasons,
            taskResult?.result
          );

          return (
            <Fragment>
              {inclusionStatus == null && isActive && openFocusMode && (
                <Tooltip content={<Trans>Focus mode</Trans>}>
                  <Button
                    minimal
                    icon={
                      <Icon
                        icon={IconNames.FULLSCREEN}
                        intent={Intent.PRIMARY}
                        iconSize={IconSize.LARGE}
                      />
                    }
                    intent={Intent.PRIMARY}
                    onClick={openFocusMode}
                  />
                </Tooltip>
              )}
              {inclusionStatus &&
                renderDecisionTags({
                  inclusionStatus,
                  reasonCodes: statusCodes,
                })}
            </Fragment>
          );
        }

        case 'firstAuthorsLastName':
          return (
            <TextWithHighlights
              keywordsData={onlySearchKeywords}
              text={reference.attrs?.authors[0]?.lastName ?? '-'}
            />
          );
        case 'year':
          return reference.attrs?.year ?? '-';
        case 'title':
          return (
            <span title={reference.title}>
              <TextWithHighlights
                className="truncate"
                keywordsData={keywordsData}
                text={reference.title}
              />
            </span>
          );
        case 'accessionNumber':
          return reference.attrs?.accessionNumber ?? '-';
        case 'referenceNumber':
          return reference.attrs?.id ?? '-';
        case 'documentType':
          return reference.attrs?.documentType ?? '-';
        case 'language':
          return reference.attrs?.language ?? '-';
        case 'batch':
          return get('import_task.label', reference) ?? '-';
        case 'comment': {
          // FIXME: implement comments filtering on backend side (DB)
          const comments: ReferenceCommentFragmentType[] = compose(
            filter(
              (referenceComment: ReferenceCommentFragmentType) =>
                referenceComment.stage_id === stageId &&
                (!referenceComment.task_id || referenceComment.team_member.user.id === user.id)
            ),
            get('reference_comments')
          )(reference);
          return renderReferenceCommentsIconWithPopover(comments);
        }
        case 'tags': {
          const taskResult = getTaskResultFromReference(reference, formId, user.id);
          const tags: TScreeningTag[] = compose(
            reject(isEmpty),
            map((tagId: string) => find(propEq('id', tagId), screeningTags)),
            get('result.tags')
          )(taskResult);
          return <ReferenceTags tags={tags} />;
        }
        case 'lastChanged':
          const screenedTaskResult = getTaskResultFromReference(reference, formId, user.id);
          const timestamp =
            get('updated_at', screenedTaskResult) ?? get('import_task.created_at', reference);
          return timestamp ? formatDate(timestamp, 'dd/MM/yyyy HH:mm') : '-';
        default:
          return null;
      }
    },
    [
      formId,
      activeReferenceId,
      user.id,
      keywordsData,
      onlySearchKeywords,
      exclusionReasons,
      openFocusMode,
      stageType,
      stageId,
    ]
  );

  const handleClick = useCallback(
    (rowIdx) => {
      onChangeActiveReference(rowIdx);
    },
    [onChangeActiveReference]
  );

  const selection = useMemo(() => {
    return {
      rows: selectedReferences,
      onChange: onChangeSelectedReferences,
    };
  }, [selectedReferences, onChangeSelectedReferences]);

  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(false);
  const handleScrollBottomChange = useCallback((isScrollBottomReached) => {
    setShowLoadMoreButton(isScrollBottomReached);
  }, []);

  return (
    <div className="h-full flex flex-col flex-no-wrap overflow-hidden relative" css={containerCss}>
      <ReferencesTable
        className="h-full"
        numRows={references.length}
        rows={references}
        cols={tableCols}
        cellContentRenderer={customLayout?.cellRenderer ?? renderCellContent}
        selection={selection}
        activeRow={activeReference}
        onClick={handleClick}
        tableId={decisionFilter}
        onSortBy={onSortBy}
        sortedBy={sortedBy}
        onClearSort={onClearSort}
        onDoubleClick={openFocusMode}
        onScrollBottomChange={handleScrollBottomChange}
      />
      {onLoadMore && (
        <LoadMoreReferencesButton
          showLoadMoreButton={showLoadMoreButton}
          referenceCount={references.length}
          totalReferencesCount={totalReferencesCount}
          onLoadMore={onLoadMore}
          loadingMore={loadingMore}
        />
      )}
    </div>
  );
};

export default ReferencesList;
