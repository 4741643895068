/** @jsx jsx */
import { Colors, Icon } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { contains } from 'lodash/fp';
import AppLogo from '../logo';
import { History, Location } from 'history';

export const sidebarCss = css`
  width: 48px;
  border-top: 1px solid ${Colors.GRAY1};
  background-color: ${Colors.DARK_GRAY5};
`;

export const moduleLinkCss = css`
  color: ${Colors.GRAY5};
  border-radius: 3px;
  font-size: 10px;
  margin: 25px 0;

  &:hover,
  &:visited {
    text-decoration: none;
  }

  &.active,
  &:hover {
    color: #fff;
  }

  &.active {
    background-color: ${Colors.GRAY1};
    font-weight: bold;
  }
`;

export type TAppModule = {
  path: string;
  caption: string;
  icon: IconName;
  moduleType: 'admin' | 'project';
  search?: string;
  // only admins with full access can access this module (isTechAdmin = true)
  restricted?: boolean;
};

interface ISidebarProps {
  location: Location<History.PoorMansUnknown>;
  modules: TAppModule[];
  bottomModules?: TAppModule[];
  projectId?: string;
}

const Sidebar: React.FC<ISidebarProps> = ({ modules, bottomModules, projectId, location }) => {
  return (
    <div className="flex-none flex flex-col overflow-hidden" css={sidebarCss}>
      <AppLogo css={{ height: '48px' }} />
      <div className="flex-1 flex flex-col justify-center items-center overflow-hidden">
        {modules.map(({ path, icon, caption, search, moduleType }, idx) => {
          const fullPath = moduleType === 'project' ? `/projects/${projectId}${path}` : path;
          const isActive =
            contains(path, location.pathname) && (search == null || location.search === search);
          return (
            <Link
              key={idx}
              to={{
                pathname: fullPath,
                search,
              }}
              css={moduleLinkCss}
              title={caption}
              className={`module-link w-full flex flex-col overflow-hidden items-center justify-center py-2 ${
                isActive ? 'active' : ''
              }`}
            >
              <Icon icon={icon} />
              <span className="mt-2 flex-none w-full truncate text-center">{caption}</span>
            </Link>
          );
        })}
      </div>
      {bottomModules ? (
        <div className="flex-none text-center my-5">
          {bottomModules.map(({ path, icon, caption }, idx) => (
            <Link key={idx} to={path} css={moduleLinkCss} title={caption}>
              <Icon icon={icon} />
            </Link>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Sidebar;
