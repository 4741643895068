/** @jsx jsx */
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Alert, Classes, Intent, Spinner, TabId } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { plural, t, Trans } from '@lingui/macro';
import { gql, loader } from 'graphql.macro';
import {
  compact,
  cloneDeep,
  isNil,
  find,
  propEq,
  flatten,
  compose,
  keys,
  map,
  reduce,
  filter,
  intersectionWith,
} from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GetProjectsQueryVariables,
  GraphQLAggregateData,
  IManagerTab,
  ISelectedProjects,
  ManagerTabIds,
  ProjectCounts,
  ProjectData,
  ProjectsFolder,
  ProjectsListBatchAction,
  ProjectStatus,
  SelectedProjectAction,
  TSelectedFolderAction,
} from '../../common/types';
import { useKeycloak } from '../../keycloak';
import AppToaster from '../../lib/toaster';
import { ProjectsLibraryFilter, ProjectsOwnerFilter, useI18n, useSetState } from '../../lib/utils';
import ErrorScreen from '../common/error_screen';
import useActionLogger from '../hooks/use_action_logger';
import { panelCollapsedColCss, panelExpandedColCss, TScreeningPanel } from '../screening/list';
import { renderFiltersToggle } from '../screening/list_header_toggles';
import BatchActionsAlert from './batch_actions_alert';
import CreateProjectsFolderDialog from './create_or_edit_projects_folder_dialog';
import CreateProjectDialog from './create_or_edit_project_dialog';
import {
  getProjectStatusFromActionType,
  mapLibraryFilterToObject,
  mapTabIdToProjectStatus,
} from './helpers';
import ListForManagers from './list_for_managers';
import ListForScreeners from './list_for_screeners';
import MoveProjectsToFolderDialog from './move_projects_to_folder_dialog';
import ProjectsFiltersColumn from './projects_filters_column';
import ProjectsListHeader from './projects_list_header';
import ProjectsListFilters from './projects_list_filters';
import useListOfProjects, {
  ORDER_BY_LATEST_FIRST,
  ORDER_BY_NAME_ALPHABETICALLY,
} from '../hooks/use_list_of_projects';

const GetProjectsQuery = loader('../../graphql/get_admin_projects.gql');
const ProjectFragment = loader('../../graphql/project_fragment.gql');
const ProjectsFolderFragment = loader('../../graphql/projects_folder_fragment.gql');

const UpdateProjectsAndFoldersStatusMutation = gql`
  mutation UpdateProjectsAndFoldersStatus(
    $projectIds: [uuid!]!
    $folderIds: [uuid!]!
    $status: project_status!
  ) {
    update_project(where: { id: { _in: $projectIds } }, _set: { status: $status }) {
      returning {
        ...ProjectFragment
      }
    }
    update_project_folder(where: { id: { _in: $folderIds } }, _set: { status: $status }) {
      returning {
        ...ProjectsFolderFragment
      }
    }
  }
  ${ProjectFragment}
  ${ProjectsFolderFragment}
`;

const UpdateProjectsDataMutation = gql`
  mutation UpdateProjectsData($projectIds: [uuid!], $projectData: project_set_input!) {
    update_project(where: { id: { _in: $projectIds } }, _set: $projectData) {
      returning {
        ...ProjectFragment
      }
    }
  }
  ${ProjectFragment}
`;

const EMPTY_SELECTED_PROJECTS: ISelectedProjects = {
  main: [],
};

const EMPTY_PROJECTS_FOLDERS: ProjectsFolder[] = [];

// TODO: auto-generate using apollo codegen
export type GetProjectsQueryResult = {
  screening: GraphQLAggregateData;
  projects: ProjectData[];
  activeFolders: ProjectsFolder[];
  folders: ProjectsFolder[];
};

const managerTabs: IManagerTab[] = [
  {
    id: ManagerTabIds.Active,
    title: <Trans>Active</Trans>,
    titleIcon: IconNames.INBOX,
    projectsStatus: ProjectStatus.Active,
  },
  {
    id: ManagerTabIds.Archived,
    title: <Trans>Archived</Trans>,
    titleIcon: IconNames.BOX,
    projectsStatus: ProjectStatus.Archived,
  },
  {
    id: ManagerTabIds.Deleted,
    title: <Trans>Deleted</Trans>,
    titleIcon: IconNames.TRASH,
    projectsStatus: ProjectStatus.InTrash,
  },
];

interface AdminTabsState {
  projectsLibraryFilter: ProjectsLibraryFilter;
  selectedTabId: TabId;
  creatingProject: boolean;
  creatingOrEditingFolder: boolean;
  selectedProjects: ISelectedProjects;
  confirmProjectAction: SelectedProjectAction | null;
  selectedFolderId: string | null;
  selectedFolderAction: TSelectedFolderAction | null;
}

const getInitialState: (isTechAdmin: boolean) => AdminTabsState = (isTechAdmin) => ({
  projectsLibraryFilter: ProjectsLibraryFilter.All,
  selectedTabId: isTechAdmin ? ManagerTabIds.Active : ManagerTabIds.Screening,
  creatingProject: false,
  creatingOrEditingFolder: false,
  selectedProjects: EMPTY_SELECTED_PROJECTS,
  confirmProjectAction: null,
  selectedFolderId: null,
  selectedFolderAction: null,
});

const AdminTabs: React.FC = () => {
  const {
    user: { id: userId },
    isTechAdmin
  } = useKeycloak();
  const i18n = useI18n();
  const insertActionLog = useActionLogger();
  const [activePanel, setActivePanel] = useState<TScreeningPanel | null>(null);
  const {
    sortOrder: activeListSortOrder,
    setSortOrder,
    allCollapsed,
    foldersCollapsed,
    toggleFolderCollapsed,
    populateFoldersCollapsed,
    toggleCollapseAllFolders,
    projectsOwnerFilter,
    setProjectsOwnerFilter,
    searchQuery,
    searchText,
    updateSearchText,
  } = useListOfProjects<ProjectsFolder>();

  const [state, setState] = useSetState<AdminTabsState>(getInitialState(isTechAdmin));
  const {
    projectsLibraryFilter,
    selectedTabId,
    creatingProject,
    creatingOrEditingFolder,
    selectedProjects,
    selectedFolderId,
    selectedFolderAction,
    confirmProjectAction,
  } = state;

  const [updateProjectsData, { loading: updatingProjectsData }] = useMutation(
    UpdateProjectsDataMutation
  );

  const [updateProjectsAndFoldersStatus, { loading: updatingProjectsAndFoldersStatus }] =
    useMutation(UpdateProjectsAndFoldersStatusMutation);

  const projectsQueryVariables: GetProjectsQueryVariables = {
    userId,
    currentStatus: mapTabIdToProjectStatus(selectedTabId as ManagerTabIds),
    filterBy: {
      _or: [
        {
          name: {
            _ilike: searchQuery,
          },
        },
        {
          folder: {
            name: {
              _ilike: searchQuery,
            },
          },
        },
      ],
      team_members:
        projectsOwnerFilter === ProjectsOwnerFilter.Member
          ? { user_id: { _eq: userId } }
          : undefined,
      claims_counts: mapLibraryFilterToObject(projectsLibraryFilter),
    },
    foldersFilterBy: {
      _or: [
        { name: { _ilike: searchQuery } },
        {
          projects: {
            name: {
              _ilike: searchQuery,
            },
          },
        },
      ],
    },
    orderBy:
      activeListSortOrder === 'latest_first' ? ORDER_BY_LATEST_FIRST : ORDER_BY_NAME_ALPHABETICALLY,
    foldersOrderBy:
      activeListSortOrder === 'latest_first' ? ORDER_BY_LATEST_FIRST : ORDER_BY_NAME_ALPHABETICALLY,
  };

  const {
    loading,
    called: everFetched,
    error,
    data,
    refetch,
  } = useQuery<GetProjectsQueryResult, GetProjectsQueryVariables>(GetProjectsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: projectsQueryVariables,
  });

  const activeFolders: ProjectsFolder[] = data?.activeFolders ?? EMPTY_PROJECTS_FOLDERS;

  const allProjects: ProjectData[] = data?.projects ?? [];

  // we are grouping projects by folders on the client-side, because it turns out that
  // the query is 10x faster this way
  const [folders, projectsWithoutFolder] = useMemo(() => {
    const folders: ProjectsFolder[] = cloneDeep(data?.folders ?? EMPTY_PROJECTS_FOLDERS);
    folders.forEach((folder) => {
      folder.projects = [];
    });

    const projectsWithoutFolder: ProjectData[] = [];
    allProjects.forEach((project) => {
      if (project.folder?.id) {
        const folder = folders.find((f) => f.id === project.folder?.id);
        if (folder) {
          folder.projects.push(project);
        }
      } else {
        projectsWithoutFolder.push(project);
      }
    });
    return [folders, projectsWithoutFolder];
  }, [data?.folders, allProjects]);

  const isProjectsMemberFilterSelected = projectsOwnerFilter === ProjectsOwnerFilter.Member;
  const isProjectsLibraryFilterSelected = projectsLibraryFilter !== ProjectsLibraryFilter.All;
  const isArchivedOrDeletedTabSelected = [ManagerTabIds.Archived, ManagerTabIds.Deleted].includes(
    selectedTabId as ManagerTabIds
  );
  
  const filteredFolders: ProjectsFolder[] = useMemo(
    () => (
      folders.filter((folder) =>
        isProjectsMemberFilterSelected ||
        isProjectsLibraryFilterSelected ||
        isArchivedOrDeletedTabSelected
          ? folder.projects.length > 0
          : folder.status === ProjectStatus.Active
      )
    ), [
      folders,
      isProjectsMemberFilterSelected,
      isProjectsLibraryFilterSelected,
      isArchivedOrDeletedTabSelected,
    ]
  );

  const selectedFolder = useMemo(
    () => find(propEq('id', selectedFolderId), folders),
    [selectedFolderId, folders]
  );

  const projectCounts: ProjectCounts = {
    SCREENING: data?.screening.aggregate.count ?? 0,
  };

  const filtersApplied =
    searchQuery != '%%' ||
    projectsLibraryFilter !== ProjectsLibraryFilter.All ||
    projectsOwnerFilter !== (isTechAdmin ? ProjectsOwnerFilter.All : ProjectsOwnerFilter.Member);

  const selectedProjectIds: string[] = useMemo(
    () =>
      compose(
        flatten,
        map((key) => {
          if (key === 'main') {
            return selectedProjects.main.map((idx) => projectsWithoutFolder[idx]?.id);
          } else {
            const folder = find(propEq('id', key), folders);
            return folder
              ? selectedProjects[key].map((idx) => folder.projects[idx]?.id)
              : [];
          }
        }),
        keys
      )(selectedProjects),
    [selectedProjects, projectsWithoutFolder, folders]
  );

  const updateSelectedProjects = useCallback(
    (folderId: string | null, updateFn: (selected: number[]) => number[]) => {
      const folderKey = folderId ?? 'main';
      setState((prevState) => ({
        ...prevState,
        selectedProjects: {
          ...prevState.selectedProjects,
          [folderKey]: updateFn(prevState.selectedProjects[folderKey] ?? []),
        },
      }));
    },
    [setState]
  );

  const toggleCreatingProject = useCallback(
    (folderId?: string | null) =>
      setState((prevState) => ({
        ...prevState,
        creatingProject: !prevState.creatingProject,
        selectedFolderId: folderId ?? null,
      })),
    [setState]
  );

  const toggleCreatingProjectsFolder = useCallback(
    () =>
      setState((prevState) => ({
        ...prevState,
        creatingOrEditingFolder: !prevState.creatingOrEditingFolder,
      })),
    [setState]
  );

  const closeProjectsFolderDialog = useCallback(
    () =>
      setState((prevState) => ({
        ...prevState,
        creatingOrEditingFolder: false,
        selectedFolderId: null,
      })),
    [setState]
  );

  const handleUpdateProjectsStatus = useCallback(
    async (payload: { projectIds: string[]; status: ProjectStatus }): Promise<any> => {
      const { projectIds, status } = payload;
      if (isNil(status)) return;

      // if the action is "restore" (setting the status = "ACTIVE")
      // get the folderIds to update their status to "ACTIVE"
      const folderIdsToUpdate =
        status === ProjectStatus.Active
          ? compose(
              map('id'),
              filter(
                (folder: ProjectsFolder) =>
                  intersectionWith(
                    (projectId: string, project: ProjectData) => projectId === project.id,
                    projectIds,
                    folder.projects
                  ).length > 0
              )
            )(folders)
          : [];

      return updateProjectsAndFoldersStatus({
        variables: {
          projectIds,
          folderIds: folderIdsToUpdate,
          status,
        },
      })
        .then(() => {
          insertActionLog('changed projects and folders status', {
            projectIds,
            folderIds: folderIdsToUpdate,
            status,
          });
          AppToaster.show({
            message: i18n._(
              plural({
                value: projectIds.length,
                one: 'Successfully changed the project status',
                other: 'Successfully changed the status of # projects',
              })
            ),
            intent: Intent.SUCCESS,
          });
          refetch();
        })
        .catch((err) => {
          AppToaster.show({
            message: `${i18n._(
              plural({
                value: projectIds.length,
              one: 'Failed to change the project status',
              other: 'Failed to change the status of # projects',
              })
            )}: ${err.message}`,
            intent: Intent.WARNING,
          });
        })
        .finally(() => {
          setState({ selectedProjects: EMPTY_SELECTED_PROJECTS, confirmProjectAction: null });
        });
    },
    [updateProjectsAndFoldersStatus, insertActionLog, setState, refetch, i18n, folders]
  );

  const handleUpdateFolderStatus = useCallback(
    (payload: { folderId: string; status: ProjectStatus }) => {
      const { folderId, status } = payload;
      const folder = find(propEq('id', folderId), folders);
      if (isNil(folder)) return;

      const projectIdsToUpdate: string[] = map('id', folder.projects);

      return updateProjectsAndFoldersStatus({
        variables: {
          folderIds: [folderId],
          projectIds: projectIdsToUpdate,
          status,
        },
      })
        .then(() => {
          insertActionLog('changed folders and projects status', {
            folderIds: [folderId],
            projectIds: projectIdsToUpdate,
            status: status,
          });
          AppToaster.show({
            message: i18n._(t`Successfully changed the folder status`),
            intent: Intent.SUCCESS,
          });
          refetch();
        })
        .catch((err) => {
          AppToaster.show({
            message: `${i18n._(t`Failed to change the folder status`)}: ${err.message}`,
            intent: Intent.WARNING,
          });
        })
        .finally(() => {
          setState({
            selectedProjects: EMPTY_SELECTED_PROJECTS,
            confirmProjectAction: null,
            selectedFolderId: null,
            selectedFolderAction: null,
          });
        });
    },
    [updateProjectsAndFoldersStatus, insertActionLog, setState, refetch, i18n, folders]
  );

  const handleMoveToFolder = useCallback(
    async (payload: { projectIds: string[]; folderId: string | null }): Promise<any> => {
      const { projectIds, folderId } = payload;
      return updateProjectsData({
        variables: {
          projectIds,
          projectData: { folder_id: folderId },
        },
      })
        .then(() => {
          insertActionLog('changed projects folder', {
            projectIds,
            folderId,
          });
          folderId
            ? AppToaster.show({
                message: i18n._(
                  plural({
                    value: projectIds.length,
                  one: 'Successfully moved the project to the folder',
                  other: 'Successfully moved # projects to the folder',
                  })
                ),
                intent: Intent.SUCCESS,
              })
            : AppToaster.show({
                message: i18n._(
                  plural({
                    value: projectIds.length,
                    one: 'Successfully removed the project from the folder',
                    other: 'Successfully removed # projects from the folder',
                  })
                ),
                intent: Intent.SUCCESS,
              });
          refetch();
        })
        .catch((err) => {
          folderId
            ? AppToaster.show({
                message: `${i18n._(
                  plural({
                    value: projectIds.length,
                    one: 'Failed to move the project to the folder',
                    other: 'Failed to move # projects to the folder',
                  })
                )}: ${err.message}`,
                intent: Intent.WARNING,
              })
            : AppToaster.show({
                message: `${i18n._(
                  plural({
                    value: projectIds.length,
                    one: 'Failed to remove the project from the folder',
                    other: 'Failed to remove # projects from the folder',
                  })
                )}: ${err.message}`,
                intent: Intent.WARNING,
              });
        })
        .finally(() => {
          setState({ selectedProjects: EMPTY_SELECTED_PROJECTS, confirmProjectAction: null });
        });
    },
    [updateProjectsData, insertActionLog, setState, refetch, i18n]
  );

  const getBatchActions = useCallback(
    (selectedTabId: ManagerTabIds): ProjectsListBatchAction[] => {
      const batchActions = [
        [ManagerTabIds.Active].includes(selectedTabId) && {
          title: i18n._(t`Move to folder`),
          icon: IconNames.ADD_TO_FOLDER,
          handler: () =>
            setState({
              confirmProjectAction: SelectedProjectAction.MoveToFolder,
            }),
        },
        [ManagerTabIds.Active].includes(selectedTabId) && {
          title: i18n._(t`Archive`),
          icon: IconNames.ARCHIVE,
          handler: () =>
            setState({
              confirmProjectAction: SelectedProjectAction.Archive,
            }),
        },
        [ManagerTabIds.Archived, ManagerTabIds.Deleted].includes(selectedTabId) && {
          title: i18n._(t`Restore`),
          icon: IconNames.UNDO,
          handler: () =>
            setState({
              confirmProjectAction: SelectedProjectAction.Restore,
            }),
        },
        [ManagerTabIds.Active, ManagerTabIds.Archived].includes(selectedTabId) && {
          title: i18n._(t`Delete`),
          icon: IconNames.TRASH,
          handler: () =>
            setState({
              confirmProjectAction: SelectedProjectAction.Delete,
            }),
        },
      ];
      return compact(batchActions);
    },
    [setState, i18n]
  );

  const handleChangeSelectedTab = useCallback(
    (tabId: TabId) => {
      setState({ selectedTabId: tabId, selectedProjects: EMPTY_SELECTED_PROJECTS });
    },
    [setState]
  );

  const filtersToggle = useMemo(
    () =>
      renderFiltersToggle({
        filtersApplied,
        activePanel,
        togglePanel: setActivePanel,
      }),
    [setActivePanel, activePanel, filtersApplied]
  );

  const togglePanel = useCallback(
    (panel) => setActivePanel((prev) => (prev === panel ? null : panel)),
    [setActivePanel]
  );

  const handleResetProjectsSearch = useCallback(() => {
    setState(getInitialState(isTechAdmin));
  }, [setState, isTechAdmin]);

  const handleSelectFolderAndAction = useCallback(
    (folderId: string, action: TSelectedFolderAction) => {
      setState({
        selectedFolderId: folderId,
        creatingOrEditingFolder: action === 'showSettings',
        selectedFolderAction: action,
      });
    },
    [setState]
  );

  const handleCloseDialog = useCallback(() => {
    setState({
      selectedFolderId: null,
      selectedFolderAction: null,
      confirmProjectAction: null,
    });
  }, [setState]);

  const handleDeleteConfirmation = useCallback(() => {
    if (isNil(selectedFolderId)) return;
    handleUpdateFolderStatus({
      folderId: selectedFolderId,
      status: ProjectStatus.InTrash,
    });
    handleCloseDialog();
  }, [handleUpdateFolderStatus, handleCloseDialog, selectedFolderId]);

  const handleMoveToFolderConfirmation = useCallback(
    (folderId: string | null) => handleMoveToFolder({ projectIds: selectedProjectIds, folderId }),
    [selectedProjectIds, handleMoveToFolder]
  );

  const renderSelectedTab = useCallback(
    (tabId: TabId) => {
      switch (tabId) {
        case ManagerTabIds.Active:
        case ManagerTabIds.Archived:
        case ManagerTabIds.Deleted:
          return (
            <ListForManagers
              loading={loading || updatingProjectsData || updatingProjectsAndFoldersStatus}
              projectsStatus={mapTabIdToProjectStatus(tabId)}
              projects={projectsWithoutFolder}
              folders={filteredFolders}
              activeFolders={activeFolders}
              selectedProjects={selectedProjects}
              updateSelectedProjects={updateSelectedProjects}
              updateProjectsStatus={handleUpdateProjectsStatus}
              moveProjectsToFolder={handleMoveToFolder}
              selectFolderAndAction={handleSelectFolderAndAction}
              updateFolderStatus={handleUpdateFolderStatus}
              foldersCollapsed={foldersCollapsed}
              toggleFolderCollapsed={toggleFolderCollapsed}
              toggleCreatingProject={toggleCreatingProject}
            />
          );
        case ManagerTabIds.Screening:
          return <ListForScreeners />;
        default:
          return null;
      }
    },
    [
      loading,
      updatingProjectsData,
      updatingProjectsAndFoldersStatus,
      projectsWithoutFolder,
      filteredFolders,
      activeFolders,
      selectedProjects,
      updateSelectedProjects,
      handleUpdateProjectsStatus,
      handleMoveToFolder,
      handleSelectFolderAndAction,
      handleUpdateFolderStatus,
      foldersCollapsed,
      toggleFolderCollapsed,
      toggleCreatingProject,
    ]
  );

  const batchSize = useMemo(
    () =>
      reduce<string, number>(
        (acc, key) => {
          acc += selectedProjects[key].length;
          return acc;
        },
        0,
        Object.keys(selectedProjects)
      ),
    [selectedProjects]
  );

  // initialize foldersCollapsed after fetching folders
  useEffect(() => {
    if (folders) {
      populateFoldersCollapsed(folders);
    }
  }, [populateFoldersCollapsed, JSON.stringify(folders)]);

  if (error) {
    return <ErrorScreen error={error} retry={() => refetch()} />;
  }

  return loading && !everFetched ? (
    <Spinner />
  ) : (
    <div className="h-full w-full flex flex-col overflow-none">
      <ProjectsListHeader
        tabs={managerTabs}
        selectedTabId={selectedTabId}
        handleChangeSelectedTab={handleChangeSelectedTab}
        projectCounts={projectCounts}
        toggleCreatingProject={() => toggleCreatingProject(null)}
        toggleCreatingFolder={toggleCreatingProjectsFolder}
        leftElement={isTechAdmin ? filtersToggle : undefined}
        batchActions={getBatchActions(selectedTabId as ManagerTabIds)}
        batchSize={batchSize}
      />
      <div className="flex flex-row w-full h-full overflow-auto">
        <div
          className={Classes.ELEVATION_0}
          css={[activePanel === 'filters' ? panelExpandedColCss : panelCollapsedColCss]}
        >
          <ProjectsFiltersColumn
            onToggle={() => togglePanel('filters')}
            filtersApplied={filtersApplied}
            onResetProjectsSearch={handleResetProjectsSearch}
            projectsLibraryFilter={projectsLibraryFilter}
            onChangeProjectsLibraryFilter={(projectsLibraryFilter) =>
              setState({ projectsLibraryFilter })
            }
          />
        </div>
        <div className="flex-grow overflow-auto" css={{ width: activePanel ? '67%' : '100%' }}>
          <div>
            {selectedTabId !== ManagerTabIds.Screening && (
              <ProjectsListFilters
                searchText={searchText}
                onUpdateSearchText={updateSearchText}
                projectsOwnerFilter={projectsOwnerFilter}
                onChangeProjectsOwnerFilter={setProjectsOwnerFilter}
                activeSortOrder={activeListSortOrder}
                updateListSortOrder={setSortOrder}
                allCollapsed={allCollapsed}
                toggleCollapseAllFolders={loading ? undefined : toggleCollapseAllFolders}
              />
            )}
          </div>
          <div>{renderSelectedTab(selectedTabId)}</div>
        </div>
        <CreateProjectDialog
          isOpen={creatingProject}
          onClose={() => toggleCreatingProject()}
          folders={activeFolders}
          folderId={selectedFolderId}
        />
        <CreateProjectsFolderDialog
          isOpen={creatingOrEditingFolder}
          onClose={closeProjectsFolderDialog}
          projectsQueryVariables={projectsQueryVariables}
          refetchProjects={refetch}
          folderId={selectedFolder?.id}
          folderName={selectedFolder?.name}
        />
        <BatchActionsAlert
          isOpen={
            !isNil(confirmProjectAction) &&
            [
              SelectedProjectAction.Archive,
              SelectedProjectAction.Restore,
              SelectedProjectAction.Delete,
            ].includes(confirmProjectAction)
          }
          type={getProjectStatusFromActionType(confirmProjectAction)}
          projectIds={selectedProjectIds}
          onConfirm={handleUpdateProjectsStatus}
          onCancel={() => setState({ confirmProjectAction: null })}
        />
        <MoveProjectsToFolderDialog
          isOpen={confirmProjectAction === SelectedProjectAction.MoveToFolder}
          onClose={handleCloseDialog}
          projectIds={selectedProjectIds}
          folders={activeFolders}
          onConfirm={handleMoveToFolderConfirmation}
        />
        <Alert
          isOpen={selectedFolderId != null && selectedFolderAction === 'delete'}
          icon={IconNames.TRASH}
          intent={Intent.DANGER}
          cancelButtonText={i18n._(t`Cancel`)}
          confirmButtonText={i18n._(t`Delete`)}
          onCancel={handleCloseDialog}
          onConfirm={handleDeleteConfirmation}
        >
          <Trans>
            Are you sure you want to delete{' '}
            <span className="font-bold">{selectedFolder?.name}</span> folder?
          </Trans>
        </Alert>
      </div>
    </div>
  );
};

export default AdminTabs;
