import { Colors, Icon, IconSize, Intent, MenuItem, Tag } from '@blueprintjs/core';
import { t, Trans } from '@lingui/macro';
import {
  compose,
  every,
  get,
  groupBy,
  isEmpty,
  isNil,
  map,
  mapValues,
  propEq,
  reduce,
  sortBy,
} from 'lodash/fp';
import { add, formatDistanceWithOptions, formatWithOptions } from 'date-fns/fp';
import React, { Fragment, ReactNode } from 'react';
import {
  FTScreeningCriteria,
  FTScreeningFormData,
  GetProjectsQueryVariables,
  IFoldersCollapsed,
  InclusionExclusionCriteriaFormData,
  InclusionStatus,
  ManagerTabIds,
  Project,
  ProjectsFolder,
  ProjectStatus,
  QcStrategyType,
  ScreeningListMode,
  SelectedProjectAction,
  StageType,
  Timestamp,
} from '../../common/types';
import i18n from '../../i18n';
import { StageStats } from '../../lib/study_helpers';
import { de, enUS } from 'date-fns/locale';
import { ProjectsLibraryFilter, ProjectsOwnerFilter, uid } from '../../lib/utils';
import { IconName, IconNames } from '@blueprintjs/icons';
import { GetProjectsQueryResult } from './admin_tabs';
import { loader } from 'graphql.macro';
import { DataProxy } from 'apollo-cache';
import { css } from '@emotion/core';

const GetProjectsQuery = loader('../../graphql/get_admin_projects.gql');

export const BASE_STAGE_TEMPLATES: Record<Exclude<StageType, StageType.DataExtraction>, string> = {
  [StageType.PreliminaryScreening]: window.REACT_APP_BASE_STAGE_TEMPLATE_PRELIMINARY ?? 'iqwig demo preliminary screening form',
  [StageType.TitlesAbstractScreening]: window.REACT_APP_BASE_STAGE_TEMPLATE_TITLES_ABSTRACT ?? 'pharmakologische Vorlage',
  [StageType.FullTextScreening]: window.REACT_APP_BASE_STAGE_TEMPLATE_FULL_TEXT ?? 'iqwig demo ft screening form',
};

const locales = {
  en: enUS,
  de,
};
const locale = locales[window.REACT_APP_DEFAULT_LOCALE] ?? locales['en'];

export function formatDate(timestamp: string | number | Date, formatStr: string) {
  return formatWithOptions({ locale }, formatStr, new Date(timestamp));
}

export function formatDateDistance(
  timestampFrom: string | number | Date,
  timestampTo?: string | number | Date
) {
  return formatDistanceWithOptions(
    { locale },
    timestampTo ? new Date(timestampTo) : new Date(),
    new Date(timestampFrom)
  );
}

export function getDate(timestamp: string | number | Date) {
  return formatDate(timestamp, 'dd/MM/yyyy');
}

export function getHumanizedDuration(duration: Duration): string {
  return formatDistanceWithOptions({ locale, includeSeconds: true }, add(duration, 0), 0);
}

export function getLocalizedDateTime(str?: string) {
  try {
    return str ? formatDate(str as Timestamp, `dd/MM/yyyy '${i18n._(t`at`)}' HH:mm`) : '-';
  } catch (err) {
    console.log('Provided string is not a valid datetime');
    return '-';
  }
}

const STATUS_DISPLAY = {
  [ProjectStatus.Active]: 'Active',
  [ProjectStatus.Archived]: 'Archived',
  [ProjectStatus.InTrash]: 'In trash',
};

export const STATUS_FILTER_DISPLAY = {
  [ProjectStatus.Active]: 'Active projects',
  [ProjectStatus.Archived]: 'Archive',
  [ProjectStatus.InTrash]: 'In trash',
};

export function getProjectStatus(project: Project): string {
  return STATUS_DISPLAY[project.status];
}

const QC_STRATEGY_DISPLAY = {
  [QcStrategyType.Single]: 'Primary Extractor No QA',
  [QcStrategyType.Double]: 'Two Extractors No QA',
  [QcStrategyType.SingleExtractorSingleQA]: 'Primary Extractor with Single QA',
};

export function getQcStrategyDisplayName(qcStrategyType: QcStrategyType): string {
  return QC_STRATEGY_DISPLAY[qcStrategyType];
}

export function getScreeningTypeInfo(screeningTypeId: string): { label: string; helpText: string } {
  switch (screeningTypeId) {
    case 'single':
      return {
        label: i18n._(t`Single screening`),
        helpText: i18n._(t`Each reference is reviewed by one reviewer`),
      };
    case 'double':
      return {
        label: i18n._(t`Double screening`),
        helpText: i18n._(t`Each reference is reviewed by two reviewers`),
      };
    default:
      return {
        label: screeningTypeId,
        helpText: screeningTypeId,
      };
  }
}

export function getProjectStatusLabel(status: ProjectStatus, stageStats: StageStats[]) {
  if (!isEmpty(stageStats) && every(propEq('completed', true), stageStats)) {
    return (
      <Tag intent={Intent.SUCCESS}>
        <Trans>Completed</Trans>
      </Tag>
    );
  }

  switch (status) {
    case ProjectStatus.Active:
      return i18n._(t`Active`);
    case ProjectStatus.Archived:
      return i18n._(t`Archived`);
    case ProjectStatus.InTrash:
      return i18n._(t`In trash`);
    default:
      return '-';
  }
}

export function getFullPhoneNumber(areaCode?: string | null, phoneNumber?: string | null): string {
  if (phoneNumber == null) return '-';

  return `${areaCode ? `${areaCode} ` : ''}${phoneNumber}`;
}

export enum StageStatus {
  Completed,
  Started,
  Drafted,
  Omitted,
}

function combineStatuses(sA: StageStatus, sB: StageStatus): StageStatus {
  return sortBy((status) => status.valueOf(), [sA, sB])[0];
}

export type ProjectStageStatus = {
  completed: boolean;
  project_id: string;
  stage_id: string;
  stage_order_number: number;
  stage_type: StageType;
  started: boolean;
};

export function getStageTypesStatuses(
  stagesStatus: ProjectStageStatus[]
): Record<StageType, StageStatus> {
  const stagesStatusesGrouped = mapValues(
    compose(
      reduce(combineStatuses, StageStatus.Omitted),
      map((stageStatus: ProjectStageStatus) =>
        stageStatus.completed
          ? StageStatus.Completed
          : stageStatus.started
          ? StageStatus.Started
          : StageStatus.Drafted
      )
    ),
    groupBy('stage_type', stagesStatus)
  );

  return {
    [StageType.PreliminaryScreening]:
      get(StageType.PreliminaryScreening, stagesStatusesGrouped) ?? StageStatus.Omitted,
    [StageType.TitlesAbstractScreening]:
      get(StageType.TitlesAbstractScreening, stagesStatusesGrouped) ?? StageStatus.Omitted,
    [StageType.FullTextScreening]:
      get(StageType.FullTextScreening, stagesStatusesGrouped) ?? StageStatus.Omitted,
    [StageType.DataExtraction]:
      get(StageType.DataExtraction, stagesStatusesGrouped) ?? StageStatus.Omitted,
  };
}

export function getScreeningListModeFromQueryParams(
  params: URLSearchParams
): ScreeningListMode | undefined {
  const listModeParam = params.get('listMode');

  switch (listModeParam) {
    case ScreeningListMode.Completed:
      return ScreeningListMode.Completed;
    case ScreeningListMode.ToScreen:
      return ScreeningListMode.ToScreen;
    default:
      return;
  }
}

export function tiabFormDataToFTFormData(
  tiabFormData: InclusionExclusionCriteriaFormData
): FTScreeningFormData {
  return {
    inclusion: [],
    exclusion: tiabFormData.domains.map(({ id, name, exclusionCode, variables }) => {
      const criteriaData: FTScreeningCriteria = {
        id,
        name,
        code: exclusionCode,
        inclusionStatus: InclusionStatus.Excluded,
      };
      const firstVar = variables[0];
      if (firstVar) {
        if (firstVar.keywords) criteriaData['keywords'] = firstVar.keywords;
        if (firstVar.instructions) criteriaData['instruction'] = firstVar.instructions;
        criteriaData.variableId = firstVar.id;
      }

      return criteriaData;
    }),
    tags: tiabFormData.tags ?? [],
  };
}

export function ftFormDataToTiabFormData(
  ftFormData: FTScreeningFormData
): InclusionExclusionCriteriaFormData {
  return {
    domains: ftFormData.exclusion.map(({ id, name, code, instruction, keywords, variableId }) => ({
      id,
      name,
      exclusionCode: code,
      exclusionButtonLabel: `${code}: ${name}`,
      variables: [
        {
          id: variableId ?? uid(),
          name,
          title: '',
          instructions: instruction ?? null,
          keywords: keywords ?? null,
        },
      ],
    })),
    tags: ftFormData.tags,
  };
}

export function isTiabFormData(
  formData: InclusionExclusionCriteriaFormData | FTScreeningFormData
): formData is InclusionExclusionCriteriaFormData {
  return get('domains', formData) != null;
}

export function markScreeningTagsCopied(
  formData: InclusionExclusionCriteriaFormData | FTScreeningFormData
): typeof formData {
  const tags = formData.tags;

  if (!isEmpty(tags)) {
    return {
      ...formData,
      tags: formData.tags!.map((tag) => ({ ...tag, copied: true })),
    };
  }

  return formData;
}

const ARCHIVE_ACTION = {
  icon: IconNames.ARCHIVE as IconName,
  status: ProjectStatus.Archived,
  text: i18n._(t`Archive`),
};

const DELETE_ACTION = {
  icon: IconNames.TRASH as IconName,
  status: ProjectStatus.InTrash,
  text: i18n._(t`Delete`),
};

const RESTORE_ACTION = {
  icon: IconNames.UNDO as IconName,
  status: ProjectStatus.Active,
  text: i18n._(t`Restore`),
};

type TTitleAndTextStatusSpec = {
  title: string;
  possibleStatuses: { status: ProjectStatus; text: string; icon: IconName }[];
};

export const TITLE_AND_NEXT_PROJECT_STATUSES: Record<ProjectStatus, TTitleAndTextStatusSpec> = {
  [ProjectStatus.Active]: {
    title: i18n._(t`Active projects`),
    possibleStatuses: [ARCHIVE_ACTION, DELETE_ACTION],
  },
  [ProjectStatus.Archived]: {
    title: i18n._(t`Archived projects`),
    possibleStatuses: [RESTORE_ACTION, DELETE_ACTION],
  },
  [ProjectStatus.InTrash]: {
    title: i18n._(t`Deleted projects`),
    possibleStatuses: [RESTORE_ACTION],
  },
};

export const TITLE_AND_NEXT_FOLDER_STATUSES: Record<ProjectStatus, TTitleAndTextStatusSpec> = {
  [ProjectStatus.Active]: {
    title: i18n._(t`Active folders`),
    possibleStatuses: [ARCHIVE_ACTION, DELETE_ACTION],
  },
  [ProjectStatus.Archived]: {
    title: i18n._(t`Archived folders`),
    possibleStatuses: [RESTORE_ACTION, DELETE_ACTION],
  },
  [ProjectStatus.InTrash]: {
    title: i18n._(t`Deleted folders`),
    possibleStatuses: [RESTORE_ACTION],
  },
};

export function renderClaimsIcon({
  hasReferencesToClaim,
  hasClaimedReferences,
  iconSize,
}: {
  hasReferencesToClaim?: boolean;
  hasClaimedReferences?: boolean;
  iconSize?: number;
}) {
  return hasReferencesToClaim ? (
    <Icon icon={IconNames.FULL_CIRCLE} size={iconSize} color={Colors.ORANGE4} />
  ) : hasClaimedReferences ? (
    <Icon icon={IconNames.CIRCLE} size={iconSize} intent={Intent.PRIMARY} />
  ) : (
    <Icon icon={IconNames.FULL_CIRCLE} size={iconSize} intent={Intent.PRIMARY} />
  );
}

export function mapTabIdToProjectStatus(tabId: ManagerTabIds): ProjectStatus {
  switch (tabId) {
    case ManagerTabIds.Active:
      return ProjectStatus.Active;
    case ManagerTabIds.Archived:
      return ProjectStatus.Archived;
    case ManagerTabIds.Deleted:
      return ProjectStatus.InTrash;
    default:
      return undefined as unknown as ProjectStatus;
  }
}

export function mapLibraryFilterToObject(
  projectsLibraryFilter: ProjectsLibraryFilter
): object | undefined {
  switch (projectsLibraryFilter) {
    case ProjectsLibraryFilter.WithClaims:
      return { claims_in_progress_count: { _gt: 0 } };
    case ProjectsLibraryFilter.WithRefsToClaim:
      return { refs_without_attachments_count: { _gt: 0 } };
    case ProjectsLibraryFilter.WithoutUnfinishedClaims:
      return { claims_in_progress_count: { _eq: 0 }, refs_without_attachments_count: { _eq: 0 } };
    default:
      return;
  }
}

export function getProjectStatusFromActionType(actionType: SelectedProjectAction | null) {
  switch (actionType) {
    case SelectedProjectAction.Archive:
      return ProjectStatus.Archived;
    case SelectedProjectAction.Restore:
      return ProjectStatus.Active;
    case SelectedProjectAction.Delete:
      return ProjectStatus.InTrash;
    default:
      return null;
  }
}

export const addFolderToProjectsQueryCache = (payload: {
  proxy: DataProxy;
  data: object;
  newFolderName: string;
  projectsQueryVariables: GetProjectsQueryVariables;
  refetchProjects: () => void;
}): void => {
  const { proxy, data, newFolderName, projectsQueryVariables, refetchProjects } = payload;
  const migrationResult = get('insert_project_folder_one', data);
  if (isNil(migrationResult)) return;
  const newFolder: ProjectsFolder = {
    id: migrationResult.id,
    name: newFolderName,
    status: ProjectStatus.Active,
    created_at: migrationResult.created_at,
    deleted_at: null,
    projects: [],
    __typename: 'project_folder',
  };
  const queryResult: GetProjectsQueryResult | null = proxy.readQuery({
    query: GetProjectsQuery,
    variables: projectsQueryVariables,
  });
  if (isNil(queryResult)) return refetchProjects();
  const updatedQueryResult = {
    ...queryResult,
    allFolders: [newFolder, ...queryResult.folders],
    folders: [newFolder, ...queryResult.folders],
  };
  proxy.writeQuery({
    query: GetProjectsQuery,
    variables: projectsQueryVariables,
    data: updatedQueryResult,
  });
};

export const folderSelectOptionRenderer = (
  selected,
  folder: ProjectsFolder | null,
  { handleClick }
) => {
  return (
    <MenuItem
      key={folder?.id ?? 'null'}
      onClick={handleClick}
      disabled={!isNil(folder) && folder.status != ProjectStatus.Active}
      text={isNil(folder) ? <Trans>outside of folders</Trans> : folder.name}
      active={selected?.id === folder?.id ?? null}
    />
  );
};

export const projectsOwnerFilterOptions: { value: ProjectsOwnerFilter; label: string }[] = [
  {
    value: ProjectsOwnerFilter.All,
    label: i18n._(t`All projects`),
  },
  {
    value: ProjectsOwnerFilter.Member,
    label: i18n._(t`Only my projects`),
  },
];

// custom small circle icon size
// ensures text-to-icon size ratio as the text is of BP.STANDARD size
export const smallCircleIconSize: number = IconSize.STANDARD * 0.75; // 12px

export const projectsLibraryFilterOptions: {
  value: ProjectsLibraryFilter;
  labelElement: ReactNode;
}[] = [
  {
    value: ProjectsLibraryFilter.All,
    labelElement: <Trans>All projects visible</Trans>,
  },
  {
    value: ProjectsLibraryFilter.WithoutUnfinishedClaims,
    labelElement: (
      <Fragment>
        {renderClaimsIcon({ iconSize: smallCircleIconSize })}
        <span className="ml-2">
          <Trans>Projects without the need for PDFs</Trans>
        </span>
      </Fragment>
    ),
  },
  {
    value: ProjectsLibraryFilter.WithClaims,
    labelElement: (
      <Fragment>
        {renderClaimsIcon({ hasClaimedReferences: true, iconSize: smallCircleIconSize })}
        <span className="ml-2">
          <Trans>Projects with claimed PDFs</Trans>
        </span>
      </Fragment>
    ),
  },
  {
    value: ProjectsLibraryFilter.WithRefsToClaim,
    labelElement: (
      <Fragment>
        {renderClaimsIcon({ hasReferencesToClaim: true, iconSize: smallCircleIconSize })}
        <span className="ml-2">
          <Trans>Projects with unclaimed PDFs</Trans>
        </span>
      </Fragment>
    ),
  },
];

export function getFolderTitleColumnLabel(folderName?: string, projectsStatus?: ProjectStatus) {
  switch (projectsStatus) {
    case ProjectStatus.Active:
      return folderName ?? i18n._(t`Project title`);
    case ProjectStatus.Archived:
      return folderName ? `${i18n._(t`Archived from`)} ${folderName}` : i18n._(t`Archived`);
    case ProjectStatus.InTrash:
      return folderName ? `${i18n._(t`Deleted from`)} ${folderName}` : i18n._(t`Deleted`);
  }
}

export function getFolderIcon(
  folderName?: string,
  projectsStatus?: ProjectStatus,
  projectsCount?: number,
  isCollapsed?: boolean
): IconName {
  const iconByStatus =
    projectsStatus === ProjectStatus.Archived
      ? IconNames.BOX
      : projectsStatus === ProjectStatus.InTrash
      ? IconNames.TRASH
      : IconNames.HOME;

  return folderName
    ? isNil(projectsCount) || projectsCount === 0 || isCollapsed
      ? IconNames.FOLDER_CLOSE
      : IconNames.FOLDER_OPEN
    : iconByStatus;
}

export const getTableHeaderOpacityCss = (isCollapsed: boolean = false) => css`
  > div {
    opacity: ${isCollapsed ? '0' : '1'};
    transition: all 0.5s ease-in-out;
  }
`;

export function populateFoldersCollapsed<TFolder>(
  foldersCollapsed: IFoldersCollapsed,
  folders: TFolder[]
) {
  return reduce(
    (acc, curr) => {
      acc[curr['id']] = false;
      return acc;
    },
    foldersCollapsed,
    folders
  );
}
