/** @jsx jsx */
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { Classes, Colors, H5, Icon, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import gql from 'graphql-tag';
import { find, get, groupBy, propEq } from 'lodash/fp';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { AccountType, Project, ProjectStatus, Role, Timestamp, User } from '../../common/types';
import { getAccountTypeLabelPlural } from '../../lib/user_helpers';
import { useI18n } from '../../lib/utils';
import FilterItem from './filter_item';
import ProjectMembersList from './project_members_list';
import UserDetails from './user_details/user_details';
import UsersList from './users_list';

const EMPTY_PROJECTS = [];

const usersQuery = gql`
  query UsersQuery {
    users: user {
      id
      name
      firstName
      lastName
      email
      role
      createdAt
      salutation
      title
      phoneAreaCode
      phoneNumber
      institutionName
      institutionWebsite
      institutionAreaCode
      institutionPhone
      institutionAddress
      institutionCity
      institutionPostalCode
      institutionCountry
      autoDeleteEnabled
      isTechAdmin
    }
  }
`;

const UserActionsLogSubscription = gql`
  subscription GetUserActionsLog {
    logs: user_actions_log(
      where: { event_type: { _eq: "appLoaded" } }
      order_by: { user_id: asc, server_timestamp: desc }
      distinct_on: user_id
    ) {
      server_timestamp
      user_id
    }
  }
`;

export type UserData = Omit<User, 'username'>;

export type MemberedProject = {
  id: string;
  name: string;
  status: ProjectStatus;
  role: Role;
  userData: User;
  lastActive?: Timestamp;
};

const sidebarCss = css`
  flex: 1 1 0;

  .sidebar-heading {
    color: ${Colors.GRAY1};
  }
`;

const usersCss = css`
  flex: 3 1 0;
`;

export interface IUsersProps {
  projects: Project[];
  filterBy?: 'projects' | 'accountType';
}

const Users: React.FC<IUsersProps> = ({ projects, filterBy = 'projects' }) => {
  const { data, loading } = useQuery(usersQuery, { pollInterval: 2000 });
  const { data: logsData, loading: loadingLogs } = useSubscription(UserActionsLogSubscription);
  const i18n = useI18n();
  const [activeProjectId, setActiveProject] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [activeAccountTypeFilter, setActiveAccountTypeFilter] = useState<string | null>(null);
  const userActionLogs = get('logs', logsData) ?? [];

  const userProjects = useMemo(() => {
    return projects.reduce(
      (acc, project) =>
        project.team_members.reduce((acc, teamMember) => {
          const userId = teamMember.user_id;
          const lastActivityTimestamp = get(
            'user_actions_in_project[0].server_timestamp',
            teamMember
          );

          return {
            ...acc,
            [userId]: [
              ...(acc[userId] ?? []),
              {
                id: project.id,
                name: project.name,
                status: project.status,
                lastActive: lastActivityTimestamp,
                role: teamMember.role,
                userData: teamMember.user
              } as MemberedProject,
            ],
          };
        }, acc),
      {} as { [userId: string]: MemberedProject[] }
    );
  }, [projects]);

  const activeProject = useMemo(() => {
    return find({ id: activeProjectId }, projects) as Project | undefined;
  }, [projects, activeProjectId]);

  const users: UserData[] = get('users', data) ?? [];
  const filteredUsers = useMemo(
    () =>
      users.filter(
        (user) => activeAccountTypeFilter == null || user.role === activeAccountTypeFilter
      ),
    [users, activeAccountTypeFilter]
  );

  const usersByRole = groupBy(get('role'), users);

  const resetSelectedUser = useCallback(() => {
    setSelectedUser(null);
  }, [setSelectedUser]);

  return loading || loadingLogs ? (
    <Spinner className="h-full" />
  ) : selectedUser ? (
    <UserDetails
      lastActivity={find(propEq('user_id', selectedUser), userActionLogs)?.server_timestamp}
      user={find({ id: selectedUser }, filteredUsers)!}
      userProjects={userProjects[selectedUser] ?? EMPTY_PROJECTS}
      onClose={resetSelectedUser}
    />
  ) : (
    <div className="w-full h-full flex flex-row flex-no-wrap overflow-auto">
      <div css={sidebarCss} className={`h-full bg-white p-4 ${Classes.ELEVATION_0} overflow-auto`}>
        <div className="flex flex-row flex-no-wrap justify-between sidebar-heading items-center mb-8">
          <span className="text-3xl">
            <Trans>Users</Trans>
          </span>
          <Icon icon={IconNames.USER} iconSize={24} />
        </div>
        <FilterItem
          label={<Trans>All users</Trans>}
          selected={(filterBy === 'projects' ? activeProjectId : activeAccountTypeFilter) == null}
          count={users.length}
          onClick={() =>
            (filterBy === 'projects' ? setActiveProject : setActiveAccountTypeFilter)(null)
          }
        />
        {filterBy === 'projects' ? (
          <Fragment>
            <H5 className="text-gray-600 my-8">{i18n._(t`Active projects`).toUpperCase()}</H5>
            {projects.map((project) => (
              <FilterItem
                key={project.id}
                label={project.name}
                selected={activeProjectId === project.id}
                onClick={() => setActiveProject(project.id || null)}
                count={project.team_members.length}
              />
            ))}
          </Fragment>
        ) : (
          [AccountType.ItAdmin, AccountType.TechAdmin, AccountType.User].map((accountType) => (
            <FilterItem
              key={accountType}
              label={getAccountTypeLabelPlural(accountType)}
              selected={activeAccountTypeFilter === accountType}
              onClick={() => setActiveAccountTypeFilter(accountType)}
              count={(usersByRole[accountType] ?? []).length}
            />
          ))
        )}
      </div>
      <div css={usersCss} className="h-full flex flex-col overflow-auto">
        {activeProject ? (
          <ProjectMembersList project={activeProject} onUserSelect={setSelectedUser} />
        ) : (
          <UsersList
            users={filteredUsers}
            onUserSelect={setSelectedUser}
            userProjects={userProjects}
            userActionLogs={userActionLogs}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
