/** @jsx jsx */
import { jsx } from '@emotion/core';
import { groupBy, has, get, isEmpty } from 'lodash/fp';
import { Card, NonIdealState } from '@blueprintjs/core';
import { ProjectStatus } from '../../../common/types';
import { t, Trans } from '@lingui/macro';
import i18n from '../../../i18n';
import { MemberedProject } from '../users_panel';
import { IconNames } from '@blueprintjs/icons';
import { getRoleLabel } from '../../../lib/user_helpers';

const STATUS_ORDER = [ProjectStatus.Active, ProjectStatus.Archived, ProjectStatus.InTrash];

const STATUS_LABELS = {
  [ProjectStatus.Active]: i18n._(t`Active projects`),
  [ProjectStatus.Archived]: i18n._(t`Archived projects`),
  [ProjectStatus.InTrash]: i18n._(t`Deleted projects`),
};

interface IUserProjects {
  projects: MemberedProject[];
}

const UserProjects: React.FC<IUserProjects> = ({ projects }) => {
  if (isEmpty(projects)) {
    return <NonIdealState icon={IconNames.FOLDER_OPEN} title={<Trans>No projects</Trans>} />;
  }

  const byStatus = groupBy(get('status'), projects);

  return (
    <div className="p-3">
      {STATUS_ORDER.map((status) =>
        has(status, byStatus) ? (
          <div key={status}>
            <div className="text-2xl mb-8">{STATUS_LABELS[status]}</div>
            {byStatus[status].map(({ id, name, role, userData }) => (
              <Card key={id} className="flex flex-col justify-between" elevation={2}>
                <span className="mb-4 text-xl">{name}</span>
                <span className="text-sm">
                  <span className="text-gray-500">
                    <Trans>Role</Trans>:{' '}
                  </span>
                  <span className="text-gray-700">{getRoleLabel(role, userData.role, userData.isTechAdmin)}</span>
                </span>
              </Card>
            ))}
          </div>
        ) : null
      )}
    </div>
  );
};

export default UserProjects;
