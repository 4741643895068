import { t } from '@lingui/macro';
import { get } from 'lodash/fp';
import { AccountType, Role } from '../common/types';
import { TUserFields } from '../components/users/edit_user_dialog';
import { UserData } from '../components/users/users_panel';
import i18n from '../i18n';
import { TScreenerData } from './distribution_helpers';

const alternateTranslationsEnabled: boolean =
  window.REACT_APP_ALTERNATE_TRANSLATIONS_ENABLED === 'true';

const KEYS_TO_OMIT = ['autoDeleteEnabled', '__typename', 'createdAt', 'id', 'name', 'isTechAdmin'];

export function userDataToUserFields(userData: UserData): TUserFields {
  const result = {};

  for (let key in userData) {
    if (KEYS_TO_OMIT.includes(key)) continue;
    const value = userData[key];

    if (value != null) {
      result[key] = value;
    }
  }
  return result as TUserFields;
}

export function isTeamMemberDisabled(teamMember: TScreenerData): boolean {
  return get('user.enabled', teamMember) === false;
}

export function isTeamMemberDeleted(teamMember: TScreenerData): boolean {
  return teamMember.user == null || get('deleted_at', teamMember) != null;
}

export function getRoleLabel(
  role?: Role,
  accountType?: AccountType,
  isTechAdmin?: boolean | null
) {
  switch (role) {
    case Role.Manager:
      return isTechAdmin ? (
        alternateTranslationsEnabled ? i18n._('alt:Manager', { 'alt:Manager': 'Manager' }) : i18n._(t`Manager`)
      ) : (
        i18n._(t`Senior screener`)
      );
    case Role.Screener:
      return accountType === AccountType.TechAdmin ? (
        isTechAdmin ? (
          alternateTranslationsEnabled ? i18n._('alt:Manager/Screener', { 'alt:Manager/Screener': 'Manager/Screener' }) :i18n._(t`Manager/Screener`)
        ) : (
          i18n._(t`Senior screener`)
        )
      ) : (
        alternateTranslationsEnabled ? i18n._('alt:Screener', { 'alt:Screener': 'Screener' }) : i18n._(t`Screener`)
      );
    default:
      return '-';
  }
}

export function getAccountTypeLabel(accountType: AccountType, isTechAdministrator?: boolean | null): string {
  switch (accountType) {
    case AccountType.User:
      return alternateTranslationsEnabled ? (
        i18n._('alt:User', {'alt:User': 'User' })
      ) : (
        i18n._(t`User`)
      );
    case AccountType.TechAdmin:
      return alternateTranslationsEnabled ? (
        i18n._('alt:Tech Administrator', { 'alt:Tech Administrator': 'Tech Administrator' })
      ) : isTechAdministrator ? (
        i18n._(t`Tech Administrator`)
      ) : (
        i18n._(t`Senior screener`)
      );
    case AccountType.ItAdmin:
      return i18n._(t`IT Administrator`);
  }
}

export function getAccountTypeLabelPlural(accountType: AccountType): string {
  switch (accountType) {
    case AccountType.User:
      return i18n._(t`Users`);
    case AccountType.TechAdmin:
      return i18n._(t`Technical Administrators`);
    case AccountType.ItAdmin:
      return i18n._(t`IT Administrators`);
  }
}
