/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { ReactNode, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql.macro';
import { Classes, Colors, ProgressBar, Spinner, Tooltip } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import { compose, filter, first, keyBy, keys, map, round, sortBy } from 'lodash/fp';
import { includes, union } from 'lodash';
import { gray1ColorCss, lightGray4bg } from '../../common/styles';
import { User } from '../../common/types';
import { UserAvatar } from '../header2';

const alternateTranslationsEnabled = window.REACT_APP_ALTERNATE_TRANSLATIONS_ENABLED === 'true';

const GetCurrentStageProgressQuery = gql`
  query GetCurrentStageProgressQuery($stageId: uuid!) {
    stageProgresses: get_stage_progress(args: { stage_id_for: $stageId }) {
      completed_tasks_count
      project_managers
      stage_id
      stage_team_members
      tasks_count
    }
  }
`;

const GetUsersQuery = gql`
  query GetUsersQuery {
    users: user {
      id
      name
      firstName
      lastName
    }
  }
`;

type StageTeamMemberStatus = 'completed' | 'not_started' | 'started';

type StageTeamMembers = {
  [userId: string]: StageTeamMemberStatus;
};

type StageProgressUser = Pick<User, 'id' | 'name' | 'firstName' | 'lastName'>;
type StageProgressUserRole = 'screener' | 'manager' | 'manager_screener';

type StageProgress = {
  completed_tasks_count: number;
  project_managers: string[];
  stage_id: string;
  stage_team_members: StageTeamMembers;
  tasks_count: number;
};

type StageProgressQueryVariables = {
  stageId: string | null;
};

type StageProgressQueryResult = {
  stageProgresses: StageProgress[];
};

type GetUsersQueryResult = {
  users: StageProgressUser[];
};

interface ICurrentStageColumnProps {
  stageId: string | null;
}

type UserToShow = {
  role: StageProgressUserRole;
  status?: StageTeamMemberStatus;
  user: StageProgressUser | null;
  userId: string;
};

export const currentStageColumnHeaderCss = css`
  border-left: 1px solid ${Colors.GRAY5};
  flex: 0 0 470px;
`;

const currentStageColumnCss = css`
  border-top: 1px solid ${Colors.GRAY5};
  ${currentStageColumnHeaderCss}
  ${lightGray4bg}
`;

const progressBarCss = css`
  border-radius: 0;

  & > .${Classes.PROGRESS_METER} {
    background: ${Colors.BLUE3};
    border-radius: 0;
  }
`;

// second of type (2nd div) is the first user element
const userDivCss = css`
  display: grid;
  grid-gap: 6px;
  grid-template-columns: 30px 3fr 10px 1fr;

  &:not(:nth-of-type(2)) {
    padding-top: 1rem;
    border-top: 1px solid ${Colors.GRAY3};
  }
`;

function getUserRoleLabel(userRole: StageProgressUserRole): ReactNode {
  switch (userRole) {
    case 'manager':
      return alternateTranslationsEnabled ? (
        <Trans id='alt:Manager'>Manager</Trans>
      ) : (
        <Trans>Manager</Trans>
      );
    case 'manager_screener':
      return alternateTranslationsEnabled ? (
        <Trans id='alt:Manager/<0/>screener'>
          Manager/
          <br />
          screener
        </Trans>
      ) : (
        <Trans>
          Manager/
          <br />
          screener
        </Trans>
      );
    case 'screener':
      return alternateTranslationsEnabled ? (
        <Trans id='alt:Screener'>Screener</Trans>
      ) : (
        <Trans>Screener</Trans>
      );
  }
}

const commonUserStatusCss = css`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

function getUserStatusElement(userStatus: StageTeamMemberStatus | undefined): ReactNode {
  if (!userStatus) {
    return null;
  }
  const [userStatusCss, tooltip] =
    userStatus === 'completed'
      ? [
          css`
            background: ${Colors.BLUE3};
            ${commonUserStatusCss}
          `,
          <Trans>Completed</Trans>,
        ]
      : userStatus === 'started'
      ? [
          css`
            background: ${Colors.WHITE};
            border: 2px solid ${Colors.BLUE3};
            ${commonUserStatusCss}
          `,
          <Trans>In progress</Trans>,
        ]
      : [
          css`
            background: ${Colors.GRAY3};
            ${commonUserStatusCss}
          `,
          <Trans>Not started</Trans>,
        ];

  return (
    <Tooltip content={tooltip}>
      <div role="status" css={userStatusCss} />
    </Tooltip>
  );
}

const CurrentStageColumn: React.FC<ICurrentStageColumnProps> = ({ stageId }) => {
  const { data, loading } = useQuery<StageProgressQueryResult, StageProgressQueryVariables>(
    GetCurrentStageProgressQuery,
    {
      skip: stageId == null,
      pollInterval: 1000,
      variables: { stageId },
    }
  );

  const { data: usersData, loading: usersLoading } = useQuery<GetUsersQueryResult>(GetUsersQuery, {
    pollInterval: 60000, // users do not change that often
  });

  const stageProgresses = data?.stageProgresses ?? ([] as StageProgress[]);
  const users = usersData?.users ?? ([] as StageProgressUser[]);
  const usersById = useMemo(() => keyBy('id', users), [users]);

  const {
    completed_tasks_count: completedTasksCount,
    project_managers: projectManagers,
    stage_team_members: stageTeamMembers,
    tasks_count: tasksCount,
  } = first(stageProgresses) ?? {
    completed_tasks_count: 0,
    project_managers: [] as string[],
    stage_id: stageId ?? '',
    stage_team_members: {} as StageTeamMembers,
    tasks_count: 0,
  };
  const progress = tasksCount === 0 ? 0 : completedTasksCount / tasksCount;
  const usersToShow = useMemo(() => {
    const stageTeamMembersUserIds = keys(stageTeamMembers);

    const managersWithoutScreeners = filter(
      (userId: string) => !includes(stageTeamMembersUserIds, userId),
      projectManagers
    );

    return compose(
      sortBy(({ userId, user }: UserToShow) => [
        includes(managersWithoutScreeners, userId) ? 0 : includes(projectManagers, userId) ? 1 : 2,
        user?.name ?? null,
      ]),
      map<string, UserToShow>((userId: string) => ({
        role: includes(stageTeamMembersUserIds, userId)
          ? includes(projectManagers, userId)
            ? 'manager_screener'
            : 'screener'
          : 'manager',
        status: stageTeamMembers[userId],
        userId,
        user: usersById[userId] ?? null,
      }))
    )(union(managersWithoutScreeners, stageTeamMembersUserIds));
  }, [projectManagers, stageTeamMembers, usersById]);

  return (
    <div className="p-8" css={currentStageColumnCss} data-testid="stage-progress">
      {loading || usersLoading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <div className="flex flex-row items-center justify-between mb-2">
            <span className="flex-1 text-lg">
              <Trans>Team progress</Trans>
            </span>
            <span css={gray1ColorCss}>{round(progress * 100)}%</span>
          </div>
          <ProgressBar css={progressBarCss} stripes={false} value={progress} />
          <div className="mt-8">
            <div className="flex flex-row justify-between items-center text-sm" css={gray1ColorCss}>
              <div>
                <Trans>Name</Trans>
              </div>
              <div className="text-right">
                <Trans>Role</Trans>
              </div>
            </div>
            {usersToShow.map(({ role, status, user, userId }) => (
              <div
                className="mt-4 items-center"
                css={userDivCss}
                key={userId}
                data-testid="stage-team-member-details"
              >
                <div>
                  <UserAvatar user={user} />
                </div>
                <div>{user?.name ?? <Trans>Deleted user</Trans>}</div>
                <div>{getUserStatusElement(status)}</div>
                <div className="text-right">{getUserRoleLabel(role)}</div>
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default CurrentStageColumn;
