/** @jsx jsx */
import { Button, Intent, NonIdealState } from '@blueprintjs/core';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import React, { Fragment, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '../../keycloak';

interface IPage404Props {
  className?: string;
}

const Page404: React.FC<IPage404Props> = ({ className }) => {
  const { isTechAdmin } = useKeycloak();
  const history = useHistory();
  const navigateToProjectsList = useCallback(() => {
    history.push('/projects');
  }, [history]);

  return (
    <NonIdealState
      className={className}
      icon={<img src="/media/logo-dark.svg" alt="Laser AI" height={64} />}
      title={
        <span className="w-full text-3xl">
          <Trans>We couldn't find the page you are looking for.</Trans>
        </span>
      }
      description={
        <div className="flex flex-col">
          <div className="uppercase text-gray-700">
            <Trans>Error code</Trans>: 404
          </div>
          {isTechAdmin ? (
            <Fragment>
              <div className="mt-4">
                <Trans>
                  The link <span className="underline">{window.location.href}</span> seems to be
                  broken or incorrect.
                </Trans>
              </div>
              <div className="mt-4 text-red-700">
                <Trans>
                  If you are looking for a project that may have been archived or deleted, please
                  search in the <span className="underline">"Archived"</span> or{' '}
                  <span className="underline">"Deleted"</span> tabs on the projects list.
                </Trans>
              </div>
            </Fragment>
          ) : (
            <div className="mt-4">
              <Trans>The link you followed seems to be broken or incorrect.</Trans>
            </div>
          )}
        </div>
      }
      action={
        <Button intent={Intent.PRIMARY} onClick={navigateToProjectsList}>
          <Trans>Show projects list</Trans>
        </Button>
      }
    />
  );
};

export default Page404;
