import { t } from '@lingui/macro';
import { alignCenterCss, alignEndCss, alignStartCss } from './styles';
import i18n from '../i18n';
import { TableCol } from '../components/common/gba_table';

const alternateTranslationsEnabled = window.REACT_APP_ALTERNATE_TRANSLATIONS_ENABLED === 'true';

function getColFactory<T>(
  colSpec: TableCol<T>
): (overrides?: Omit<Partial<TableCol<T>>, 'id'>) => TableCol<T> {
  return (overrides?: Omit<Partial<TableCol<T>>, 'id'>) => ({
    ...colSpec,
    ...(overrides ?? {}),
  });
}

export const yearCol = getColFactory({
  id: 'year',
  label: i18n._(t`Year`),
  headerCellCss: alignCenterCss,
  cellCss: alignStartCss,
  sortable: true,
  width: 64,
});

export const titleCol = getColFactory({
  id: 'title',
  label: i18n._(t`Title`),
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  cellClassName: 'reference-title',
  sortable: true,
});

export const docTypeCol = getColFactory({
  id: 'documentType',
  label: i18n._(t`Document type`),
  headerCellCss: alignStartCss,
  cellCss: alignEndCss,
  width: 150,
  sortable: true,
});

export const lastChangedCol = getColFactory({
  id: 'lastChanged',
  label: alternateTranslationsEnabled ? i18n._(t('alt:Last changed')`Last changed`) : i18n._(t`Last changed`),
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  width: 128,
  sortable: true,
});

export const firstAuthorLastNameCol = getColFactory({
  id: 'firstAuthorsLastName',
  label: alternateTranslationsEnabled ? i18n._(t('alt:First author')`First author`) : i18n._(t`1st Author`),
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  sortable: true,
  width: 128,
});

export const statusCol = getColFactory({
  id: 'status',
  label: i18n._(t`Status`),
  width: 130,
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  sortable: true,
});

export const menuCol = getColFactory({
  id: 'menu',
  width: 50,
});

export const screeningDecisionCol = getColFactory({
  id: 'decision',
  label: i18n._(t`Decision`),
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  width: 100,
});

export const referenceNumberCol = getColFactory({
  id: 'referenceNumber',
  label: i18n._(t`Ref. num.`),
  width: 96,
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  sortable: true,
});

export const accessionNumberCol = getColFactory({
  id: 'accessionNumber',
  label: i18n._(t`Accession number`),
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  width: 128,
  sortable: true,
});

export const batchCol = getColFactory({
  id: 'batch',
  label: i18n._(t`Batch`),
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  width: 128,
  sortable: false,
});

export const commentCol = getColFactory({
  id: 'comment',
  label: i18n._(t`Comment`),
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  sortable: true,
  width: 128,
});

export const tagsCol = getColFactory({
  id: 'tags',
  label: i18n._(t`Structured comments`),
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  sortable: false,
  width: 128,
});

export const languageCol = getColFactory({
  id: 'language',
  label: i18n._(t`Language`),
  headerCellCss: alignStartCss,
  cellCss: alignStartCss,
  sortable: true,
  width: 96,
});
