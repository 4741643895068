/** @jsx jsx */
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import i18n from '../../i18n';
import { AppContentProps } from '../../common/types';
import { useI18n } from '../../lib/utils';
import { useKeycloak } from '../../keycloak';
import Sidebar, { TAppModule } from '../common/sidebar';

const APP_MODULES: TAppModule[] = [
  {
    path: '/projects',
    caption: i18n._(t`Projects`),
    icon: IconNames.PROJECTS,
    moduleType: 'admin',
  },
  {
    path: '/users',
    caption: i18n._(t`Users`),
    icon: IconNames.PEOPLE,
    moduleType: 'admin',
    restricted: true,
  },
];

interface IProjectSidebarProps extends AppContentProps {}

const ProjectsListSidebar: React.FC<IProjectSidebarProps> = ({ location }) => {
  const i18n = useI18n();
  const { isTechAdmin } = useKeycloak();
  const modulesList = useMemo(
    () =>
      [...APP_MODULES].filter(({ restricted }) => (restricted ?? false) === false || isTechAdmin),
    [i18n, isTechAdmin]
  );

  return <Sidebar location={location} modules={modulesList} />;
};

export default ProjectsListSidebar;
