/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import {
  Button,
  Colors,
  Divider,
  Icon,
  Intent,
  NonIdealState,
  Position,
  Spinner,
  Tab,
  Tabs,
  Tag,
  Tooltip,
} from '@blueprintjs/core';
import React, { Fragment, memo, ReactNode, useCallback, useEffect, useMemo } from 'react';
import {
  compose,
  constant,
  defaults,
  find,
  first,
  flatMap,
  get,
  groupBy,
  isEmpty,
  isNil,
  keyBy,
  keys,
  map,
  omit,
  orderBy,
  pick,
  propEq,
  reject,
  values,
} from 'lodash/fp';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import { t, Trans } from '@lingui/macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  AdminReferenceDetailsMatchParams,
  Flatten,
  Reference,
  ReferenceAttachment,
  ReferenceLog,
  ReferenceRemovalReason,
  ScreeningTag,
  Stage,
  StageResult,
  StageType,
  Study,
  StudyPoolStudy,
  TReferenceLogType,
  User,
} from '../../../common/types';
import { useCurrCallback, useI18n, useKeywordTagStyles, useSetState } from '../../../lib/utils';
import ErrorScreen from '../../common/error_screen';
import PDFViewer from '../../pdf_viewer/pdf_viewer';
import {
  PageToggles as PDFPages,
  SearchToggle as PDFSearchToggle,
  ZoomToggle as PDFZoomToggle,
} from '../../pdf_viewer/controls';
import DocPageToggles from '../../screening/full_text/doc_page_toggles';
import DocZoomToggles from '../../screening/full_text/doc_zoom_toggles';
import { formatDate } from '../../project/helpers';
import { IconName, IconNames } from '@blueprintjs/icons';
import { fancyScrollCss, lightGray5bg } from '../../../common/styles';
import {
  getAuthorNames,
  getReferenceStudyIdAndTitle,
  locationSearchToReferenceFilters,
  removeCachedReferenceComment,
  updateCachedReferenceCommentText,
  updateReferenceCommentsCache,
} from '../helpers';
import ReferenceDetailsLogCard from './reference_details_log_card';
import { MutationFunctionOptions } from '@apollo/react-common';
import ModifyReferenceMenu from './modify_reference_menu';
import useReferenceDetailsLogger, {
  createSimpleReferenceDetailsLogs,
} from '../../hooks/use_reference_details_logger';
import { useKeycloak } from '../../../keycloak';
import AppToaster from '../../../lib/toaster';
import { captureException } from '@sentry/browser';
import { getAllCountFilter, getResolvedCountFilter, getSearchArgs, ORDER_BY_BATCH } from './index';
import type { TDecisionOverwriteHandler } from './decision_overwrite_dialog';
import type { TEditedTagsHandler } from './reference_tags_edit_dialog';
import PDFAttachmentsDialog from './pdf_attachments_dialog';
import {
  EMPTY_ACTIVE_DECISION_CODE_FILTERS,
  EMPTY_ACTIVE_DOCUMENT_TYPE_FILTERS,
  EMPTY_ACTIVE_KEYWORD_FILTERS,
  EMPTY_ACTIVE_SCREENING_TAG_FILTERS,
  EMPTY_ACTIVE_YEAR_FILTER,
  EMPTY_SEARCH_PHRASE_TOKENS,
} from '../../../apollo/screening_state';
import { getSearchTokens } from '../../../lib/task_helpers';
import ReferenceCommentsPanel from './reference_comments_panel';
import type { ReferenceCommentFragmentType } from '../../../graphql/reference_comment_fragment';
import {
  DeleteReferenceCommentMutationDataType,
  DeleteReferenceCommentMutationVariablesType,
} from '../../../graphql/delete_reference_comment';
import { renderDecisionTags } from './references_list_layouts';
import DownloadButton from '../../common/download_button';
import { Buckets } from '../../../lib/attachmentUpload';

const ProjectScreeningTagsQuery = loader('../../../graphql/get_project_screening_tags_data.gql');
const RemoveReferencesMutation = loader('../../../graphql/remove_references.gql');
const RestoreReferencesMutation = loader('../../../graphql/restore_references.gql');
const UpdateReferencesNoReportFieldAndClaimsMutation = loader(
  '../../../graphql/update_references_no_report_field_and_claims.gql'
);
const DeleteReferenceCommentMutation = loader('../../../graphql/delete_reference_comment.gql');
const ReferenceCommentFragment = loader('../../../graphql/reference_comment_fragment.gql');
const SetActiveAndSelectedReferencesMutation = loader(
  '../../../graphql/local/set_active_and_selected_references.gql'
);
const UpdateStudyPoolStudyMutation = gql`
  mutation UpdateStudyPoolStudy(
    $studyId: String!
    $studyPoolId: uuid!
    $data: study_pool_study_set_input!
  ) {
    update_study_pool_study_by_pk(
      pk_columns: { study_id: $studyId, study_pool_id: $studyPoolId }
      _set: $data
    ) {
      study_id
      study_pool_id
      inclusion_status
      status_reason_codes
      tags
      comment
    }
  }
`;

export const ReferenceDataQuery = gql`
  query GetReferenceData($referenceId: uuid!) {
    reference_by_pk(id: $referenceId) {
      id
      attrs
      title
      no_report
      deleted_at
      project {
        id
        stages(order_by: { order_number: asc }) {
          id
          type
          name
          order_number
          forms(order_by: { created_at: desc }, limit: 1) {
            id
            form
          }
        }
        team_members {
          id
          user_id
          user {
            id
            name
          }
        }
      }
      logs(order_by: { created_at: desc_nulls_last }) {
        reference_id
        data
        created_at
      }
      reference_attachments {
        key
        filename
      }
      screener_tags {
        tags
      }
      import_task {
        key
        created_at
      }
      study {
        id
        current_stage_id
      }
      stage_results {
        stage_id
        study_id
        study_pool_id
        inclusion_status
        status_reason_codes
        tags
        comment
      }
      reference_comments(order_by: { updated_at: desc }) {
        ...ReferenceCommentFragment
      }
    }
  }
  ${ReferenceCommentFragment}
`;

const UsersQuery = gql`
  query GetUsersQuery {
    users: user {
      id
      name
    }
  }
`;

type TStageResult = Pick<
  StageResult,
  | 'stage_id'
  | 'study_id'
  | 'study_pool_id'
  | 'inclusion_status'
  | 'status_reason_codes'
  | 'tags'
  | 'comment'
>;

const ReferencesIdsQuery = gql`
  query ReferencesIdsQuery(
    $searchArgs: search_references2_args! = {}
    $referencesFilter: reference_bool_exp!
    $orderBy: [reference_order_by!]
    $offset: Int!
    $limit: Int!
  ) {
    references: search_references2(
      args: $searchArgs
      order_by: $orderBy
      where: $referencesFilter
      offset: $offset
      limit: $limit
    ) @connection(key: "references_search", filter: ["args", "where", "order_by"]) {
      id
    }
  }
`;

export type TReferenceDetailsData = Pick<
  Reference,
  'id' | 'attrs' | 'no_report' | 'deleted_at' | 'title'
> & {
  project: {
    id: string;
    stages: Pick<Stage, 'id' | 'type' | 'order_number' | 'name' | 'forms'>[];
    team_members: {
      id: string;
      user_id: string;
      user: Pick<User, 'id' | 'name'>;
    }[];
  };
  logs: Pick<ReferenceLog, 'reference_id' | 'created_at' | 'data'>;
  reference_attachments: ReferenceAttachment[];
  screener_tags: { tags: string[] } | null;
  import_task: {
    key: string;
    created_at: string;
  };
  stage_results: TStageResult[];
  reference_comments: ReferenceCommentFragmentType[];
  study: Pick<Study, 'id' | 'current_stage_id'>;
};

const UpsertReferenceCommentMutation = gql`
  mutation UpsertReferenceComment($comment: reference_comment_insert_input!) {
    insert_reference_comment_one(
      object: $comment
      on_conflict: { constraint: reference_comment_pkey, update_columns: comment }
    ) {
      ...ReferenceCommentFragment
    }
  }
  ${ReferenceCommentFragment}
`;

const RemoveReferenceAttachmentMutation = gql`
  mutation RemoveReferenceAttachment(
    $attachmentKey: String!
    $referenceId: uuid!
    $logData: jsonb!
  ) {
    delete_reference_attachment_by_pk(key: $attachmentKey) {
      key
    }
    insert_reference_details_log_one(object: { reference_id: $referenceId, data: $logData }) {
      reference_id
      data
    }
  }
`;

const UpdateReferenceMutation = gql`
  mutation UpdateReference($id: uuid!, $reference: reference_set_input!) {
    update_reference_by_pk(pk_columns: { id: $id }, _set: $reference) {
      id
      title
      attrs
    }
  }
`;

const titleCss = css`
  color: ${Colors.DARK_GRAY1};
`;

const pdfContainerCss = css`
  ${fancyScrollCss};
  background-color: ${Colors.GRAY2};
`;

const pdfManagementCss = css`
  .pdf-nav {
    border: 0;
  }
`;

type ReferenceDetailsTab = {
  id: string;
  title: string | ReactNode;
  icon: IconName;
};

type ReferenceDetailsTabs = {
  [key: string]: ReferenceDetailsTab;
};

const REFERENCE_DETAILS_TABS: ReferenceDetailsTabs = {
  details: {
    id: 'details',
    title: <Trans>Details</Trans>,
    icon: IconNames.INFO_SIGN,
  },
  history: {
    id: 'history',
    title: <Trans>History</Trans>,
    icon: IconNames.HISTORY,
  },
  comments: {
    id: 'comments',
    title: <Trans>Comments</Trans>,
    icon: IconNames.COMMENT,
  },
};

const RESTRICTED_TABS = ['history'];

interface IAdminReferenceDetailsViewProps
  extends RouteComponentProps<AdminReferenceDetailsMatchParams> {
  // regular props
}

type TReferenceDetailsState = {
  selectedTab: ReferenceDetailsTab;
  fileUploadDialogOpen: boolean;
  commentEditOpen: boolean;
  commentValue: string;
  attachmentKey?: string;
  attachmentFilename?: string;
};

const INITIAL_STATE: TReferenceDetailsState = {
  selectedTab: REFERENCE_DETAILS_TABS.details,
  fileUploadDialogOpen: false,
  commentEditOpen: false,
  commentValue: '',
};

const EMPTY_LIST = [];

const AdminReferenceDetailsView: React.FC<IAdminReferenceDetailsViewProps> = memo(({ match }) => {
  const [state, setState] = useSetState<TReferenceDetailsState>(INITIAL_STATE);
  const {
    selectedTab,
    fileUploadDialogOpen,
    commentEditOpen,
    commentValue,
    attachmentKey,
    attachmentFilename,
  } = state;
  const { projectId, referenceId } = match.params;
  const [, , neutralKeywordCss] = useKeywordTagStyles();
  const i18n = useI18n();
  const addReferencesDetailsLog = useReferenceDetailsLogger();
  const { user, isTechAdmin } = useKeycloak();
  const location = useLocation();
  const {
    data: referenceDetailsData,
    loading: loadingReferencesDetails,
    error: referenceDetailsError,
  } = useQuery<{
    reference_by_pk: TReferenceDetailsData;
  }>(ReferenceDataQuery, {
    variables: { referenceId },
    fetchPolicy: 'network-only',
    pollInterval: 1500,
  });
  const {
    data: usersData,
    loading: loadingUsers,
    error: usersError,
  } = useQuery<{
    users: Pick<User, 'id' | 'name'>[];
  }>(UsersQuery, {
    fetchPolicy: 'network-only',
    pollInterval: 300000, // 5 minutes
  });
  const {
    data: screeningTagsData,
    loading: loadingScreeningTags,
    error: screeningTagsError,
  } = useQuery(ProjectScreeningTagsQuery, {
    variables: { projectId },
    fetchPolicy: 'network-only',
  });

  const referencesIdsQueryVariables = useMemo(() => {
    const appliedFilters = locationSearchToReferenceFilters(location.search);
    if (!appliedFilters) {
      return;
    }
    const {
      activeDocumentTypeFilters,
      activeDecisionCodeFilters,
      activeScreeningTagFilters,
      activeYearFilters,
      searchPhraseTokens,
      filtersTarget,
      activeKeywordFilters,
      onlyWithComments,
      pdfFilter,
      onlyWithoutAbstract,
      activeBatchKey,
      decisionFilter,
      orderedBy,
    } = appliedFilters;
    const searchParams = new URLSearchParams(location.search);
    const referenceIdx = Number(searchParams.get('refIdx')); // this route always has 'refIdx' passed
    const hasPreviousReference = Number(referenceIdx) > 0;
    const stageId = searchParams.get('stageId') || undefined;
    const referenceStatus = searchParams.get('referenceStatus') || undefined;
    const duplicatesOnly = Boolean(searchParams.get('duplicatesOnly'));
    return {
      searchArgs: getSearchArgs({
        projectId,
        activeDocumentTypeFilters: activeDocumentTypeFilters ?? EMPTY_ACTIVE_DOCUMENT_TYPE_FILTERS,
        activeDecisionCodeFilters: activeDecisionCodeFilters ?? EMPTY_ACTIVE_DECISION_CODE_FILTERS,
        activeScreeningTagFilters: activeScreeningTagFilters ?? EMPTY_ACTIVE_SCREENING_TAG_FILTERS,
        activeYearFilters: activeYearFilters ?? EMPTY_ACTIVE_YEAR_FILTER,
        searchPhraseTokens: searchPhraseTokens ?? EMPTY_SEARCH_PHRASE_TOKENS,
        filtersTarget: filtersTarget || 'all_fields',
        searchTokensWithOperator: getSearchTokens(
          activeKeywordFilters ?? EMPTY_ACTIVE_KEYWORD_FILTERS
        ),
        onlyWithComments: onlyWithComments || false,
        pdfFilter: pdfFilter || 'all',
        onlyWithoutAbstract: onlyWithoutAbstract || false,
        orderedBy: orderedBy ?? ORDER_BY_BATCH,
      }),
      referencesFilter:
        (decisionFilter ?? 'to_review') === 'resolved'
          ? getResolvedCountFilter(stageId, referenceStatus, duplicatesOnly)
          : getAllCountFilter(stageId, referenceStatus, duplicatesOnly, activeBatchKey || null),
      offset: hasPreviousReference ? referenceIdx - 1 : 1,
      limit: hasPreviousReference ? 3 : 1,
    };
  }, [location.search, projectId]);

  const {
    data: referencesIdsData,
    loading: loadingReferencesIds,
    error: referencesIdsError,
  } = useQuery<{
    references: Pick<Reference, 'id'>[];
  }>(ReferencesIdsQuery, {
    fetchPolicy: 'no-cache',
    variables: referencesIdsQueryVariables,
  });

  const reference = referenceDetailsData?.reference_by_pk;
  const users = usersData?.users ?? [];
  const usersById = useMemo(() => keyBy('id', users), [users]);

  const [previousReferenceId, nextReferenceId]: (string | null)[] = useMemo(() => {
    switch (referencesIdsData?.references.length) {
      case 1: // only next reference is fetched
        return [null, referencesIdsData.references[0].id];
      case 2: // only previous and current references are fetched
        return [referencesIdsData.references[0].id, null];
      case 3: // previous, current and next references are fetched
        return [referencesIdsData.references[0].id, referencesIdsData.references[2].id];
      default:
        return [null, null];
    }
  }, [referencesIdsData]);

  const uploadMutationOptions: MutationFunctionOptions<any, Record<string, any>> = {
    update: (proxy, { data }) => {
      const mutationResult = get('insert_reference_attachment.returning', data);
      if (isNil(mutationResult) || isNil(referenceId)) return;
      const queryResult: { reference_by_pk: TReferenceDetailsData } | null = proxy.readQuery({
        query: ReferenceDataQuery,
        variables: { referenceId },
      });
      const referenceData = get('reference_by_pk', queryResult);
      const updatedQueryResult = {
        reference_by_pk: {
          ...referenceData,
          reference_attachments: [
            ...(referenceData?.reference_attachments ?? []),
            ...mutationResult,
          ],
        },
      };
      proxy.writeQuery({
        query: ReferenceDataQuery,
        variables: { referenceId },
        data: updatedQueryResult,
      });
    },
  };

  const projectStages: Pick<Stage, 'id' | 'type'>[] = reference?.project.stages ?? [];
  const [removeReferenceAttachment] = useMutation(RemoveReferenceAttachmentMutation);
  const [updateReference] = useMutation(UpdateReferenceMutation);
  const [removeReferences] = useMutation<
    any,
    { referenceIds: string[]; userId: string; removalReason: string }
  >(RemoveReferencesMutation);
  const [restoreReferences] = useMutation<any, { referenceIds: string[] }>(
    RestoreReferencesMutation
  );
  const [updateReferencesNoReportFieldAndClaims] = useMutation(
    UpdateReferencesNoReportFieldAndClaimsMutation
  );
  const [updateStudyPoolStudy] = useMutation<
    any,
    {
      studyId: string;
      studyPoolId: string;
      data: Partial<
        Pick<StudyPoolStudy, 'inclusion_status' | 'status_reason_codes' | 'comment' | 'tags'>
      >;
    }
  >(UpdateStudyPoolStudyMutation);
  const [upsertReferenceComment] = useMutation<
    {
      insert_reference_comment_one: ReferenceCommentFragmentType;
    },
    {
      comment: {
        id?: string;
        comment: string;
        reference_id: string;
        stage_id: string;
        team_member_id: string;
      };
    }
  >(UpsertReferenceCommentMutation);
  const [deleteReferenceComment] = useMutation<
    DeleteReferenceCommentMutationDataType,
    DeleteReferenceCommentMutationVariablesType
  >(DeleteReferenceCommentMutation);
  const history = useHistory();

  const screeningTags: { [tagId: string]: ScreeningTag } = useMemo(() => {
    return compose(
      keyBy('id'),
      flatMap(({ tags }) => tags ?? []),
      get('forms')
    )(screeningTagsData);
  }, [screeningTagsData]);

  const latestStageResult: TStageResult | undefined = useMemo(() => {
    return orderBy(
      ({ stage_id }) => find({ id: stage_id }, reference?.project.stages)?.order_number,
      'desc',
      reference?.stage_results
    )[0];
  }, [reference?.stage_results, reference?.project]);

  const setCommentValue = useCallback(
    (evt) => {
      setState({ commentValue: evt.target.value });
    },
    [setState]
  );

  const setSelectedTab = useCallback(
    (newTabId: string) => {
      setState({ selectedTab: REFERENCE_DETAILS_TABS[newTabId] });
    },
    [setState]
  );

  const onOpenPDFUploadDialog = useCallback(() => {
    setState({ fileUploadDialogOpen: true });
  }, [setState]);

  const onClosePDFUploadDialog = useCallback(() => {
    setState({ fileUploadDialogOpen: false });
  }, [setState]);

  const onDeletePDF = useCallback(
    async (deletedAttachmentKey: string) => {
      try {
        await removeReferenceAttachment({
          variables: {
            referenceId,
            attachmentKey: deletedAttachmentKey,
            logData: {
              type: 'attachment_removed',
            },
          },
          optimisticResponse: {
            delete_reference_attachment_by_pk: {
              key: deletedAttachmentKey,
              __typename: 'reference_attachment',
            },
            insert_reference_details_log_one: {
              reference_id: referenceId,
              data: {
                type: 'attachment_removed',
              },
              __typename: 'reference_details_log',
            },
          },
          update: (proxy, { data }) => {
            const mutationResult = get('delete_reference_attachment_by_pk', data);
            if (isNil(mutationResult)) return;
            const updatedQueryResult = {
              reference_by_pk: {
                ...reference,
                reference_attachments: compose(
                  reject(propEq('key', deletedAttachmentKey)),
                  get('reference_attachments')
                )(reference),
              },
            };
            proxy.writeQuery({
              query: ReferenceDataQuery,
              variables: { referenceId },
              data: updatedQueryResult,
            });
          },
        });
      } catch (err) {
        captureException(err);
        AppToaster.show({
          icon: IconNames.ERROR,
          intent: Intent.DANGER,
          message: i18n._(t`Failed to delete the file`),
        });
      }
    },
    [removeReferenceAttachment, referenceId, reference, i18n]
  );

  const renderScreeningTags = useCallback(
    (tags?: string[]) => {
      return isEmpty(tags)
        ? '-'
        : map(
            (tagId: string) => (
              <Tag className="mb-1 mr-1" key={tagId} css={neutralKeywordCss}>
                {screeningTags[tagId]?.tag}
              </Tag>
            ),
            tags
          );
    },
    [neutralKeywordCss, screeningTags]
  );

  const setCommentEdit = useCallback(() => {
    setState({ commentEditOpen: true });
  }, [setState]);

  const dismissCommentChange = useCallback(() => {
    setState({
      commentEditOpen: false,
      commentValue: latestStageResult?.comment ?? '',
    });
  }, [setState, latestStageResult]);

  const handleSaveBibliographicData = useCallback(
    ({ title, attrs, id }: Pick<Reference, 'id' | 'title' | 'attrs'>) => {
      const updatedAttrs = defaults(reference?.attrs, attrs);
      updateReference({
        variables: { id, reference: { title, attrs: updatedAttrs } },
        optimisticResponse: {
          update_reference_by_pk: {
            __typename: 'reference',
            id,
            title,
            attrs: updatedAttrs,
          },
        },
        update: (proxy, { data }) => {
          const updatedData = get('update_reference_by_pk', data);
          if (updatedData == null) return;

          const referenceFragment = gql`
            fragment UpdatedReferenceFragment on reference {
              reference_id
              title
              attrs
            }
          `;

          proxy.writeFragment({
            fragment: referenceFragment,
            id: `reference:${id}`,
            data: {
              reference_id: id,
              title: updatedData.title,
              attrs: updatedData.attrs,
              __typename: 'reference',
            },
          });
        },
      });
    },
    [updateReference, reference]
  );

  const handleRemoveAsDuplicate = useCallback(
    (referenceId: string) => {
      const promise = removeReferences({
        variables: {
          referenceIds: [referenceId],
          removalReason: ReferenceRemovalReason.IsDuplicate,
          userId: user.id,
        },
      })
        .then(() => {
          history.push(`/projects/${projectId}/references`);
          AppToaster.show({
            intent: Intent.WARNING,
            message: i18n._(t`Reference has been removed as duplicate`),
            icon: IconNames.INFO_SIGN,
            action: {
              text: <Trans>Undo</Trans>,
              onClick: () => {
                const restorePromise = restoreReferences({
                  variables: { referenceIds: [referenceId] },
                });
                const restoredPromiseLog = createSimpleReferenceDetailsLogs('restored', user, [
                  referenceId,
                ]);
                addReferencesDetailsLog(restorePromise, constant(restoredPromiseLog));
              },
            },
          });
        })
        .catch(() => {
          AppToaster.show({
            message: <Trans>Failed to remove reference as duplicates</Trans>,
            intent: Intent.WARNING,
          });
        });

      const referenceDetailsLogs = createSimpleReferenceDetailsLogs('removed_as_duplicate', user, [
        referenceId,
      ]);

      addReferencesDetailsLog(promise, constant(referenceDetailsLogs));
    },
    [user, addReferencesDetailsLog, history, projectId]
  );

  const handleSetNoReport = useCallback(
    (referenceId: string, noReport: boolean) => {
      const toasterMessage = noReport
        ? i18n._(t`Reference has been marked as having no report`)
        : i18n._(t`Reference has been unmarked as having no report`);

      const promise = updateReferencesNoReportFieldAndClaims({
        variables: {
          referenceIds: [referenceId],
          noReport,
          claimedReferencesToDeleteIds: noReport ? [] : [referenceId],
        },
        optimisticResponse: {
          update_reference: {
            __typename: 'reference_mutation_response',
            returning: [
              {
                id: referenceId,
                no_report: noReport,
                __typename: 'reference',
              },
            ],
          },
          delete_claimed_references: {
            __typename: 'delete_claimed_references_mutation_response',
            affected_rows: noReport ? 0 : 1,
          },
        },
        update: (proxy, { data }) => {
          // FIXME: this updates only reference details cache. This doesn't cover reference details
          // logs cache, so when user goes to History tab he will not see the record belonging to
          // this action until he refreshes the view
          const updatedReferences: Pick<Reference, 'id' | 'no_report'>[] = get(
            'update_reference.returning',
            data
          );
          const referenceFragment = gql`
            fragment ReferenceNoReportFragment on reference {
              id
              no_report
            }
          `;
          proxy.writeFragment({
            id: `reference:${referenceId}`,
            fragment: referenceFragment,
            data: updatedReferences[0],
          });
        },
      }).then(() => {
        AppToaster.show({
          intent: Intent.WARNING,
          message: toasterMessage,
          icon: IconNames.INFO_SIGN,
        });
      });

      const referenceDetailsLogs = createSimpleReferenceDetailsLogs(
        noReport ? 'marked_with_no_report' : 'no_report_mark_removed',
        user,
        [referenceId]
      );

      addReferencesDetailsLog(promise, constant(referenceDetailsLogs));
    },
    [updateReferencesNoReportFieldAndClaims, user]
  );

  const handleRestore = useCallback(
    (referenceId: string) => {
      return restoreReferences({ variables: { referenceIds: [referenceId] } }).then(() => {
        history.push({
          pathname: `/projects/${projectId}/references`,
          search: 'duplicatesOnly=1',
        });
      });
    },
    [restoreReferences]
  );

  const handleUpdateStudyPoolStudyWithNewResult = useCallback(
    (newStudyResult: Flatten<TReferenceDetailsData['stage_results']>) => {
      const updatedData = pick(
        ['inclusion_status', 'status_reason_codes', 'tags', 'comment'],
        newStudyResult
      );

      return updateStudyPoolStudy({
        variables: {
          studyId: newStudyResult.study_id,
          studyPoolId: newStudyResult.study_pool_id,
          data: updatedData,
        },
        optimisticResponse: {
          update_study_pool_study_by_pk: {
            study_id: newStudyResult.study_id,
            study_pool_id: newStudyResult.study_pool_id,
            ...updatedData,
            __typename: 'study_pool_study',
          },
        },
        update: (proxy, { data }) => {
          if (data == null) return;

          const stageResultsFragment = gql`
            fragment UpdatedStageResults on stage_results {
              inclusion_status
              status_reason_codes
              tags
              comment
            }
          `;

          proxy.writeFragment({
            fragment: stageResultsFragment,
            id: `stage_results:${newStudyResult['study_pool_id']}_${newStudyResult['study_id']}`,
            data: {
              ...updatedData,
              __typename: 'stage_results',
            },
          });
        },
      });
    },
    [updateStudyPoolStudy]
  );

  const updateComment = useCallback(() => {
    if (latestStageResult != null && reference != null) {
      const promise = handleUpdateStudyPoolStudyWithNewResult({
        ...latestStageResult,
        comment: commentValue,
      });

      const referenceStatusOverwriteLogs = createSimpleReferenceDetailsLogs(
        'comments_updated',
        user,
        [reference.id],
        { stageId: latestStageResult.stage_id, comment: commentValue }
      );

      addReferencesDetailsLog(promise, constant(referenceStatusOverwriteLogs));
    }

    setState({ commentEditOpen: false });
  }, [
    latestStageResult,
    reference,
    handleUpdateStudyPoolStudyWithNewResult,
    addReferencesDetailsLog,
    user,
    commentValue,
  ]);

  const handleTagsUpdate: TEditedTagsHandler = useCallback(
    (newTags, currentResult) => {
      if (reference == null) return;

      const promise = handleUpdateStudyPoolStudyWithNewResult({
        ...currentResult,
        tags: newTags,
      });

      const referenceStatusOverwriteLogs = createSimpleReferenceDetailsLogs(
        'tags_changed',
        user,
        [reference.id],
        { stageId: currentResult.stage_id, tags: newTags }
      );

      addReferencesDetailsLog(promise, constant(referenceStatusOverwriteLogs));
    },
    [handleUpdateStudyPoolStudyWithNewResult, user, reference, addReferencesDetailsLog]
  );

  const handleScreeningResultOverwrite: TDecisionOverwriteHandler = useCallback(
    (decision, currentResult) => {
      if (reference == null) return;
      const { inclusionStatus, reasonCodes } = decision;

      const promise = handleUpdateStudyPoolStudyWithNewResult({
        ...currentResult,
        inclusion_status: inclusionStatus,
        status_reason_codes: reasonCodes,
      });

      const referenceStatusOverwriteLogs = createSimpleReferenceDetailsLogs(
        'screening_status_overwrite',
        user,
        [reference.id]
      );

      // the order of logs is not guaranteed to be the same as their order in referenceDetailsLogs
      // collection. Here, it is important that status_change log appears after the
      // screening_status_overwrite one. That is why we have to compose addReferencesDetailsLog
      // calls
      addReferencesDetailsLog(
        addReferencesDetailsLog(promise, constant(referenceStatusOverwriteLogs)),
        constant([
          {
            reference_id: reference!.id,
            data: {
              type: 'status_changed' as TReferenceLogType,
              studyId: reference.study.id,
              studyPoolId: currentResult.study_pool_id,
              decision: inclusionStatus,
              decisionCodes: reasonCodes,
              stageId: currentResult.stage_id,
              userId: null,
            },
          },
        ])
      );
    },
    [handleUpdateStudyPoolStudyWithNewResult, reference, user, addReferencesDetailsLog]
  );

  const handleReferenceCommentUpdate = useCallback(
    (commentData: { id?: string; comment: string; teamMemberId: string; stageId: string }) => {
      const { id: commentId, comment, teamMemberId, stageId } = commentData;
      if (reference == null) return;

      const logsData = createSimpleReferenceDetailsLogs('comments_updated', user, [reference.id], {
        comment: commentData,
      });

      const promise = upsertReferenceComment({
        variables: {
          comment: {
            id: commentId,
            comment,
            reference_id: reference.id,
            team_member_id: teamMemberId,
            stage_id: stageId,
          },
        },
        // provide optimistic response in case of update only (comment id was provided)
        optimisticResponse: commentId
          ? {
              insert_reference_comment_one: {
                comment,
                id: commentId,
                stage_id: stageId,
                reference_id: reference.id,
                task_id: null,
                form_id: null,
                updated_at: new Date().toISOString(),
                team_member: {
                  id: teamMemberId,
                  user: {
                    id: user.id,
                    name: user.name,
                  },
                },
              },
            }
          : undefined,
        update(proxy, { data }) {
          if (data == null) return;
          const { insert_reference_comment_one: insertedComment } = data;

          if (commentId) {
            // existing comment updated
            updateCachedReferenceCommentText(proxy, commentId, comment);
          } else {
            // new comment inserted
            updateReferenceCommentsCache(
              proxy,
              insertedComment.reference_id,
              (existingComments) => [insertedComment, ...existingComments]
            );
          }
        },
      });

      addReferencesDetailsLog(promise, constant(logsData));
    },
    [reference, user, addReferencesDetailsLog]
  );

  const handleReferenceCommentDelete = useCallback(
    (commentId: string) => {
      if (reference == null) return;

      const logsData = createSimpleReferenceDetailsLogs('comments_removed', user, [reference.id], {
        removedCommentId: commentId,
      });

      const promise = deleteReferenceComment({
        variables: {
          id: commentId,
        },
        optimisticResponse: {
          delete_reference_comment_by_pk: {
            form_id: null,
            task_id: null,
            id: commentId,
            reference_id: reference.id,
            __typename: 'reference_comment',
          },
        },
        update: removeCachedReferenceComment,
      });

      addReferencesDetailsLog(promise, constant(logsData));
    },
    [reference, addReferencesDetailsLog, user]
  );

  const renderSelectedTabPanel = useCallback(() => {
    switch (selectedTab.id) {
      case 'details': {
        if (isNil(reference)) return null;

        const doi: string | undefined = get('attrs.doi', reference);
        const authorNames: string | undefined = getAuthorNames(reference);
        const title: string | undefined = get('title', reference);
        const year: number | undefined = get('attrs.year', reference);
        const journal: string | undefined = get('attrs.venue', reference);
        const link: string | undefined = get('attrs.fullText', reference);
        const accessionNumber: string | undefined = get('attrs.accessionNumber', reference);
        const source: string | undefined = get('attrs.source', reference);
        const referenceNumber: string | undefined = get('attrs.id', reference);
        const batchDate: string | undefined = get('import_task.created_at', reference);

        const projectStages = reference.project.stages;
        const dExStage = find(propEq('type', StageType.DataExtraction), projectStages);

        return (
          <Fragment>
            <div className="flex flex-row flex-none overflow-hidden items-center p-4">
              <div className="flex-1 text-xl truncate">
                <Trans>Reference details</Trans>
              </div>
              <ModifyReferenceMenu
                reference={reference}
                screeningTags={values(screeningTags)}
                onSaveBibliographicData={handleSaveBibliographicData}
                onRemoveAsDuplicate={handleRemoveAsDuplicate}
                onSetNoReport={handleSetNoReport}
                onRestore={handleRestore}
                onOverwriteScreeningResult={handleScreeningResultOverwrite}
                onUpdateTags={handleTagsUpdate}
              />
            </div>
            <div className="flex flex-col p-4">
              <div className="flex flex-col">
                <span className="text-xs text-gray-600">
                  <Trans>DOI</Trans>
                </span>
                <span>{isEmpty(doi) ? '-' : doi}</span>
              </div>
              <div className="flex flex-col py-4">
                <span className="text-xs text-gray-600">
                  <Trans>Authors</Trans>
                </span>
                <span>{isEmpty(authorNames) ? '-' : authorNames}</span>
              </div>
              <div className="flex flex-col py-4">
                <span className="text-xs text-gray-600">
                  <Trans>Title</Trans>
                </span>
                <span>{isEmpty(title) ? '-' : title}</span>
              </div>
              <div className="flex flex-col py-4">
                <span className="text-xs text-gray-600">
                  <Trans>Year</Trans>
                </span>
                <span>{isEmpty(year) ? '-' : year}</span>
              </div>
              <div className="flex flex-col py-4">
                <span className="text-xs text-gray-600">
                  <Trans>Journal</Trans>
                </span>
                <span>{isEmpty(journal) ? '-' : journal}</span>
              </div>
              <div className="flex flex-col py-4">
                <span className="text-xs text-gray-600">
                  <Trans>Link</Trans>
                </span>
                <span>{isEmpty(link) ? '-' : link}</span>
              </div>
              <div className="flex flex-row py-4">
                <div className="w-1/2 flex flex-col">
                  <span className="text-xs text-gray-600">
                    <Trans>Accession number</Trans>
                  </span>
                  <span>{isEmpty(accessionNumber) ? '-' : accessionNumber}</span>
                </div>
                <div className="w-1/2 flex flex-col">
                  <span className="text-xs text-gray-600">
                    <Trans>Source</Trans>
                  </span>
                  <span>{isEmpty(source) ? '-' : source}</span>
                </div>
              </div>
              <div className="flex flex-col py-4">
                <span className="text-xs text-gray-600">
                  <Trans>Reference number</Trans>
                </span>
                <span>{isEmpty(referenceNumber) ? '-' : referenceNumber}</span>
              </div>
            </div>
            <div className="w-full border-t-2 border-gray-600 p-4">
              <div className="flex flex-row justify-between py-4">
                <span className="text-xs text-gray-600">
                  <Trans>Status</Trans>
                </span>
                <div>
                  {latestStageResult
                    ? renderDecisionTags({
                        inclusionStatus: latestStageResult?.inclusion_status,
                        reasonCodes: latestStageResult?.status_reason_codes,
                      })
                    : '-'}
                  {reference.no_report && (
                    <Tag intent={Intent.DANGER} className="ml-2">
                      <Trans>No PDF</Trans>
                    </Tag>
                  )}
                </div>
              </div>
              {reference.project.stages.map(({ id, name }) => {
                const stageResult = find({ stage_id: id }, reference.stage_results);
                return (
                  <React.Fragment key={id}>
                    <div className="flex flex-row justify-between py-4 mt-2">
                      <span className="text-xs text-gray-600">{name}</span>
                      <div>
                        {stageResult
                          ? renderDecisionTags({
                              inclusionStatus: stageResult?.inclusion_status,
                              reasonCodes: stageResult?.status_reason_codes,
                            })
                          : '-'}
                      </div>
                    </div>
                    <Divider className="m-0" />
                  </React.Fragment>
                );
              })}
              <div className="flex flex-row justify-between py-4">
                <span className="text-xs text-gray-600">
                  <Trans>Extraction</Trans>
                </span>
                {(dExStage ? <Trans>not started</Trans> : null) ?? '-'}
              </div>

              <div className="flex flex-col justify-between py-4">
                <span className="text-xs text-gray-600">
                  <Trans>Batch</Trans>
                </span>
                <span>{batchDate ? formatDate(batchDate, 'dd/MM/yyyy HH:mm') : '-'}</span>
              </div>
              <div className="flex flex-col justify-between py-4">
                <span className="text-xs text-gray-600">
                  <Trans>Structured comments</Trans>
                </span>
                <div>{renderScreeningTags(latestStageResult?.tags)}</div>
              </div>
            </div>
          </Fragment>
        );
      }
      case 'history': {
        const logs = compose(
          groupBy((log: any) => formatDate(log.created_at, 'yyyy-MM-dd')),
          get('logs')
        )(reference);
        const logDates = keys(logs);

        return (
          <div className="flex flex-col flex-grow justify-end p-4">
            {logDates.map((logDate) => (
              <div key={logDate}>
                <span className="flex flex-row justify-end text-xs">{logDate}</span>
                {logs[logDate].map((log, idx) => (
                  <ReferenceDetailsLogCard
                    key={idx}
                    log={log}
                    batch={get('import_task.created_at', reference)}
                    screeningTags={values(screeningTags)}
                    stages={projectStages}
                    usersById={usersById}
                  />
                ))}
              </div>
            ))}
          </div>
        );
      }
      case 'comments': {
        if (reference == null) return null;

        return (
          <ReferenceCommentsPanel
            reference={reference}
            onUpdateComment={handleReferenceCommentUpdate}
            onDeleteComment={handleReferenceCommentDelete}
          />
        );
      }
      default:
        return null;
    }
  }, [
    selectedTab,
    reference,
    commentEditOpen,
    commentValue,
    screeningTags,
    projectStages,
    setCommentValue,
    setCommentEdit,
    updateComment,
    dismissCommentChange,
    renderScreeningTags,
    latestStageResult,
    handleReferenceCommentUpdate,
    handleReferenceCommentDelete,
  ]);

  useEffect(() => {
    if (latestStageResult) {
      setState({
        commentValue: latestStageResult.comment,
      });
    }
  }, [setState, latestStageResult]);

  const referenceAttachments = get('reference_attachments', reference) || EMPTY_LIST;

  const referenceAttachmentsIndexes = useMemo(() => {
    return referenceAttachments.reduce((obj, { key }, idx) => {
      obj[key] = idx;
      return obj;
    }, {});
  }, [referenceAttachments]);

  useEffect(() => {
    setState((currentState) => {
      const { attachmentKey: currentAttachmentKey } = currentState;
      if (currentAttachmentKey && currentAttachmentKey in referenceAttachmentsIndexes) {
        // currently displayed attachment still exists so don't change it
        return currentState;
      }
      // set default when someone deletes currently displayed pdf or when attachments are fetched for the first time
      const defaultAttachment = first(referenceAttachments);
      return {
        ...currentState,
        attachmentKey: defaultAttachment?.key,
        attachmentFilename: defaultAttachment?.filename,
      };
    });
  }, [referenceAttachments, referenceAttachmentsIndexes, setState]);

  const setAttachment = useCallback(
    (order: 'previous' | 'next') => {
      if (!attachmentKey) {
        return;
      }
      const attachmentIdx = referenceAttachmentsIndexes[attachmentKey];
      const targetAttachmentIdx = attachmentIdx + (order === 'next' ? 1 : -1);
      if (targetAttachmentIdx in referenceAttachments) {
        const targetAttachment = referenceAttachments[targetAttachmentIdx];
        setState({
          attachmentKey: targetAttachment.key,
          attachmentFilename: targetAttachment.filename,
        });
      }
    },
    [referenceAttachments, referenceAttachmentsIndexes, attachmentKey, setState]
  );

  const switchAttachment = useCurrCallback(
    (order: 'previous' | 'next', _evt) => {
      setAttachment(order);
    },
    [setAttachment]
  );

  const canSetPreviousAttachment = attachmentKey
    ? referenceAttachmentsIndexes[attachmentKey] > 0
    : false;
  const canSetNextAttachment = attachmentKey
    ? referenceAttachmentsIndexes[attachmentKey] < referenceAttachments.length - 1
    : false;

  const changeReference = useCurrCallback(
    (targetReferenceId: string, referenceIdxDelta: number, _evt) => {
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set(
        'refIdx',
        (Number(newSearchParams.get('refIdx')) + referenceIdxDelta).toString()
      );
      history.push(
        `/projects/${projectId}/references/${targetReferenceId}/details?${newSearchParams.toString()}`
      );
    },
    [history, projectId, location.search]
  );

  const [setActiveAndSelectedReferences] = useMutation(SetActiveAndSelectedReferencesMutation);

  const referenceDetailsTabs = useMemo(() => {
    return isTechAdmin ? REFERENCE_DETAILS_TABS : omit(RESTRICTED_TABS, REFERENCE_DETAILS_TABS);
  }, [isTechAdmin]);

  // We want to keep the highlighted row / cells after coming back from the reference details to the references list.
  useEffect(() => {
    setActiveAndSelectedReferences({
      variables: {
        activeReference: referenceId,
      },
    });
  }, [setActiveAndSelectedReferences, referenceId]);

  useEffect(() => {
    // reset attachmentKey as soon as some reference details loading starts, otherwise we end up
    // having (for a short period of time = 1 render cycle) attachmentKey pointing to previous
    // reference attachment. This introduces attachments fetching racing within pdf viewer and leads
    // to situation when new reference is loaded, but prev reference's PDF is shown (T5542).
    if (loadingReferencesDetails) {
      setState({ attachmentKey: undefined, attachmentFilename: undefined });
    }
  }, [loadingReferencesDetails, setState]);

  return referenceDetailsError || screeningTagsError || referencesIdsError || usersError ? (
    <ErrorScreen
      error={(referenceDetailsError || screeningTagsError || referencesIdsError || usersError)!}
    />
  ) : loadingReferencesDetails || loadingScreeningTags || loadingReferencesIds || loadingUsers ? (
    <Spinner className="h-full" />
  ) : (
    <Fragment>
      <div className="w-full h-full flex flex-row flex-no-wrap overflow-hidden relative">
        <div className="flex flex-col flex-1 overflow-auto">
          <div
            className="h-10 flex-none flex flex-row items-center flex-no-wrap overflow-hidden"
            css={lightGray5bg}
          >
            <PDFSearchToggle className="h-full" large minimal />
            <Divider className="my-0 ml-0 h-full" />
            <Tooltip
              content={reference && getReferenceStudyIdAndTitle(reference)}
              className="truncate w-full"
              targetClassName="truncate"
              targetTagName="div"
              position={Position.BOTTOM_RIGHT}
            >
              <span className="flex-1 ml-2 text-xl" css={titleCss}>
                {reference && getReferenceStudyIdAndTitle(reference)}
              </span>
            </Tooltip>

            {isTechAdmin && (
              <React.Fragment>
                <Divider className="my-0 ml-0 mr-0 h-full" />
                <Button
                  icon={IconNames.PAPERCLIP}
                  className="h-full"
                  large
                  minimal
                  onClick={onOpenPDFUploadDialog}
                  title={i18n._(t`Manage PDF attachments`)}
                />
              </React.Fragment>
            )}
            <Divider className="my-0 ml-0 mr-0 h-full" />
            <Button
              icon={IconNames.ARROW_UP}
              className="h-full"
              large
              minimal
              disabled={!previousReferenceId}
              onClick={changeReference(previousReferenceId, -1)}
              title={i18n._(t`Show previous reference`)}
            />
            <Divider className="my-0 ml-0 mr-0 h-full" />
            <Button
              icon={IconNames.ARROW_DOWN}
              className="h-full"
              large
              minimal
              disabled={!nextReferenceId}
              onClick={changeReference(nextReferenceId, 1)}
              title={i18n._(t`Show next reference`)}
            />
          </div>
          <Divider className="m-0" />

          {isNil(attachmentKey) || isNil(attachmentFilename) ? (
            <NonIdealState
              css={pdfContainerCss}
              action={
                isTechAdmin ? (
                  <Button icon={IconNames.PAPERCLIP} onClick={onOpenPDFUploadDialog}>
                    <Trans>Assign PDF</Trans>
                  </Button>
                ) : undefined
              }
            />
          ) : (
            <React.Fragment>
              <div className="flex-1 overflow-auto" css={pdfContainerCss}>
                <PDFViewer fileKey={attachmentKey} searchBarPosition="topRight" />
              </div>
              <Divider className="m-0" />
              <div
                className="flex-0 h-10 flex flex-row items-center justify-between px-3"
                css={[lightGray5bg, pdfManagementCss]}
              >
                <div>
                  {canSetPreviousAttachment ? (
                    <Button
                      icon={IconNames.CARET_LEFT}
                      className="uppercase pdf-nav"
                      outlined
                      onClick={switchAttachment('previous')}
                    >
                      <Trans>Previous PDF</Trans>
                    </Button>
                  ) : null}
                </div>
                <div className="flex">
                  <PDFPages renderer={DocPageToggles} />
                  <Divider className="h-6" />
                  <PDFZoomToggle renderer={DocZoomToggles} />
                  <Tooltip content={<Trans>Download PDF</Trans>}>
                    <DownloadButton
                      minimal
                      bucketName={Buckets.ReferencesAttachments.name}
                      downloadKey={attachmentKey}
                      downloadName={attachmentFilename}
                    />
                  </Tooltip>
                </div>
                <div>
                  {canSetNextAttachment ? (
                    <Button
                      rightIcon={IconNames.CARET_RIGHT}
                      className="uppercase pdf-nav"
                      outlined
                      onClick={switchAttachment('next')}
                    >
                      <Trans>Next PDF</Trans>
                    </Button>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <Divider className="m-0" />
        <div className="flex flex-col flex-none max-w-lg w-1/3 overflow-auto">
          <div className="h-10 flex flex-row items-center justify-end" css={lightGray5bg}>
            <div className="flex-1 text-left truncate px-4">
              <Tabs id="reference_details_tabs" animate large onChange={setSelectedTab}>
                {keys(referenceDetailsTabs).map((tabId) => {
                  const tab = referenceDetailsTabs[tabId];
                  return (
                    <Tab
                      className="mx-2"
                      key={tab.id}
                      id={tab.id}
                      title={
                        <span className="text-xs">
                          <Icon className="mr-1" icon={tab.icon} />
                          {tab.title}
                        </span>
                      }
                    />
                  );
                })}
              </Tabs>
            </div>
          </div>
          <Divider className="m-0" />
          <div className="flex flex-col flex-1 flex-grow overflow-auto" css={fancyScrollCss}>
            {renderSelectedTabPanel()}
          </div>
        </div>
      </div>
      <PDFAttachmentsDialog
        isOpen={fileUploadDialogOpen}
        projectId={projectId}
        referenceId={referenceId}
        referenceAttachments={referenceAttachments}
        uploadMutationOptions={uploadMutationOptions}
        onDeletePDF={onDeletePDF}
        onClose={onClosePDFUploadDialog}
      />
    </Fragment>
  );
});

export default AdminReferenceDetailsView;
