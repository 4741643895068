/** @jsx jsx */
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Divider,
  H5,
  Icon,
  IMenuItemProps,
  Intent,
  NonIdealState,
  Position,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { loader, gql } from 'graphql.macro';
import { compose, find, get, isEmpty, map, propEq } from 'lodash/fp';
import { differenceInMinutes } from 'date-fns/fp';
import { FC, useCallback, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { alignStartCss, centerItemCss, gray5Border } from '../../common/styles';
import { GraphQLAggregateData, Role, TeamMember } from '../../common/types';
import i18n from '../../i18n';
import { useKeycloak } from '../../keycloak';
import { useCurrCallback, useI18n } from '../../lib/utils';
import ActionsMenu from '../common/actions_menu';
import ErrorScreen from '../common/error_screen';
import { createTable, TableCol } from '../common/gba_table';
import useActionLogger from '../hooks/use_action_logger';
import { formatDateDistance, getLocalizedDateTime } from '../project/helpers';
import AdminPageContentWrapper from '../common/admin_page_content_wrapper';
import OrganizationUsersChecklist, { TInvitationUserData } from './organization_users_checklist';
import ReplaceUserDialog from './replace_user_dialog';
import TeamMemberWithTasksDialog from './team_member_with_tasks_dialog';
import { menuCol } from '../../common/table_cols';
import { TABLE_CONTAINER, TABLE_COLUMN_HEADERS } from '@blueprintjs/table/lib/esm/common/classes';
import { getTasksUpdateAndDeleteStrategiesForRemovedTeamMember } from '../../lib/task_helpers';
import { getRoleLabel } from '../../lib/user_helpers';

const InsertTeamMembersAndSendInvitationMutation = loader(
  '../../graphql/insert_team_members_and_send_invitation_mutation.gql'
);

export enum TeamMemberWithTasksDialogMode {
  Removal,
  RoleChange,
}

const TeamMembersTable = createTable<TeamMemberData>();

const ChangeTeamMemberRoleMutation = gql`
  mutation ChangeTeamMemberRole($id: uuid!, $role: project_role_enum!) {
    update_team_member_by_pk(pk_columns: { id: $id }, _set: { role: $role }) {
      id
      role
    }
  }
`;

const DeleteTeamMemberMutation = gql`
  mutation DeleteTeamMember($id: uuid!) {
    update_team_member_by_pk(pk_columns: { id: $id }, _set: { deleted_at: "now()" }) {
      id
      deleted_at
    }
  }
`;

const ReplaceTeamMemberUserMutation = gql`
  mutation ReplaceTeamMember($projectId: uuid!, $teamMemberId: uuid!, $newUserId: String!) {
    delete_team_member(where: { project_id: { _eq: $projectId }, user_id: { _eq: $newUserId } }) {
      returning {
        id
      }
    }
    update_team_member_by_pk(pk_columns: { id: $teamMemberId }, _set: { user_id: $newUserId }) {
      id
      user_id
    }
  }
`;

const DeleteTeamMemberWithTasksMutation = gql`
  mutation DeleteTeamMemberWithTasksAndDecisions(
    $projectId: uuid!
    $memberId: uuid!
    $tasksDeleteStrategy: task_bool_exp!
    $taskUpdateStrategy: task_bool_exp!
  ) {
    update_team_member_by_pk(pk_columns: { id: $memberId }, _set: { deleted_at: "now()" }) {
      id
    }
    delete_task(where: $tasksDeleteStrategy) {
      returning {
        id
      }
    }
    update_task(where: $taskUpdateStrategy, _set: { completed: true }) {
      returning {
        id
      }
    }
    delete_study_pool_team_member(
      where: { team_member_id: { _eq: $memberId }, study_pool: { project_id: { _eq: $projectId } } }
    ) {
      returning {
        team_member_id
      }
    }
  }
`;

const ChangeTeamMemberRoleWithTasksMutation = gql`
  mutation ChangeTeamMemberRoleWithTasksAndDecisions(
    $projectId: uuid!
    $memberId: uuid!
    $tasksDeleteStrategy: task_bool_exp!
    $taskUpdateStrategy: task_bool_exp!
    $role: project_role_enum!
  ) {
    update_team_member_by_pk(pk_columns: { id: $memberId }, _set: { role: $role }) {
      id
    }
    delete_task(where: $tasksDeleteStrategy) {
      returning {
        id
      }
    }
    update_task(where: $taskUpdateStrategy, _set: { completed: true }) {
      returning {
        id
      }
    }
    delete_study_pool_team_member(
      where: { team_member_id: { _eq: $memberId }, study_pool: { project_id: { _eq: $projectId } } }
    ) {
      returning {
        team_member_id
      }
    }
  }
`;

const GetProjectMembersQuery = gql`
  query GetProjectMembers($projectId: uuid!) {
    project: project_by_pk(id: $projectId) {
      id
      completed
      stages(order_by: { order_number: desc }, where: { tasks: {} }, limit: 1) {
        id
        completed
        study_pools {
          id
          study_pool_team_members {
            completed
            team_member_id
          }
        }
      }
    }
    teamMembers: team_member(
      where: { project_id: { _eq: $projectId }, deleted_at: { _is_null: true } }
    ) {
      id
      role
      created_at
      tasks_by_status {
        count
        inclusion_status
      }
      tasks_aggregate {
        aggregate {
          count
        }
      }
      unsent_decisions_count: tasks_aggregate(
        where: {
          completed: { _eq: false }
          task_results: {
            _or: [
              { result: { _contains: { inclusionStatus: "included" } } }
              { result: { _contains: { inclusionStatus: "excluded" } } }
            ]
          }
        }
      ) {
        aggregate {
          count
        }
      }
      user_id
      user {
        id
        name
        role
        enabled
        isTechAdmin
      }
      project {
        id
        created_by
        created_by_user {
          id
        }
      }
      user_actions_in_project(limit: 1, order_by: { server_timestamp: desc }) {
        server_timestamp
      }
    }
  }
`;

const TABLE_COLS: TableCol<TeamMemberData>[] = [
  {
    id: 'name',
    label: i18n._(t`Name`),
    headerCellCss: alignStartCss,
    cellCss: alignStartCss,
  },
  {
    id: 'joinedAt',
    label: i18n._(t`Invited to project`),
    width: 200,
  },
  {
    id: 'lastSeen',
    label: i18n._(t`Last seen`),
    width: 200,
  },
  {
    id: 'role',
    label: i18n._(t`Role`),
    headerCellCss: alignStartCss,
    cellCss: alignStartCss,
    width: 150,
  },
  menuCol(),
];

export type TeamMemberData = Pick<
  TeamMember,
  | 'id'
  | 'role'
  | 'created_at'
  | 'user'
  | 'project'
  | 'user_actions_in_project'
  | 'tasks_by_status'
  | 'user_id'
> & {
  unsent_decisions_count: GraphQLAggregateData;
};

const getLastSeen = (lastActionTs?: string) => {
  if (lastActionTs == null) return '-';

  const lastActionTime = new Date(lastActionTs);

  return differenceInMinutes(lastActionTime, new Date()) > 5 ? (
    formatDateDistance(lastActionTime)
  ) : (
    <Trans>
      <span className="text-green-600">Active</span>
    </Trans>
  );
};

interface TeamMembersSettingsRouterProps {
  projectId: string;
}

interface ITeamMembersSettingsProps extends RouteComponentProps<TeamMembersSettingsRouterProps> {
  // regular props
}

interface STeamMemberWithTasksDialogState {
  forTeamMember: null | TeamMemberData;
  mode: TeamMemberWithTasksDialogMode;
}

const teamMembersContainerCss = css`
  display: flex;
  gap: 2rem;
  height: 100%;
  padding-bottom: 1rem;

  .${TABLE_CONTAINER} .${TABLE_COLUMN_HEADERS} {
    border: 1px solid #ced9e0;
    border-bottom: 0;
  }
`;

const lineHeightCss = css`
  line-height: 18px;
`;

const personContainerCss = css`
  min-width: 368px;
`;

const TeamMembersSettings: FC<ITeamMembersSettingsProps> = ({ match, history }) => {
  const { projectId } = match.params;
  const i18n = useI18n();
  const insertActionLog = useActionLogger();
  const {
    user: { id: currentUserId },
  } = useKeycloak();

  const [teamMemberWithTasksDialogState, setTeamMemberWithTasksDialogState] =
    useState<STeamMemberWithTasksDialogState>({
      forTeamMember: null,
      mode: TeamMemberWithTasksDialogMode.Removal,
    });

  const [memberToReplace, setMemberToReplace] = useState<null | string>(null);

  const { data, loading, error, refetch } = useQuery(GetProjectMembersQuery, {
    variables: { projectId },
    pollInterval: 1500,
  });

  const latestStage = get('project.stages[0]', data);
  const stageCompleted: boolean = get('completed', latestStage) ?? false;
  const projectCompleted: boolean = get('project.completed', data) ?? false;
  const studyPoolTeamMembers = get('study_pools[0].study_pool_team_members', latestStage);
  const [addTeamMembersAndSendInvitations, { loading: addingMembers }] = useMutation(
    InsertTeamMembersAndSendInvitationMutation
  );
  const [changeTeamMemberRole, { loading: changingRole }] = useMutation(
    ChangeTeamMemberRoleMutation
  );
  const [deleteTeamMember, { loading: deletingMember }] = useMutation(DeleteTeamMemberMutation);
  const [changeTeamMemberRoleWithTasks, { loading: changingRoleWithTasks }] = useMutation(
    ChangeTeamMemberRoleWithTasksMutation
  );
  const [deleteTeamMemberWithTasks, { loading: deletingMemberWithTasks }] = useMutation(
    DeleteTeamMemberWithTasksMutation
  );

  const [replaceUser, { loading: replacingUser }] = useMutation(ReplaceTeamMemberUserMutation);

  const teamMembers: TeamMemberData[] =
    compose(
      map((teamMember: TeamMemberData) => {
        const completedScreening: boolean =
          get('completed', find({ team_member_id: teamMember.id }, studyPoolTeamMembers)) ?? false;
        return {
          ...teamMember,
          completedScreening,
        };
      }),
      get('teamMembers')
    )(data) ?? [];
  const projectMembersIds = useMemo(() => map(get('user_id'), teamMembers), [teamMembers]);

  const handleInvite = useCallback(
    (usersInvitationData: TInvitationUserData[]) => {
      return addTeamMembersAndSendInvitations({
        variables: {
          teamMembers: usersInvitationData.map(
            ({ id: user_id, isTechAdmin }) => ({
              user_id,
              project_id: projectId,
              role:
                isTechAdmin
                  ? Role.Manager
                  : Role.Screener,
            })
          ),
          invitation: {
            projectId,
            userIds: usersInvitationData.map(({ id }) => id),
          },
        },
      }).then(() => {
        insertActionLog('invited users to the project', { usersInvitationData });
        refetch();
      });
    },
    [addTeamMembersAndSendInvitations, projectId, refetch, insertActionLog]
  );

  const doDeleteTeamMemberWithTasks = useCallback(
    (teamMemberId: string, sendDecisions: boolean) => {
      const { taskUpdateStrategy, tasksDeleteStrategy } =
        getTasksUpdateAndDeleteStrategiesForRemovedTeamMember(teamMemberId, sendDecisions);

      return deleteTeamMemberWithTasks({
        variables: {
          projectId,
          memberId: teamMemberId,
          tasksDeleteStrategy,
          taskUpdateStrategy,
        },
      }).then(() =>
        insertActionLog('removed team member with tasks from the project', {
          projectId,
          teamMemberId,
          sendDecisions,
          tasksDeleteStrategy,
          taskUpdateStrategy,
        })
      );
    },
    [deleteTeamMemberWithTasks, insertActionLog, projectId]
  );

  const doChangeTeamMemberRoleWithTasks = useCallback(
    (teamMemberId: string, sendDecisions: boolean) => {
      const { taskUpdateStrategy, tasksDeleteStrategy } =
        getTasksUpdateAndDeleteStrategiesForRemovedTeamMember(teamMemberId, sendDecisions);

      const variables = {
        projectId,
        memberId: teamMemberId,
        tasksDeleteStrategy,
        taskUpdateStrategy,
        role: Role.Manager,
      };

      return changeTeamMemberRoleWithTasks({
        variables,
      }).then(() => insertActionLog('changed team member role with tasks', variables));
    },
    [changeTeamMemberRoleWithTasks, insertActionLog, projectId]
  );

  const doChangeTeamMemberRole = useCallback(
    (teamMemberId: string, role: Role) => {
      return changeTeamMemberRole({ variables: { id: teamMemberId, role } }).then(() =>
        insertActionLog('changed team member role', {
          projectId,
          teamMemberId,
          role,
        })
      );
    },
    [changeTeamMemberRole, insertActionLog, projectId]
  );

  const doDeleteTeamMember = useCallback(
    (teamMemberId: string) => {
      return deleteTeamMember({ variables: { id: teamMemberId } }).then(() =>
        insertActionLog('removed team member from the project', { projectId, teamMemberId })
      );
    },

    [deleteTeamMember, insertActionLog, projectId]
  );

  const handleTeamMemberDecisionsRemove = useCallback(
    (sendDecisions: boolean) => {
      const teamMemberId = teamMemberWithTasksDialogState.forTeamMember?.id;
      if (teamMemberId == null) return;

      return doDeleteTeamMemberWithTasks(teamMemberId, sendDecisions);
    },
    [teamMemberWithTasksDialogState, doDeleteTeamMemberWithTasks]
  );

  const handleTeamMemberDecisionsChangeRole = useCallback(
    (keepDecisions: boolean) => {
      const teamMemberId = teamMemberWithTasksDialogState.forTeamMember?.id;
      if (teamMemberId == null) return;

      return doChangeTeamMemberRoleWithTasks(teamMemberId, keepDecisions);
    },
    [teamMemberWithTasksDialogState, doChangeTeamMemberRoleWithTasks]
  );

  const handleTeamMemberRemove = useCurrCallback(
    (teamMemberId, _evt) => {
      const memberData = find(propEq('id', teamMemberId), teamMembers);
      const tasksCount = get('tasks_aggregate.aggregate.count', memberData) ?? 0;

      // if stage is completed or there are no tasks assigned to team member, just remove him,
      // leaving tasks untouched
      if (stageCompleted || tasksCount === 0) {
        doDeleteTeamMember(teamMemberId);
      }
      // if team member has no unsent decisions - remove such member and all his tasks
      else if (memberData?.unsent_decisions_count.aggregate.count === 0) {
        doDeleteTeamMemberWithTasks(teamMemberId, false);
      }
      // open a dialog to decide what to do with team member's decision
      else {
        setTeamMemberWithTasksDialogState({
          forTeamMember: memberData!,
          mode: TeamMemberWithTasksDialogMode.Removal,
        });
      }
    },
    [
      doDeleteTeamMember,
      doDeleteTeamMemberWithTasks,
      setTeamMemberWithTasksDialogState,
      teamMembers,
      stageCompleted,
    ]
  );

  const handleRoleChange = useCurrCallback(
    (teamMemberId, _evt) => {
      const memberData = find(propEq('id', teamMemberId), teamMembers);
      const role = get('role', memberData);
      const tasksCount = get('tasks_aggregate.aggregate.count', memberData) ?? 0;

      if (role === Role.Manager) {
        doChangeTeamMemberRole(teamMemberId, Role.Screener);
      } else {
        // if stage is completed or there are no tasks assigned to team member, just change role,
        // leaving tasks untouched
        if (stageCompleted || tasksCount === 0) {
          doChangeTeamMemberRole(teamMemberId, Role.Manager);
        }
        // if team member has no unsent decisions - change role and remove all his tasks
        else if (memberData?.unsent_decisions_count.aggregate.count === 0) {
          doChangeTeamMemberRoleWithTasks(teamMemberId, false);
        }
        // open a dialog to decide what to do with team member's decision
        else {
          setTeamMemberWithTasksDialogState({
            forTeamMember: memberData!,
            mode: TeamMemberWithTasksDialogMode.RoleChange,
          });
        }
      }
    },
    [
      doChangeTeamMemberRole,
      doChangeTeamMemberRoleWithTasks,
      setTeamMemberWithTasksDialogState,
      teamMembers,
    ]
  );

  // Note: currentTeamMemberId is an `id` of TeamMember record, while newUserId is an `id` if a User
  // record
  const handleUserReplace = useCurrCallback(
    (currentTeamMemberId, newUserId) => {
      replaceUser({
        variables: {
          projectId,
          teamMemberId: currentTeamMemberId,
          newUserId,
        },
      }).then(() => {
        const teamMember = find(propEq('id', currentTeamMemberId), teamMembers);
        insertActionLog('teamMemberUserReplaced', {
          teamMemberId: currentTeamMemberId,
          oldUser: teamMember?.user_id,
          newUser: newUserId,
        });
      });
    },
    [replaceUser, teamMembers, projectId, insertActionLog]
  );

  const renderTableCell = useCallback(
    (colId: string, teamMember: TeamMemberData) => {
      switch (colId) {
        case 'name':
          const projectCreatedBy = get('project.created_by', teamMember);
          return (
            <div className="flex flex-col">
              <div className="flex flex-row items-center">
                {teamMember.user?.isTechAdmin && <Icon className="mr-1" icon={IconNames.CROWN} />}
                <span>{teamMember.user?.name ?? <Trans>User removed</Trans>}</span>
              </div>
              <div>
                {teamMember.user_id === projectCreatedBy && (
                  <span className="text-xs">
                    <Trans>Project owner</Trans>
                  </span>
                )}
                {teamMember.user == null ||
                  (!teamMember.user.enabled && (
                    <Tag
                      minimal
                      intent={Intent.DANGER}
                      title={i18n._(
                        t`User account has been disabled. This user is not able to participate in any project activity.`
                      )}
                    >
                      <Trans>Deactivated</Trans>
                    </Tag>
                  ))}
              </div>
            </div>
          );
        case 'joinedAt':
          return getLocalizedDateTime(get('created_at', teamMember));
        case 'lastSeen':
          const lastActionTs = get('user_actions_in_project[0].server_timestamp', teamMember);

          return getLastSeen(lastActionTs);
        case 'role':
          return getRoleLabel(teamMember.role, teamMember.user?.role, teamMember.user?.isTechAdmin);
        case 'menu':
          const actions: IMenuItemProps[] = [
            {
              disabled: teamMember.user_id === currentUserId || stageCompleted || projectCompleted,
              onClick: handleTeamMemberRemove(teamMember.id),
              text: <Trans>Remove from the project</Trans>,
            },
          ];

          if (teamMember.role === Role.Screener) {
            actions.unshift({
              disabled: stageCompleted || projectCompleted,
              text: <Trans>Replace with a new team member</Trans>,
              onClick: () => setMemberToReplace(teamMember.id),
            });
          }

          return (
            <ActionsMenu
              menuPosition={Position.LEFT}
              menuButtonProps={{
                loading:
                  deletingMember ||
                  deletingMemberWithTasks ||
                  changingRole ||
                  changingRoleWithTasks ||
                  replacingUser,
              }}
              actions={actions}
            />
          );
        default:
          return null;
      }
    },
    [
      changingRole,
      changingRoleWithTasks,
      currentUserId,
      deletingMember,
      deletingMemberWithTasks,
      handleRoleChange,
      handleTeamMemberRemove,
      i18n,
      setMemberToReplace,
      stageCompleted,
      projectCompleted,
      replacingUser,
    ]
  );

  const onDialogApply = useCallback(
    (sendDecisions: boolean) => {
      let promise: Promise<void> | undefined;
      switch (teamMemberWithTasksDialogState.mode) {
        case TeamMemberWithTasksDialogMode.Removal:
          promise = handleTeamMemberDecisionsRemove(sendDecisions);
          break;
        case TeamMemberWithTasksDialogMode.RoleChange:
          promise = handleTeamMemberDecisionsChangeRole(sendDecisions);
          break;
      }
      promise?.then(() =>
        insertActionLog('applied decision with team members dialog', {
          teamMemberWithTasksDialogState,
          sendDecisions,
        })
      );
    },
    [
      handleTeamMemberDecisionsChangeRole,
      handleTeamMemberDecisionsRemove,
      teamMemberWithTasksDialogState,
      insertActionLog,
    ]
  );

  if (error) {
    return <ErrorScreen error={error} retry={() => refetch()} />;
  }

  return (
    <AdminPageContentWrapper title={<Trans>Team members</Trans>}>
      {loading ? (
        <Spinner css={centerItemCss} />
      ) : (
        <div css={teamMembersContainerCss}>
          {isEmpty(teamMembers) ? (
            <NonIdealState icon={IconNames.BLOCKED_PERSON} title={<Trans>No team members</Trans>} />
          ) : (
            <TeamMembersTable
              cols={TABLE_COLS}
              rows={teamMembers}
              numRows={teamMembers.length}
              cellContentRenderer={renderTableCell}
              className="h-full members-list"
            />
          )}
          <div className="bg-white flex flex-col" css={[gray5Border, personContainerCss]}>
            <H5 className="font-normal m-0 p-4 flex flex-col" css={lineHeightCss}>
              <Trans>People in organization</Trans>
            </H5>
            <Divider className="m-0" />
            <OrganizationUsersChecklist
              onInvite={handleInvite}
              isProjectCompleted={projectCompleted}
              usersToOmit={projectMembersIds}
              addingMembers={addingMembers}
            />
          </div>
        </div>
      )}
      <TeamMemberWithTasksDialog
        isOpen={teamMemberWithTasksDialogState.forTeamMember != null}
        unsentDecisionsCount={
          teamMemberWithTasksDialogState.forTeamMember?.unsent_decisions_count.aggregate.count ?? 0
        }
        onClose={() => {
          setTeamMemberWithTasksDialogState({
            forTeamMember: null,
            mode: TeamMemberWithTasksDialogMode.Removal,
          });
        }}
        onApply={onDialogApply}
        teamMemberName={teamMemberWithTasksDialogState.forTeamMember?.user?.name ?? ''}
        mode={teamMemberWithTasksDialogState.mode}
      />
      <ReplaceUserDialog
        isOpen={memberToReplace != null}
        onClose={() => setMemberToReplace(null)}
        onApply={handleUserReplace(memberToReplace)}
        projectMembers={projectMembersIds}
      />
    </AdminPageContentWrapper>
  );
};

export default TeamMembersSettings;
