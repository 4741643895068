/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Button,
  Checkbox,
  Collapse,
  InputGroup,
  NumberRange,
  RangeSlider,
  RadioGroup,
  Radio,
} from '@blueprintjs/core';
import { useState, useCallback, Dispatch, SetStateAction, FormEvent } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { useCurrCallback } from '../../../lib/utils';
import { get, toSafeInteger, clamp, isEmpty, some } from 'lodash/fp';
import {
  TActiveAttributeFilters,
  TActiveScreeningTagFilters,
  TPdfFilter,
} from '../../../apollo/screening_state';
import { t, Trans } from '@lingui/macro';
import { AttributeFiltersTag, rangeFilterInputCss, ScreeningTagFiltersTag } from '../../screening/screening_filters';
import { TDomainReasonsData, TScreeningTag } from './';
import { IYearsRangeFilterState } from './list';
import { fancyScrollCss } from '../../../common/styles';
import { StageType } from '../../../common/types';
import StageStatusFilters from './stage_status_filters';
import i18n from '../../../i18n';
import ReferencesSearchWidget from '../../screening/references_search_widget';
import { useScreeningFilters } from '../../hooks/use_screening_filters';

const docTypeColVisible: boolean = window.REACT_APP_DOCTYPE_COLUMN_VISIBLE === 'true';

export function isActive<T>(filterObj: T, key: string) {
  return get([key, 'active'], filterObj) ?? false;
}

export const pdfFilterOptions: { value: TPdfFilter; text: string }[] = [
  {
    value: 'all',
    text: i18n._(t`All records`),
  },
  {
    value: 'without_pdf',
    text: i18n._(t`Without PDF`),
  },
  {
    value: 'with_pdf',
    text: i18n._(t`With PDF attached`),
  },
];

interface ScreeningFiltersState {
  showAttributes: boolean;
  showStatus: boolean;
}

interface IScreeningFiltersProps {
  domains: TDomainReasonsData;
  documentTypes: string[];
  yearsFilterData: { min: number; max: number };
  yearsRangeFilterState: IYearsRangeFilterState;
  setYearsRangeFilterState: Dispatch<SetStateAction<IYearsRangeFilterState>>;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  onlyWithComments: boolean;
  pdfFilter: TPdfFilter;
  onlyWithoutAbstract: boolean;
  screeningTags: TScreeningTag[];
  activeScreeningTagFilters: TActiveScreeningTagFilters;
  projectStageTypes: StageType[];
}

const ScreeningFilters: React.FC<IScreeningFiltersProps> = ({
  domains,
  documentTypes,
  yearsFilterData,
  yearsRangeFilterState,
  setYearsRangeFilterState,
  activeDecisionCodeFilters,
  activeDocumentTypeFilters,
  onlyWithComments,
  pdfFilter,
  onlyWithoutAbstract,
  screeningTags,
  activeScreeningTagFilters,
  projectStageTypes,
}) => {
  const [state, setState] = useState<ScreeningFiltersState>({
    showAttributes: true,
    showStatus: true,
  });
  const { showAttributes, showStatus } = state;
  const yearsRangeFilterValue: NumberRange = [
    clamp(yearsFilterData.min, yearsFilterData.max, yearsRangeFilterState.min),
    clamp(yearsFilterData.min, yearsFilterData.max, yearsRangeFilterState.max),
  ];
  const {
    setOnlyWithCommentsFilter,
    setOnlyWithoutAbstractFilter,
    setPdfFilter,
    toggleScreeningTag,
    toggleAttribute,
    setYearsFilter,
  } = useScreeningFilters();

  const isStageInitialized = useCallback(
    (stageType: StageType): boolean => {
      switch (stageType) {
        case StageType.PreliminaryScreening:
          return (
            projectStageTypes.includes(StageType.PreliminaryScreening) &&
            !isEmpty(domains.preliminary)
          );
        case StageType.TitlesAbstractScreening:
          return (
            projectStageTypes.includes(StageType.TitlesAbstractScreening) &&
            !isEmpty(domains.titlesAbstracts)
          );
        case StageType.FullTextScreening:
          return (
            projectStageTypes.includes(StageType.FullTextScreening) &&
            !isEmpty(domains.fullText.inclusion) &&
            !isEmpty(domains.fullText.exclusion)
          );
        default:
          return false;
      }
    },
    [projectStageTypes, domains]
  );

  const statusFiltersVisible = some(isStageInitialized, [
    StageType.PreliminaryScreening,
    StageType.TitlesAbstractScreening,
    StageType.FullTextScreening,
  ]);

  const isAttributeActive = useCallback(
    (filterObject: TActiveAttributeFilters | TActiveScreeningTagFilters, key: string): boolean =>
      isActive<TActiveAttributeFilters | TActiveScreeningTagFilters>(filterObject, key),
    []
  );

  const toggleFlag = useCurrCallback(
    (flagName, _evt) => {
      setState((state) => ({
        ...state,
        [flagName]: !state[flagName],
      }));
    },
    [setState]
  );

  const handleToggleActiveScreeningTag = useCallback(
    (id: string, active: boolean) => {
      toggleScreeningTag({ id, active });
    },
    [toggleScreeningTag]
  );

  const handleToggleActiveDocumentType = useCallback(
    (attribute: string, label: string, active: boolean) => {
      toggleAttribute({ key: 'activeDocumentTypeFilters', attribute, label, active });
    },
    [toggleAttribute]
  );

  const handleSetYearsFilter = useCallback(
    (value: NumberRange) => {
      const [min, max] = value;
      setYearsFilter(min, max);
      setYearsRangeFilterState({ min, max });
    },
    [setYearsFilter, setYearsRangeFilterState]
  );

  const handleSetYearsFilterValue = useCallback(
    (evt) => {
      setYearsRangeFilterState((state: IYearsRangeFilterState) => ({
        ...state,
        [evt.target.name]: toSafeInteger(evt.target.value),
      }));
    },
    [setYearsRangeFilterState]
  );

  const handleChangePdfFilter = useCallback(
    (evt: FormEvent<HTMLInputElement>) => {
      setPdfFilter(evt.currentTarget.value as TPdfFilter);
    },
    [setPdfFilter]
  );

  return (
    <div className="flex flex-col overflow-auto h-full bg-gray-500" css={fancyScrollCss}>
      <div className="flex flex-col bg-white px-4 py-2">
        <span className="text-xl">
          <Trans>Search</Trans>
        </span>
        <ReferencesSearchWidget className="w-full" />
      </div>
      {statusFiltersVisible && (
        <div>
          <div className="border flex flex-no-wrap justify-between items-center pl-4 bg-white">
            <span className="text-xl">
              <Trans>Status</Trans>
            </span>
            <Button
              minimal
              large
              icon={showStatus ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
              onClick={toggleFlag('showStatus')}
            />
          </div>
          <Collapse isOpen={showStatus}>
            {projectStageTypes.map(
              (stageType: StageType) =>
                isStageInitialized(stageType) && (
                  <StageStatusFilters
                    key={stageType}
                    stageType={stageType}
                    domains={domains}
                    activeDecisionCodeFilters={activeDecisionCodeFilters}
                    onActiveAttributeToggle={toggleAttribute}
                    isAttributeActive={isAttributeActive}
                  />
                )
            )}
          </Collapse>
        </div>
      )}
      <div>
        <div className="border flex flex-no-wrap justify-between items-center pl-4 bg-white">
          <span className="text-xl">
            <Trans>Attributes</Trans>
          </span>
          <Button
            minimal
            large
            icon={showAttributes ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
            onClick={toggleFlag('showAttributes')}
          />
        </div>
        <Collapse isOpen={showAttributes}>
          {docTypeColVisible ? (
            <div className="px-4 py-2">
              <div className="rounded border bg-white">
                <div className="border-b p-3">
                  <span className="font-bold">
                    <Trans>Document Type</Trans>
                  </span>
                </div>
                <div className="flex p-3">
                  {documentTypes.map((documentType) => (
                    <AttributeFiltersTag
                      key={documentType}
                      onToggle={handleToggleActiveDocumentType}
                      attribute={documentType}
                      label={documentType ?? 'empty'}
                      active={isAttributeActive(activeDocumentTypeFilters, documentType)}
                    />
                  ))}
                </div>
              </div>
            </div>
          ) : null}
          <div className="px-4 py-2">
            <div className="rounded border bg-white">
              <div className="border-b p-3">
                <span className="font-bold">
                  <Trans>Year</Trans>
                </span>
              </div>
              <div className="flex flex-col p-3">
                <div className="flex flex-row justify-between">
                  <InputGroup
                    css={rangeFilterInputCss}
                    name="min"
                    value={yearsRangeFilterState.min.toString()}
                    onChange={handleSetYearsFilterValue}
                    onBlur={() => handleSetYearsFilter(yearsRangeFilterValue)}
                  />
                  <InputGroup
                    css={rangeFilterInputCss}
                    name="max"
                    value={yearsRangeFilterState.max.toString()}
                    onChange={handleSetYearsFilterValue}
                    onBlur={() => handleSetYearsFilter(yearsRangeFilterValue)}
                  />
                </div>
                <RangeSlider
                  className="my-3"
                  min={yearsFilterData.min}
                  max={yearsFilterData.max}
                  stepSize={1}
                  labelStepSize={20}
                  value={yearsRangeFilterValue}
                  onChange={(value: NumberRange) =>
                    setYearsRangeFilterState({ min: value[0], max: value[1] })
                  }
                  onRelease={() => handleSetYearsFilter(yearsRangeFilterValue)}
                  labelRenderer={false}
                  vertical={false}
                />
              </div>
            </div>
          </div>
          {isEmpty(screeningTags) ? null : (
            <div className="px-4 py-2">
              <div className="rounded border bg-white">
                <div className="border-b p-3">
                  <span className="font-bold">
                    <Trans>Structured comments</Trans>
                  </span>
                </div>
                <div className="flex p-3 flex-wrap">
                  {screeningTags.map(({ id, tag }) => (
                    <ScreeningTagFiltersTag
                      key={id}
                      onToggle={handleToggleActiveScreeningTag}
                      attribute={id}
                      label={tag}
                      active={isAttributeActive(activeScreeningTagFilters, id)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="px-4 py-2">
            <div className="rounded border bg-white">
              <div className="border-b p-3">
                <span className="font-bold">
                  <Trans>Comments</Trans>
                </span>
              </div>
              <div className="flex p-3">
                <Checkbox
                  checked={onlyWithComments}
                  labelElement={<Trans>Show only references with comments</Trans>}
                  onChange={() => setOnlyWithCommentsFilter(!onlyWithComments)}
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-2">
            <div className="rounded border bg-white">
              <div className="border-b p-3">
                <span className="font-bold">
                  <Trans>PDF</Trans>
                </span>
              </div>
              <div className="flex p-3">
                <RadioGroup selectedValue={pdfFilter} onChange={handleChangePdfFilter}>
                  {pdfFilterOptions.map(({ value, text: label }) => (
                    <Radio key={value} {...{ value, label }} />
                  ))}
                </RadioGroup>
              </div>
            </div>
          </div>
          <div className="px-4 py-2">
            <div className="rounded border bg-white">
              <div className="border-b p-3">
                <span className="font-bold">
                  <Trans>Abstract</Trans>
                </span>
              </div>
              <div className="flex p-3">
                <Checkbox
                  checked={onlyWithoutAbstract}
                  labelElement={<Trans>Show only references without an abstract</Trans>}
                  onChange={() => setOnlyWithoutAbstractFilter(!onlyWithoutAbstract)}
                />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ScreeningFilters;
