/** @jsx jsx */
import { Button, Card, Classes, Dialog, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { useCallback, useState } from 'react';
import { useCurrCallback } from '../../lib/utils';
import OrganizationMembersList, { TUserData } from './organization_members_list';

interface IReplaceUserDialogProps {
  isOpen: boolean;
  projectMembers: string[];
  onClose: VoidFunction;
  onApply: (userId: string) => void;
}

const ReplaceUserDialog: React.FC<IReplaceUserDialogProps> = ({
  isOpen,
  onClose,
  onApply,
  projectMembers,
}) => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const handleClose = useCallback(() => {
    setSelectedUserId(null);
    onClose();
  }, [onClose, setSelectedUserId]);

  const handleNewUserInvite = useCallback(() => {
    if (selectedUserId === null) return;

    onApply(selectedUserId);
    handleClose();
  }, [onApply, selectedUserId, handleClose]);

  const handleUserSelect = useCurrCallback(
    (userId, _evt) => {
      setSelectedUserId((current) => (current === userId ? null : userId));
    },
    [setSelectedUserId]
  );

  const renderUser = useCallback(
    (user: TUserData) => {
      const selected = selectedUserId === user.id;
      return (
        <Card
          key={user.id}
          className={`p-0 my-3 w-3/4 mx-auto ${
            selected ? 'border' : 'border-none'
          } border-blue-600`}
          interactive
          onClick={handleUserSelect(user.id)}
        >
          <div className="mx-2 py-3 flex flex-row items-center">
            <span className="flex flex-grow">{user.name}</span>
            {user.isTechAdmin && <Icon icon={IconNames.CROWN} />}
          </div>
        </Card>
      );
    },
    [handleUserSelect, selectedUserId]
  );

  return (
    <Dialog
      isOpen={isOpen}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      onClose={onClose}
      title={<Trans>Replace user</Trans>}
      css={{ width: '40vw', minHeight: '40vh' }}
    >
      <div className={Classes.DIALOG_BODY}>
        <div>
          <Trans>
            Select a person that will replace current user in the project. All references will be
            reassigned automatically.
          </Trans>
        </div>
        <OrganizationMembersList usersToOmit={projectMembers} userRenderer={renderUser} />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={<Trans>Cancel</Trans>} onClick={handleClose} />
          <Button
            disabled={selectedUserId == null}
            text={<Trans>Replace</Trans>}
            onClick={handleNewUserInvite}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ReplaceUserDialog;
