import { useQuery } from '@apollo/react-hooks';
import { Spinner } from '@blueprintjs/core';
import { gql } from 'graphql.macro';
import { get, isNil } from 'lodash/fp';
import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ProjectStatus, ProjectMatchParams } from '../common/types';
import { useKeycloak } from '../keycloak';
import Page404 from './pages/page404';

const GetProjectStatusQuery = gql`
  query GetProjectStatus($projectId: uuid!) {
    project_by_pk(id: $projectId) {
      id
      status
    }
  }
`;
interface IRestrictedAccessProps extends RouteComponentProps<ProjectMatchParams> {
  children: ReactElement<any, any> | JSX.Element;
  onlyAdmin?: boolean;
  privileged?: boolean;
  activeProjectOnly?: boolean;
}

interface IAccessCheck {
  routeRestrictions: { onlyAdmin?: boolean; privileged?: boolean; activeProjectOnly?: boolean };
  requestData: {
    isAdmin?: boolean;
    isTechAdmin?: boolean;
    projectStatus?: ProjectStatus;
  };
}

function hasAccess({ routeRestrictions, requestData }: IAccessCheck) {
  const { onlyAdmin, privileged, activeProjectOnly } = routeRestrictions;
  const { isAdmin, isTechAdmin, projectStatus } = requestData;

  return (
    (onlyAdmin ? isAdmin : true) &&
    (privileged ? isTechAdmin : true) &&
    (activeProjectOnly ? projectStatus === ProjectStatus.Active : true)
  );
}

const RestrictedAccess: React.FC<IRestrictedAccessProps> = ({
  children,
  onlyAdmin,
  privileged,
  activeProjectOnly,
  match,
}) => {
  const { isAdmin, isTechAdmin } = useKeycloak();
  const projectId: string | undefined = get('params.projectId', match);

  const { data, loading } = useQuery(GetProjectStatusQuery, {
    skip: isNil(activeProjectOnly) || isNil(projectId),
    variables: {
      projectId,
    },
    fetchPolicy: 'network-only',
  });
  const projectStatus: ProjectStatus | undefined = get('project_by_pk.status', data);

  return loading ? (
    <Spinner />
  ) : hasAccess({
      routeRestrictions: { onlyAdmin, privileged, activeProjectOnly },
      requestData: { isAdmin, isTechAdmin, projectStatus },
    }) ? (
    children
  ) : (
    <Page404 />
  );
};

export default RestrictedAccess;
