/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Checkbox, Icon, InputGroup, NonIdealState, Spinner, Divider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useI18n } from '../../lib/utils';
import { t, Trans } from '@lingui/macro';
import { get, includes, isEmpty, map, sortBy, compose, filter, lowerCase } from 'lodash/fp';
import { ReactNode, useMemo, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import ErrorScreen from '../common/error_screen';
import { fancyScrollCss } from '../../common/styles';
import { User } from '../../common/types';

const GetUsersQuery = gql`
  query getUsers {
    users: user {
      id
      name
      role
      isTechAdmin
    }
  }
`;

export type TUserData = Pick<User, 'id' | 'name' | 'role' | 'isTechAdmin'>;

interface IOrganizationMembersListProps {
  userRenderer: (user: TUserData) => ReactNode;
  usersToOmit: string[];
}

const OrganizationMembersList: React.FC<IOrganizationMembersListProps> = ({
  userRenderer,
  usersToOmit,
}) => {
  const { data, loading, error, refetch } = useQuery(GetUsersQuery, { pollInterval: 1500 });
  const [showAdmins, setShowAdmins] = useState(true);
  const [usersFilter, setUsersFilter] = useState('');
  const i18n = useI18n();

  const users = get('users', data) ?? [];

  const filteredAndSortedUsers = useMemo(() => {
    return compose(
      sortBy('name'),
      filter(
        (user: User) =>
          includes(lowerCase(usersFilter), lowerCase(user.name)) &&
          (showAdmins ? true : !user.isTechAdmin) &&
          !includes(user.id, usersToOmit)
      )
    )(users);
  }, [usersFilter, showAdmins, usersToOmit, users]);

  if (error) {
    return <ErrorScreen error={error} retry={() => refetch()} />;
  }

  return loading ? (
    <Spinner className="h-full" />
  ) : (
    <div className="flex flex-col overflow-hidden w-full">
      <div className="flex-shrink-0 px-3">
        <div className="my-4">
          <InputGroup
            type="search"
            leftIcon={IconNames.SEARCH}
            placeholder={i18n._(t`Search...`)}
            value={usersFilter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsersFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="flex-shrink-0 px-3">
        <div className="mb-4">
          <Checkbox
            onChange={() => setShowAdmins(!showAdmins)}
            checked={showAdmins}
            className="mx-2 pt-1 flex flex-row items-center"
          >
            <Trans>Show admin users</Trans>
            <Icon className="ml-2" icon={IconNames.CROWN} />
          </Checkbox>
        </div>
      </div>
      <Divider className="m-0" />
      <div className="overflow-auto px-3 mb-2" css={fancyScrollCss}>
        {isEmpty(filteredAndSortedUsers) ? (
          isEmpty(usersFilter) ? (
            <NonIdealState icon={IconNames.HEART} title={<Trans>No users to invite</Trans>} />
          ) : (
            <NonIdealState icon={IconNames.SEARCH} title={<Trans>No user found</Trans>} />
          )
        ) : (
          map(userRenderer, filteredAndSortedUsers)
        )}
      </div>
    </div>
  );
};

export default OrganizationMembersList;
